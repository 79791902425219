import { IElementTopicHeader } from "./Interfaces";
import { ElementTopicService } from "./ElementTopicService";

export class ElementTopicHeader implements IElementTopicHeader{
    private readonly _topicService: ElementTopicService;
    private readonly _topicId: string;
    private readonly _name: string;

    public constructor(parent: ElementTopicService, topicId: string, name:string) {
        this._topicService = parent;
        this._topicId = topicId;
        this._name = name;
    }

    public get topicId(): string { return this._topicId; }
    public get name(): string { return this._name; }
}