import { IPresentationService, IPresentation, IPresentationHeader } from "./Interfaces";
import { CustomerContext } from "../customers/CustomerContext";
import { PresentationApi, IApiPresentationsPresentation } from "../../apis/PresentationApi";
import { IObservableDef, ObservableDef } from "../../libs/observables";
import { DateTimeConversions } from "../../libs/utils/DateTimeConversions";
import { IElement, IElementService } from "../elements/Interfaces";
import { IActivator, ExecuteErrorApi, ExecuteOk } from "../../libs/activators";
import { PresentationActivator } from "./PresentationActivator";
import { PresentationServiceActivator } from "./PresentationServiceActivator";


export class PresentationService implements IPresentationService {
    private readonly _activator: PresentationServiceActivator;
    private readonly _presentationActivatorMap = new Map<string, PresentationActivator>();
    private readonly _presentationApi: PresentationApi;
    private readonly _obsPresentationHeaders: ObservableDef<IPresentationHeader[]>;
    private readonly _obsSelectedPresentation = new ObservableDef<IPresentation | undefined>(undefined);
    private readonly _elementServiceActivator: IActivator<IElementService>;

    constructor(customer: CustomerContext, activator: PresentationServiceActivator, api: PresentationApi, presentationsApiData: IApiPresentationsPresentation[]) {
        this._elementServiceActivator = customer.elementServiceActivator;
        this._activator = activator;
        this._presentationApi = api;


        const headers: IPresentationHeader[] = presentationsApiData.map((p) => {
            return {
                presentationId: p.presentationId,
                name: p.name,
                thumbnail: p.thumbnailUrl,
                expireDate: DateTimeConversions.parseDateFromString(p.presentationExpiredDateTime)
            };
        });

        this._obsPresentationHeaders = new ObservableDef<IPresentationHeader[]>(headers);
    }


    public get obsSelectedPresentation(): IObservableDef<IPresentation | undefined> { return this._obsSelectedPresentation; }

    public get obsPresentationHeaders(): IObservableDef<IPresentationHeader[]> { return this._obsPresentationHeaders; }

    public getPresentationActivator(presentationId: string): IActivator<IPresentation> {
        let activator = this._presentationActivatorMap.get(presentationId);
        if (!activator) {
            activator = new PresentationActivator(this, this._presentationApi, presentationId, this._elementServiceActivator);
            this._presentationActivatorMap.set(presentationId, activator);
        }
        return activator;
    }

    public async createNewPresentationAsync(name: string, elements: IElement[]): Promise<string | undefined> {
        let presentationId: string | undefined;

        await this._activator.executeAsync(async() => {
            const apiResult = await this._presentationApi.createPresentationAsync({
                name,
                elementIds: elements.map((p) => p.elementId),

            });

            if (apiResult.resultType === "error") {
                return new ExecuteErrorApi(apiResult);
            }

            presentationId = apiResult.data.presentationId;
            let thumbnail = "";
            if (elements.length > 0 && elements[0].obsData.value) {
                thumbnail = elements[0].obsData.value.thumbnailUrl;
            }
            this.addHeader(presentationId, name, thumbnail, undefined);
            return new ExecuteOk();
        });

        return presentationId;
    }

    public updateHeader(presentationId: string, name: string, expireDate: Date | undefined): void {
        const headers = this._obsPresentationHeaders.value;
        const presentationHeaderIndex = headers.findIndex((p) => p.presentationId === presentationId);
        headers[presentationHeaderIndex] = {
            ...headers[presentationHeaderIndex],
            name,
            expireDate
        };

        this._obsPresentationHeaders.emit([...headers]);
    }

    public deleteHeader(presentationId: string): void {
        const headers = this._obsPresentationHeaders.value;
        const presentationHeaderIndex = headers.findIndex((p) => p.presentationId === presentationId);
        headers.splice(presentationHeaderIndex, 1);
        this._obsPresentationHeaders.emit([...headers]);
    }


    public updateHeaderThumbnail(presentationId: string, pictureUrl: string): void {
        const headers = this._obsPresentationHeaders.value;
        const presentationHeaderIndex = headers.findIndex((p) => p.presentationId === presentationId);
        headers[presentationHeaderIndex] = {
            ...headers[presentationHeaderIndex],
            thumbnail: pictureUrl
        };

        this._obsPresentationHeaders.emit([...headers]);
    }

    public addHeader(presentationId: string, name: string, thumbNail: string, expireDate: Date | undefined): void {
        const presentationHeader: IPresentationHeader = {
            presentationId,
            name,
            thumbnail: thumbNail,
            expireDate
        };

        this._obsPresentationHeaders.emit([...this._obsPresentationHeaders.value, presentationHeader]);
    }
}
