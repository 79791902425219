import React, { PureComponent } from "react";
import { IElement } from "../../../services/elements/Interfaces";
import { style } from "typestyle";
import { ObsDataComponent } from "../../../libs/react";
import { PbTheme, PbCheckbox } from "../../../components/pd_themed";

const fixedAspectRatioDiv = style({
    overflow: "hidden",
    backgroundColor: PbTheme.colors.backgroundBlockLv4,
    position: "relative",
    zIndex: PbTheme.content.zIndex,
    paddingTop: "56.25%",
    width: "100%",
    cursor: "pointer",
    userSelect: "none"
});

const imageBox = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    alignItems: "center",
    height: "100%"

});

const thumpnailStyle = style({
    gridRow: "1 / span 2",
    gridColumn: "1",
    maxWidth: "100%",
    maxHeight: "100%",
});

const thumpnailSelectionStyle = style({
    gridRow: "1",
    gridColumn: "1",
    backgroundColor: "rgba(255,255,255,0.3)",
    width: "100%",
    height: "100%",
});

const checkboxStyle = style({
    gridRow: "1",
    gridColumn: "1",
    width: "100%",
    height: "100%",
    padding:"10px",
    boxSizing: "border-box",
    zIndex: PbTheme.content.zIndex + 1
});

interface IProps {
    elements: IElement[];
    selectedElements: IElement[];
    onElementClicked: (selection: IElement) => void;
}

export class MediaList extends PureComponent<IProps>{
    public render(): JSX.Element[] {
        return this.props.elements.map((element) => {
            const isSelected = this.props.selectedElements.findIndex((p) => p.elementId === element.elementId) !== -1;
            return (
                <div key={element.elementId + isSelected} className={fixedAspectRatioDiv} onClick={() => this.props.onElementClicked(element)} >
                    <div className={imageBox}>
                        <ObsDataComponent obsData={element.obsData} content={(elementData) => (
                            <img src={elementData.url} alt={elementData.name} className={thumpnailStyle} />
                        )} />
                        {
                            isSelected ? <div className={thumpnailSelectionStyle} /> : null
                        }
                        <div className={checkboxStyle} >
                            <PbCheckbox checked={isSelected} />
                        </div>

                    </div>
                </div>
            );
        });
    }
}