import { CustomerContext } from '../customers/CustomerContext';
import { IObservable, ObservableDef } from '../../libs/observables';
import {
    IUploadedFile,
    IUploadElementService,
    IUploadFile,
    IUploadServiceStatus,
    UploadStatus
    } from './Interfaces';
import { UploadApi } from '../../apis/UploadApi';
import { UploadFile } from './UploadFile';

export class UploadElementService implements IUploadElementService {
    private readonly _obsUploadFiles = new ObservableDef(new Array<UploadFile>());
    private readonly _obsStatus = new ObservableDef<IUploadServiceStatus>({ todo: 0, total: 0, error: 0 });
    private readonly _uploadApi: UploadApi;
    private _uploadFile: UploadFile | undefined;
    private _readyCallback: (() => void);

    constructor(customer: CustomerContext, readyCallback: (() => void)) {
        this._uploadApi = new UploadApi(() => customer.tokenProvider.getTokenAsync(), customer.deviceContext._configService);
        this._readyCallback = readyCallback;
    }

    public get obsUploadFiles(): IObservable<IUploadFile[]> { return this._obsUploadFiles; }
    public get obsStatus(): IObservable<IUploadServiceStatus> { return this._obsStatus; }

    public addFiles(fileList: FileList): void {
        this.addFilesAsync(fileList);
    }

    public async addFilesAsync(fileList: FileList): Promise<IUploadedFile[]> {
        const newList = this._obsUploadFiles.value.map((f) => f);
        for (const file of fileList) {
            const uploadFile = newList.find((uf) => uf.fileExist(file));
            if (!uploadFile) {
                newList.push(new UploadFile(file));
            }
        }

        if (newList.length !== this._obsUploadFiles.value.length) {
            this._obsUploadFiles.emit(newList);
        }

        await this.updateStatusAsync();
        return await this.startUploadAsync();
    }

    public refreshAsync(): void {
        this._obsUploadFiles.emit(this._obsUploadFiles.value.filter((uploadedFile) => uploadedFile.obsStatus.value !== "success"));
    }

    private async startUploadAsync(): Promise<IUploadedFile[]> {
        const result: IUploadedFile[] = [];
        if (!this._uploadFile) {
            this._uploadFile = this.getNextFileToUpload();
            while (this._uploadFile) {
                const uploadRes = await this._uploadFile.uploadAsync(this._uploadApi);
                if (uploadRes) {
                    result.push(uploadRes);
                }
                await this.updateStatusAsync();
                this._uploadFile = this.getNextFileToUpload();
            }
        }
        return result;
    }

    private getNextFileToUpload(): UploadFile | undefined {
        return this._obsUploadFiles.value.find((uploadFile) => uploadFile.isReadyForUpload);
    }

    private async updateStatusAsync(): Promise<void> {
        const status: IUploadServiceStatus = {
            todo: 0,
            total: this._obsUploadFiles.value.length,
            error: 0,
        };

        for (const uploadFile of this._obsUploadFiles.value) {
            const uploadStatus: UploadStatus = uploadFile.obsStatus.value;
            switch (uploadStatus) {
                case "waiting":
                    status.todo = status.todo + 1;
                    break;
                case "error":
                    status.error = status.error + 1;
                    break;
            }
        }

        this._obsStatus.emit(status);

        if (status.todo === 0) {
            await this._readyCallback();
            await this.refreshAsync();
        }
    }
}
