interface ApiStatusIdle {
    status: "idle";
}

interface ApiStatusBusy {
    status: "busy";
}

interface ApiStatusOk {
    status: "ok";
}

interface ApiStatusError {
    status: "error";
    error: string;
    userError: string;
}

export type ApiStatus = ApiStatusIdle | ApiStatusBusy | ApiStatusOk | ApiStatusError;

export const APISTATUS_DEFAULT: ApiStatus = { status: "idle" };
