import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ModelStateView } from '../../../components/datawaiting';
import { CustomerList } from './CustomerList';
import { IDeviceContext } from '../../../services/device/Interfaces';
import { PbBlockContainer } from '../../../components/pd_themed/PbBlockContainer';
import { PbButtonFab, PbParagraph } from '../../../components/pd_themed';
import { PbHeader2 } from '../../../components/pd_themed/PbHeader2';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';
import { ICustomerSearchModel } from '../../../services/customersearch/Interfaces';
import { useDeviceContext } from '../../main/AppLayout';
import { useObservable } from '../../../libs/react';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

const connectHintContainer = style({
    display: "grid",
    gridTemplateRows: "auto auto auto",
    gridTemplateColumns: "auto",
    justifyItems: "center",
    alignItems: "center"
});

interface ICustomerBlockClassProps {
    model: ICustomerSearchModel;
    deviceContext: IDeviceContext;
}

function CustomerBlockClass(props: ICustomerBlockClassProps): JSX.Element | null {
    return (
        <PbBlockContainer>
            <div className={containerStyle} >
                <PbHeader2>{TextStrings.textMyCustomers}</PbHeader2>
                {props.model.hasNone ?
                    <div className={connectHintContainer}>
                        <PbParagraph>{TextStrings.textMyCustomersConnect}</PbParagraph>
                        <PbButtonFab onClick={() => promptConnectSystem()}><AddIcon /></PbButtonFab>
                        <PbParagraph>{TextStrings.textConnectDialogTitle}</PbParagraph>
                    </div>
                    : null}
                <CustomerList
                    deviceContext={props.deviceContext} customerSearchModel={props.model}
                />
            </div>
        </PbBlockContainer>
    );

    async function promptConnectSystem() {
        await  props.deviceContext._loginLogic.connectSystemDialogExecuteAsync();
    }
}

export function PbBlockCustomers(): JSX.Element | null {
    const deviceContext = useDeviceContext();
    const customerSearchModelState = useObservable(deviceContext?.obsCustomerSearchModel)

    if (customerSearchModelState?.type === "data" && customerSearchModelState.data.hasOnlyOne) {
        return null;
    }

    return deviceContext && customerSearchModelState ? (
        <ModelStateView
            modelState={customerSearchModelState}
            content={(model) => (
                <CustomerBlockClass
                    model={model}
                    deviceContext={deviceContext} />

            )}
        />
    ) : null;
}
