import React, { PureComponent } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const buttonTextClassName = style({
    transition: PbTheme.transitions.all1x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    color: PbTheme.colors.textNormal,
    backgroundColor: "none",
    background: "none",
    borderRadius: "none",
    border: "none",
    outline: "0",
    textDecoration: "underline",
    padding: 0,
    cursor: "pointer"
});

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
    disabled?: boolean;
    className?: string;
}

export class PbButtonText extends PureComponent<IProps> {
    public render(): JSX.Element {
        return (
            <button {...this.props} type="button" className={classes(this.props.className, buttonTextClassName)} disabled={this.props.disabled} onClick={this.props.onClick} />
        );
    }
}
