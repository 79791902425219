import React from 'react';
import { BreadCrumbItem } from '../../../../../components/pd_themed';
import { TextStrings } from '../../../../../lang/TextStrings';
import { Outlet } from 'react-router-dom';
import { NarrowCastingBlock } from './NarrowCastingBlock';

export function NarrowCastingRoute(): JSX.Element | null {
    return (
        <>
            <BreadCrumbItem title={TextStrings.textNarrowCastingBlockTitle} />
            <NarrowCastingBlock />
            <Outlet />
        </>
    );
}
