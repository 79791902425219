import CompareResult from "./CompareResult";
import DayOfWeek from "./DayOfWeek";

export default class DateValue {
  private readonly _internalValue: Date;
  private _dayOfWeek?: DayOfWeek;
  private static _minDate = DateValue.fromValues(1, 1, 1);
  private static _maxDate = DateValue.fromValues(9999, 12, 31);

  public static get minDate(): DateValue { return DateValue._minDate; }
  public static get maxDate(): DateValue { return DateValue._maxDate; }

  public static fromValues(year: number, month: number, date: number): DateValue {
    const jsDate = new Date(year, month - 1, date, 0, 0);

    if (jsDate.getDate() !== date
    || jsDate.getMonth() !== month - 1 ) {
      throw new Error(`date is not of a valid value (${year},${date},${month})`);
    }

    return new DateValue(jsDate);
  }

  public static fromJsDate(date: Date): DateValue {
    return new DateValue(date);
  }

  public static get now(): DateValue {
    return DateValue.fromJsDate(new Date());
  }

  public static fromJsDateOrUndefined(date: Date | null | undefined): DateValue | undefined {
    if (date) {
      return this.fromJsDate(date);
    }

    return undefined;
  }

  public static fromApiOrUndefined(date: string | null | undefined): DateValue | undefined {
    if (date) {
      return this.fromApi(date);
    }

    return undefined;
  }

  public static fromApi(date: string): DateValue {
    if (!date) {
      throw new Error("date must have a value");
    }

    const splitted = date.split("-");

    if (splitted.length !== 3) {
      throw new Error("DateType.date invalid format");
    }

    const years = Number.parseInt(splitted[0]);
    const months = Number.parseInt(splitted[1]);
    const days = Number.parseInt(splitted[2]);

    if (isNaN(years) || isNaN(months) || isNaN(days)) {
      throw new Error("Years/months/days NaN");
    }

    return DateValue.fromValues(years, months, days);
  }

  public static fromUiStringOrUndefined(date: string | null | undefined): DateValue | undefined {
    if (!date) {
      return undefined;
    }

    const splitted = date.split("-");

    if (splitted.length !== 3) {
      return undefined;
    }

    const days = Number.parseInt(splitted[0]);
    const months = Number.parseInt(splitted[1]);
    let years = Number.parseInt(splitted[2]);

    if (isNaN(years) || isNaN(months) || isNaN(days)) {
      return undefined;
    }

    if ( years <= 20 ) {
      // 01-01-2 and 01-01-20 are incomplete dates.
      return undefined;
    }

    if (years < 100) {
      years += 2000;
    }

    if ( years < 1000) {
      // 01-01-202 is an incomplete date.
      return undefined;
    }

    try {
      return DateValue.fromValues(years, months, days);
    } catch {
      return undefined;
    }
  }

  public static fromUiString(date: string): DateValue {
    const dateValue = DateValue.fromUiStringOrUndefined(date);

    if (!dateValue) {
      throw new Error("Cannot parse date");
    }

    return dateValue;
  }

  public static valueOrMinDate(value: DateValue | null | undefined): DateValue {
    return value ?? DateValue.minDate;
  }

  public static valueOrMaxDate(value: DateValue | null | undefined): DateValue {
    return value ?? DateValue.maxDate;
  }

  public get dayOfWeek(): DayOfWeek {
    if (this._dayOfWeek === undefined) {
      this._dayOfWeek = DayOfWeek.createFromDate(this._internalValue);
    }

    return this._dayOfWeek;
  }

  public get year(): number {
    return this._internalValue.getFullYear();
  }

  public get month(): number {
    return this._internalValue.getMonth() + 1;
  }

  public get dayOfMonth(): number {
    return this._internalValue.getDate();
  }

  public get jsDate(): Date {
    return new Date(this._internalValue);
  }

  public get uiString(): string {
    const years = this._internalValue.getFullYear().toString();
    const months = (this._internalValue.getMonth() + 1).toString().padStart(2, "0");
    const days = this._internalValue.getDate().toString().padStart(2, "0");

    return `${days}-${months}-${years}`;
  }

  public get uiStringWithoutYear(): string {
    const months = (this._internalValue.getMonth() + 1).toString().padStart(2, "0");
    const days = this._internalValue.getDate().toString().padStart(2, "0");

    return `${days}-${months}`;
  }

  public get uiStringWithMonth(): string {
    const options: Intl.DateTimeFormatOptions = { day: "numeric", month: "short", year: "numeric" };

    return this._internalValue.toLocaleDateString("nl-NL", options);
  }

  public get uiStringWithDay(): string {
    return `${this.dayOfWeek.shortName} ${this.uiString}`;
  }

  public get toApiAsDate(): string {
    const years = this._internalValue.getFullYear().toString();
    const months = (this._internalValue.getMonth() + 1).toString().padStart(2, "0");
    const days = this._internalValue.getDate().toString().padStart(2, "0");

    return `${years}-${months}-${days}`;
  }

  public addDays(days: number): DateValue {
    const newDate = new Date(this._internalValue);

    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + days);

    return new DateValue(newDate);
  }

  public addMonth(months: number): DateValue {
    const newDate = new Date(this._internalValue);

    newDate.setHours(0, 0, 0, 0);
    newDate.setMonth(newDate.getMonth() + months);

    return new DateValue(newDate);
  }

  private constructor(initvalue: Date) {
    this._internalValue = new Date(initvalue);
    this._internalValue.setHours(0, 0, 0, 0);
    Object.freeze(this._internalValue);
  }

  public toString(): string {
    return `${this.dayOfMonth}-${this.month}-${this.year}`;
  }

  public static equals(date1: DateValue | null | undefined, date2: DateValue | null | undefined): boolean {
    if (date1 && date2) {
      return date1.year === date2.year && date1.month === date2.month && date1.dayOfMonth === date2.dayOfMonth;
    }

    if (date1 || date2) {
      return false;
    }

    return true;
  }

  public equals(otherDate: DateValue | null | undefined): boolean {
    return DateValue.equals(this, otherDate);
  }

  public static max(date1: DateValue, ...dates : DateValue[]) : DateValue {
    let maxDate = date1;

    dates.forEach(d => {
      if ( DateValue.compare(d, maxDate).isBigger) {
        maxDate = d;
      }
    });

    return maxDate;
  }

  public static compare(a: DateValue, b: DateValue): CompareResult {
    if (!a || !b) {
      return CompareResult.NotEqual;
    }

    if (a._internalValue === b._internalValue) {
      return CompareResult.Equal;
    }

    if (a.year < b.year) {
      return CompareResult.Smaller;
    }

    if (a.year > b.year) {
      return CompareResult.Bigger;
    }

    if (a.month < b.month) {
      return CompareResult.Smaller;
    }

    if (a.month > b.month) {
      return CompareResult.Bigger;
    }

    if (a.dayOfMonth < b.dayOfMonth) {
      return CompareResult.Smaller;
    }

    if (a.dayOfMonth > b.dayOfMonth) {
      return CompareResult.Bigger;
    }

    return CompareResult.Equal;
  }

  public numberOfDaysUntil(until: DateValue): number {
    return DateValue.getDifferenceInDays(until, this);
  }

  public static getDifferenceInDays(a: DateValue, b: DateValue): number {
    // eslint-disable-next-line no-underscore-dangle
    return (a._internalValue.getTime() - b._internalValue.getTime()) / 86400000; // 1000*60*60*24;
  }
}
