import React from 'react';
import { PbTheme } from '../../components/pd_themed';
import { useObservable } from '../../libs/react';
import { style } from 'typestyle';
import { useDeviceContext } from './AppLayout';

const footerStyle = style({
    color: PbTheme.colors.textNormalOnGray,
    padding: "5px",
    gridRow: "3",
    fontSize: "0.7em",
    background: PbTheme.colors.backgroundTopBar,
    fontWeight: 300,
    gridColumn: "1",
    marginTop: "10px",
    display: "grid",
    gridTemplateColumns: "1fr auto"
});

interface IProps {
    onToggleMenu?: () => void;
}

export function AppFooter(props: IProps): JSX.Element {
    const deviceContext = useDeviceContext();
    const deviceNr = useObservable(deviceContext?.tokenProvider.obsDeviceNr);

    const deviceNrText = deviceNr ?
        `Device Nr: ${deviceNr}`
        : "";

    return (
        <footer className={footerStyle} >
            <span>Version: 1.6.7</span>
            <span>{deviceNrText}</span>
        </footer>
    );
}
