import React from 'react';
import { PlayerSettingsBlock } from './license/PlayerSettingsBlock';
import { useCustomerContext } from '../../UseCustomer';
import { ModelStateComponent } from '../../../../../components/datawaiting';
import { BreadCrumbItem } from '../../../../../components/pd_themed';

export function PlayerSettingsRoute(): JSX.Element | null {
    const customer = useCustomerContext();
    if (!customer) {
        return null;
    }

    return customer ? (
        <ModelStateComponent
            obsModelState={customer.obsLicensesModelState}
            content={(licensesModel) => {
                return (
                    <>
                        <BreadCrumbItem title="Speler" />
                        <PlayerSettingsBlock />
                    </>
                )
            }} />
    ) : null;
}
