import { CustomerContext } from '../customers/CustomerContext';
import { ILicenseContext, ILicenseContextService, ILicenseData } from './Interfaces';
import { IObservable, IObservableOpt, ObservableOpt } from '../../libs/observables';
import { LicenseContext } from './LicenseContext';

export class LicensesContextService implements ILicenseContextService {
    private readonly _customerContext: CustomerContext;
    private readonly _contextMap = new Map<string, LicenseContext>();
    private _obsSelectedLicense = new ObservableOpt<ILicenseContext>();
    private _obsSingleLicense = new ObservableOpt<ILicenseContext>();

    constructor(customerContext: CustomerContext) {
        this._customerContext = customerContext;
    }

    public get obsSelectedLicenseContext(): IObservable<ILicenseContext> { return this._obsSelectedLicense; }

    public get selectedLicenseId(): string | undefined { return this._obsSelectedLicense.optValue?.licenseId; }

    public get obsSingleLicense(): IObservableOpt<ILicenseContext> { return this._obsSingleLicense; }

    public select(licenseId: string): LicenseContext {
        const license = this.getLicense(licenseId);
        this._obsSelectedLicense.emitOnChanged(license);
        return license;
    }

    public updateLicenses(licenses: ReadonlyArray<ILicenseData>): void {
        licenses.forEach(license => {
            const licenseContext = this.getLicense(license.licenseId);
            licenseContext.updateLicenseData(license);
        });

        if (licenses.length === 1) {
            const licenseContext = this.getLicense(licenses[0].licenseId);
            this.select(licenseContext.licenseId);
            this._obsSingleLicense.emit(licenseContext);
        }
    }

    private getLicense(licenseId: string): LicenseContext {
        let context = this._contextMap.get(licenseId);
        if (!context) {
            context = new LicenseContext(this._customerContext, licenseId);
            this._contextMap.set(licenseId, context);
        }

        return context;
    }
}
