import React, { FunctionComponent } from "react";
import { useResolvedPath } from "react-router-dom";
import { BreadCrumbItemClass } from "./BreadCrumbItemClass";

type BreadCrumbItemProps = {
    title: string;
}

export const BreadCrumbItem: FunctionComponent<BreadCrumbItemProps> = ({ title }: BreadCrumbItemProps) => {
    const resolvedPath = useResolvedPath(".");
    return <BreadCrumbItemClass title={title} location={resolvedPath.pathname} />
}