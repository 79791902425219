import React, { useContext } from "react";
import { style } from "typestyle";
import { PbTheme } from "../pd_themed/PbTheme";
import { Guid } from "guid-typescript";
import { OverlayContext } from "./OverlayContainerProvider"
import { useEffect } from "react";
import { PropsWithChildren } from "react";
import { DialogModel, DialogModelUntyped, DialogObservableProps } from "../../libs/react/DialogExecutor";
import { useObservable } from "../../libs/react";

const overlayContainerStyle = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "0.5fr minmax(min-content, 1000px) 0.5fr",
});

const overlayContentStyle = style({
    gridRow: "1",
    gridColumn: "2",
    backgroundColor: PbTheme.colors.backgroundBlockLv1,
    display: "grid",
    boxSizing: "border-box"
});

export type WithPbOverlayProps<TInput, TOutput> = {
    dialogModel: DialogModel<TInput, TOutput>;
}

interface IProps {
    open?: boolean;
    dialogModel?: DialogModelUntyped;
}

export function PbOverlay(props: PropsWithChildren<IProps>): JSX.Element | null {
    const overlayContext = useContext(OverlayContext);

    const isOpen = props.open === true || !!props.dialogModel;

    useEffect(() => {
        if (overlayContext && isOpen) {
            const id = Guid.create();

            overlayContext.showOverlay({
                id,
                isDialog: false,
                content: () => renderInternal(),
            });

            return () => {
                overlayContext.closeOverlay(id);
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    return <div/>; // Return empty div. Null is not working.

    function renderInternal(): JSX.Element {
        return (
            <div className={overlayContainerStyle}>
                <div className={overlayContentStyle}>
                    {props.children}
                </div>
            </div>
        );
    }
}

export function withPbOverlay<TInput, TOutput>(WrappedComponent: React.ComponentType<WithPbOverlayProps<TInput, TOutput>>) {
    return (props: DialogObservableProps<TInput, TOutput>): JSX.Element | null => {
        const dialogModel = useObservable(props.obsDialogModel);
        return dialogModel ? (
            <PbOverlay dialogModel={dialogModel} >
                <WrappedComponent dialogModel={dialogModel} />
            </PbOverlay>
        ) : null;
    }
}
