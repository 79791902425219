/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable max-classes-per-file */

import { IStoreReader } from "./IStoreReader";
import { StoreBase } from "./StoreBase";

export class StoreWithReader<TModel extends object> extends StoreBase<TModel> implements IStoreReader<TModel> {
  public setOnChange(onChange: (model: TModel) => void): void {
    this.setOnChangeHandler(onChange);
  }
}
