import React from 'react';
import { BreadCrumbs, PbHeader2, PbTheme } from '../../components/pd_themed';
import { style } from 'typestyle';

const headerContainerStyle = style({
    position: "fixed",
    left: 0,
    top: 0,
    right: 0,
    height: PbTheme.header.height,
    display: "grid",
    gridTemplateRows: "auto",
    gridTemplateColumns: "0.5fr minmax(auto, 1000px) 0.5fr",
    padding: "10px",
    alignItems: "center",
    backgroundColor: PbTheme.colors.backgroundBlockLv1,
    marginBottom: "10px",
    borderBottom: "solid 1px #444",
    boxSizing: "border-box",
    zIndex: PbTheme.header.zIndex,
});

const titleConainerStyle = style({
    gridColumn: "2",
    display: "grid",
    gridTemplateRows: "auto",
    gridTemplateColumns: "auto 1fr",
    gridColumnGap: "20px",
    alignItems: "center",
    $nest: {
        [PbTheme.layoutPortraitMediaQuery]: {
            gridTemplateRows: "min-content min-content",
            gridTemplateColumns: "auto",
        }
    }
});

const titleTextStyle = style({
    margin: 0,

    $nest: {
        [PbTheme.layoutPortraitMediaQuery]: {
            fontSize: "1.5rem",
        }
    }
});

export function AppHeader(): JSX.Element | null {
    return (
        <header className={headerContainerStyle}>
            <div className={titleConainerStyle}>
                <PbHeader2 className={titleTextStyle}>PB-Sound</PbHeader2>
                <BreadCrumbs />
            </div>
        </header>
    );
}
