import React from 'react';
import { ModelStateView } from '../../../../components/datawaiting';
import { CircularProgress } from '@material-ui/core';
import { PbBlockContainer } from '../../../../components/pd_themed/PbBlockContainer';
import { PbHeader2 } from '../../../../components/pd_themed/PbHeader2';
import { style } from 'typestyle';
import { TextStrings } from '../../../../lang/TextStrings';
import { useCustomerContext } from '../UseCustomer';
import { PbParagraph, RouterListButton } from '../../../../components/pd_themed';
import { ILicenseData, ILicensesModel } from '../../../../services/licenses/Interfaces';
import { useObservable } from '../../../../libs/react';
import { useLicenseContext } from '../menu/narrow_casting/UseLicense';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

const listStyle = style({
    overflow: "auto",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "min-content"
});

export function PbBlockLicenses(): JSX.Element | null {
    const licenseContext = useLicenseContext();
    const customerContext = useCustomerContext();
    const licensesModelState = useObservable(customerContext?.obsLicensesModelState);
    const visible = licensesModelState?.type === "data" &&  licensesModelState.data?.hasSingleLicense !== true;

    return licensesModelState && visible ? (
        <ModelStateView
            modelState={licensesModelState}
            content={(model) => <DisplayModel model={model} />}
            loadingContent={(
                <PbBlockContainer>
                    <div style={{ margin: "auto" }}>
                        <CircularProgress />
                    </div>
                </PbBlockContainer>
            )}
        />
    ) : null;

    function DisplayModel(props: { model: ILicensesModel }): JSX.Element | null {
        return props.model.hasNoLicense
            ? <DisplayEmpty />
            : props.model.hasSingleLicense
                ? null
                : <DisplayLicenses model={props.model} />
    }

    function DisplayEmpty(): JSX.Element | null {
        return (
            <PbBlockContainer>
                <div className={containerStyle} >
                    <PbHeader2>{TextStrings.textMyPlayers}</PbHeader2>
                    <PbParagraph style={{ fontWeight: "bold", margin: "auto" }} >{TextStrings.textMyContentNoPlayersMessage}</PbParagraph>
                </div>
            </ PbBlockContainer>
        );
    }

    function DisplayLicenses(props: { model: ILicensesModel }): JSX.Element | null {
        return (
            <PbBlockContainer>
                <div className={containerStyle} >
                    <PbHeader2>{TextStrings.textMyPlayers}</PbHeader2>
                    <div className={listStyle} >
                        {props.model.licenses.map((license) => {
                            return (
                                <RouterListButton
                                    key={license.licenseId}
                                    onClick={() => onSelectLicense(license)}
                                    text={license.description}
                                    selected={license.licenseId === licenseContext?.licenseId}
                                />
                            )
                        }) ?? null}
                    </div>
                </div>
            </ PbBlockContainer>
        );
    }


    function onSelectLicense(license: ILicenseData): void {
        if (customerContext) {
            customerContext.licenses.select(license.licenseId);
        }
    }
}
