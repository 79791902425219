import { IDataContainer } from "./IDataContainer";
import { ObservableDef, IObservableDef } from "../observables";
import { DataContainerState, IIdleData } from "./DataContainerState";
import { DataContainerExecuteResult } from "./DataContainerExecuteResult";

export class DataContainer<TData> implements IDataContainer<TData> {
    private readonly _idleData: IIdleData = { type: "idle" };
    private readonly _obsDataState: ObservableDef<DataContainerState<TData>>;
    private _data: TData | undefined;

    constructor() {
        this._obsDataState = new ObservableDef<DataContainerState<TData>>(this._idleData);
    }

    public get data(): TData | undefined { return this._data; }

    public get obsDataState(): IObservableDef<DataContainerState<TData>> { return this._obsDataState; }

    public resetError(): void {
        //
    }

    public setIdle(): void {
        this._data = undefined;
        this._obsDataState.emit(this._idleData);
    }

    public setLoading(message?: string): void {
        this._data = undefined;
        this._obsDataState.emit({
            type: "loading",
            message,
        });
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    private setLoadError(): void {
        this._data = undefined;
        this._obsDataState.emit(this._idleData);
    }

    public setUpdating(data: TData, message?: string): void {
        this._data = undefined;
        this._obsDataState.emit({
            type: "update",
            data,
            message,
        });
    }

    public setUpdateError(data: TData, error: string, canRetry?: boolean): void {
        this._data = data;
        this._obsDataState.emit({
            type: "upderror",
            data,
            canRetry: canRetry === true,
            error,
        });
    }

    public clearError(): void {
        if (this._data) {
            this.setData(this._data);
        } else {
            this.setIdle();
        }
    }

    public setData(data: TData): void {
        this._data = data;
        this._obsDataState.emit({ type: "data", data });
    }

    public async executeLoadAsync(callback: () => Promise<DataContainerExecuteResult<TData>>, message?: string): Promise<DataContainerExecuteResult<TData>> {
        this.setLoading(message);
        try {
            const result = await callback();
            if (result.isError === true) {
                this.setLoadError();
            } else {
                this.setData(result.data);
            }
            return result;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (err: any) {
            return {
                isError: true,
                canRetry: false,
                error: err.toString(),
            };
        }
    }

    public async executeUpdateAsync(callback: () => Promise<DataContainerExecuteResult<TData>>, message?: string): Promise<DataContainerExecuteResult<TData>> {
        if (this._data) {
            this.setUpdating(this._data, message);
            try {
                const result = await callback();
                if (result.isError === true) {
                    this.setLoadError();
                } else {
                    this.setData(result.data);
                }
                return result;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            } catch (err: any) {
                return {
                    isError: true,
                    canRetry: false,
                    error: err.toString(),
                };
            }
        }

        return {
            isError: true,
            canRetry: false,
            error: "Cannot update empty container",
        };
    }
}
