import { ConfigService } from '../config/ConfigService';
import { DeviceContext } from '../device/DeviceContext';
import { IApplicationService } from './Interfaces';
import { IConfigService } from '../config/Interfaces';
import { IDeviceContext } from '../device/Interfaces';
import { DeviceTokenProvider } from '../token/DeviceTokenProvider';

export class ApplicationService implements IApplicationService {
    private readonly _configService: ConfigService;
    public readonly deviceContext: IDeviceContext;


    constructor(configService: ConfigService, deviceTokenProvider: DeviceTokenProvider) {
        this._configService = configService;
        this.deviceContext = new DeviceContext(configService, deviceTokenProvider);
    }

    public get configService(): IConfigService { return this._configService; }
}
