import React, { PureComponent, HTMLAttributes } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const paragraphClassName = style({
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    color: PbTheme.colors.textNormal,
    margin: "5px 0"
});

type IProps = HTMLAttributes<HTMLParagraphElement>

export class PbParagraph extends PureComponent<IProps> {
    public render(): JSX.Element {
        const className = this.props.className ? classes(this.props.className, paragraphClassName) : paragraphClassName;
        return (
            <p {...this.props} className={className}>
            </p>
        );
    }
}
