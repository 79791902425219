import { PresentationApi, IApiPresentationTopicHeaders } from "../../apis/PresentationApi";
import { IObservableDef, ObservableDef } from "../../libs/observables";
import { CustomerContext } from "../customers/CustomerContext";
import { IPresentationTopicService } from "./Interfaces";
import { PresentationTopicHeader } from "./PresentationTopicHeader";
import { PresentationTopicServiceActivator } from "./PresentationTopicServiceActivator";
import {PresentationTopicActivator} from "./PresentationTopicActivator";

export class PresentationTopicService implements IPresentationTopicService {
    private readonly _customer: CustomerContext;
    private readonly _activator: PresentationTopicServiceActivator;
    private readonly _obsTopicHeaders: ObservableDef<PresentationTopicHeader[]>;
    private readonly _api: PresentationApi;
    private readonly _topicElementsAcivatorMap: Map<string, PresentationTopicActivator> = new Map();

    public constructor(customer: CustomerContext, activator: PresentationTopicServiceActivator, api: PresentationApi, headers: IApiPresentationTopicHeaders) {
        this._customer = customer;
        this._activator = activator;
        this._api = api;
        this._obsTopicHeaders = new ObservableDef(headers.topics.map(
            t => {
                const topic = new PresentationTopicHeader(this, t.topicId, t.name);
                return topic;
            }
        ));
    }

    getTopicPresentationsActivator(topicId: string): PresentationTopicActivator {
        let topicElementsActivator = this._topicElementsAcivatorMap.get(topicId);
        if(!topicElementsActivator){
            topicElementsActivator = new PresentationTopicActivator(this._api, topicId);
            this._topicElementsAcivatorMap.set(topicId, topicElementsActivator);
        }

        return topicElementsActivator;
    }

    public get obsTopics(): IObservableDef<PresentationTopicHeader[]> { return this._obsTopicHeaders }
}
