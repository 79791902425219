import { createStyles, Theme } from "@material-ui/core/styles";


export const drawerWidth = 190;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const layoutStyles = (theme: Theme) => createStyles({
    root: {
        // position: "fixed",
        // top: "0",
        // bottom: "0",
        // left: "0",
        // right: "0",

        height: "100vh",
        width: "100%",

        display: "grid",
        background: "#0002",
        gridTemplateColumns: "1fr",
        // gridTemplateRows: "auto minmax(0, 1fr) auto",
        gridTemplateRows: "auto 1fr auto",
        gridTemplateAreas: "header main footer",
    },

    appBar: {
        gridColumn: "1",
        gridRow: "1",


        position: "relative",
        top: "unset",
        right: "unset",
        left: "unset",

        // [theme.breakpoints.up('sm')]: {
        //     gridColumn: "2",
        // },


        // [theme.breakpoints.up('sm')]: {
        //     width: `calc(100% - ${drawerWidth}px)`,
        // },

    },

    main: {
        display: "grid",
        gridAutoColumns: "minmax(0, 1fr)",
        gridAutoRows: "minmax(0, 1fr)",
        gridColumn: "1",
        gridRow: "2",
        background: `${theme.palette.background.default}`,
        padding: "30px",
    },

    footer: {
        gridColumn: "1",
        // gridColumn: "2",
        gridRow: "3",
        background: `${theme.palette.primary.dark}`,
        padding: "5px",

        color: theme.palette.primary.contrastText.toString(),
        fontWeight: theme.typography.fontWeightLight,
        fontSize: "0.7em",

        // [theme.breakpoints.up('sm')]: {
        //     gridColumn: "2",
        // },

    },

    menu: {
        gridColumn: "1",
        gridRow: "2",

        zIndex: 1200,

        // background: "black",
        background: `${theme.palette.background.paper}`,
        borderRight: "1px gray solid",

        boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",


        // [theme.breakpoints.up('sm')]: {
        //     width: drawerWidth,
        //     flexShrink: 0,
        // },
    },
    menuButton: {
        marginRight: 20,
        // [theme.breakpoints.up('sm')]: {
        //     display: 'none',
        // },
    },

    menuOverlay: {
        background: `${theme.palette.background.default}`,
        opacity: 0.5,
        gridRow: 2,
        gridColumn: 2,
        zIndex: 1100,
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },


    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    appContent: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
});
