import React, { PureComponent } from 'react';
import { classes, style } from 'typestyle';
import { Guid } from 'guid-typescript';
import { PbTheme } from './PbTheme';

const buttonStyle = style({
    color: PbTheme.colors.textNormal,
    backgroundColor: PbTheme.colors.buttonPrimary,
    transition: PbTheme.transitions.all05x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    padding: "0.6em 1em 0.6em 1em",
    borderRadius: "100px",
    border: "none",
    outline: "0",
    alignContent: "center",
    boxShadow: "0px 4px 4px #181818",
    margin: "4px 0 4px 0",
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: PbTheme.colors.buttonPrimaryHover,
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "6px 0 2px 0",
            backgroundColor: PbTheme.colors.buttonPrimaryActive,
        }
    }
});

interface IProps {
    className?: string;
    accept?: string;
    multiple?: boolean;
    onFilesSelected: (files: FileList) => void;
}

export class PbUploadButton extends PureComponent<IProps> {
    private _inputId: Guid;

    constructor(props: IProps) {
        super(props);
        this._inputId = Guid.create();
    }

    private getClassName() {
        if (this.props.className) {
            return classes(this.props.className, buttonStyle)
        } else {
            return buttonStyle;
        }
    }

    public render(): JSX.Element {
        return (
            <>
                <button className={this.getClassName()} onClick={() => this.onButtonClick()}>
                    {this.props.children}
                </button>
                <input type="file" id={this._inputId.toString()} style={{ display: "none" }} onChange={(evt) => this.onFilesSelected(evt)} accept={this.props.accept} multiple={this.props.multiple} />
            </>
        );
    }

    private onButtonClick() {
        const inputElm = document.getElementById(this._inputId.toString());
        if (inputElm) {
            inputElm.click();
        }
    }

    private onFilesSelected(evt: React.ChangeEvent<HTMLInputElement>) {
        if (evt.target.files) {
            this.props.onFilesSelected(evt.target.files);
        }
    }
}
