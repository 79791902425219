import React from "react";
import { ModelStateObservable } from "../../libs/activators";
import { useObservable } from "../../libs/react";
import { ModelStateView } from "./ModelStateView";

type IProps<TModel> = {
    readonly obsModelState: ModelStateObservable<TModel>;
    content: (data: TModel) => JSX.Element | null;
    loadingContent?: JSX.Element;
};

export function ModelStateComponent<TModel>(props: IProps<TModel>): JSX.Element | null {
    const modelState = useObservable(props.obsModelState);
    return modelState ? (
        <ModelStateView
            modelState={modelState}
            content={props.content}
            loadingContent={props.loadingContent}
        />
    ) : null;
}
