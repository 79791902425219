import React, { useMemo } from "react";
import { style } from "typestyle";
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay";
import { PbButton, PbButtonText, PbHeader2 } from "../../../components/pd_themed";
import { TextStrings } from "../../../lang/TextStrings";
import { DialogExecutor, ObservableDialogModel } from "../../../libs/react/DialogExecutor";
import { ICustomerContext } from "../../../services/customers/Interfaces";
import { IElement } from "../../../services/elements/Interfaces";
import { SelectMediaDialog, SelectMediaDialogExecutor } from './SelectMediaDialog';
import { SelectTopicDialogExecutor, SelectTopicDialog } from "./SelectTopicDialog";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

interface ISelectElementsDialogInput {
    customerContext: ICustomerContext;
}

interface ISelectElementsDialogOutput {
    elements: IElement[];
}

export class SelectElementsDialogExecutor extends DialogExecutor<ISelectElementsDialogInput, ISelectElementsDialogOutput> { }

function SelectElementsDialogForm(props: WithPbDialogProps<ISelectElementsDialogInput, ISelectElementsDialogOutput>): JSX.Element | null {
    const selectMediaDialogExecutor = useMemo(() => new SelectMediaDialogExecutor(), []);
    const selectTopicDialogExecutor = useMemo(() => new SelectTopicDialogExecutor(), []);

    return (
        <>
            <div className={dialogContainer}>
                <PbHeader2>{TextStrings.textEditPresentationSelectMedia}</PbHeader2>
                <PbButton onClick={() => openMediaDialog()}>{TextStrings.textElementSelectorMedia}</PbButton>
                <PbButton onClick={() => openTopicsDialog()}>{TextStrings.textElementSelectorTopics}</PbButton>
                <PbButtonText onClick={() => closeDialog()}>{TextStrings.textCloseAction}</PbButtonText>
            </div>
            <SelectMediaDialog obsDialogModel={selectMediaDialogExecutor.obsDialogModel} />
            <SelectTopicDialog obsDialogModel={selectTopicDialogExecutor.obsDialogModel} />
        </>
    );

    function closeDialog(): void {
        props.dialogModel.close();
    }

    async function openTopicsDialog(): Promise<void> {
        const topicResult = await selectTopicDialogExecutor.executeAsync({ customerContext: props.dialogModel.input.customerContext });
        if (topicResult.result) {
            props.dialogModel.resolve({
                elements: topicResult.output.elements,
            });
        }
    }

    async function openMediaDialog(): Promise<void> {
        const selectedMediaResult = await selectMediaDialogExecutor.executeAsync({ customerContext: props.dialogModel.input.customerContext });
        if (selectedMediaResult.result) {
            props.dialogModel.resolve({
                elements: selectedMediaResult.output.elements,
            });
        }
    }
}

export const SelectElementsDialog = withPbDialog<ISelectElementsDialogInput, ISelectElementsDialogOutput>(SelectElementsDialogForm);
