import { ObservableDef, IObservable } from "../../libs/observables";
import { IAutomatPresentationHeader, ILicenseContext, ILicenseAdvertisement, ILicenseAdvertisementData, ILicenseAdvertisementPresentationListMode } from "./Interfaces";
import { LicenseAdvertisementActivator } from "./LicenseAdvertisementActivator";
import { ExecuteOk, ExecuteErrorApi } from "../../libs/activators";
import { AdvertisementLicensesApi } from "../../apis/AdvertisementLicensesApi";
import { IPresentationHeader } from "../presentations/Interfaces";


export class LicenseAdvertisement implements ILicenseAdvertisement {
    private readonly _activator: LicenseAdvertisementActivator;
    private readonly _api: AdvertisementLicensesApi;
    private readonly _license: ILicenseContext;
    private readonly _originalAutomatPresentations: IAutomatPresentationHeader[];
    private readonly _obsAutomatPresentations: ObservableDef<IAutomatPresentationHeader[]>;
    private readonly _obsPresentationListMode: ObservableDef<ILicenseAdvertisementPresentationListMode>;

    private readonly _displayFactorStep = 0.25;

    constructor(activator: LicenseAdvertisementActivator, data: ILicenseAdvertisementData, api: AdvertisementLicensesApi, license: ILicenseContext) {
        this._activator = activator;
        this._api = api;
        this._license = license;
        this._originalAutomatPresentations = this.normalizeDisplayFactor(data.automatPresentations);
        this._obsAutomatPresentations = new ObservableDef<IAutomatPresentationHeader[]>(this._originalAutomatPresentations);
        this._obsPresentationListMode = new ObservableDef<ILicenseAdvertisementPresentationListMode>("View");
    }

    public get obsPresentationListMode(): IObservable<ILicenseAdvertisementPresentationListMode> { return this._obsPresentationListMode; }

    public get obsAutomatPresentations(): IObservable<IAutomatPresentationHeader[]> { return this._obsAutomatPresentations; }

    public switchToEditMode(): void {
        this._obsPresentationListMode.emit("Edit");
    }

    public cancelEdit(): void {
        this._obsAutomatPresentations.emit(this._originalAutomatPresentations);
        this._obsPresentationListMode.emit("View");
    }

    public async saveAsync(): Promise<void> {
        await this._activator.executeAsync(async() => {
            const updateResult = await this._api.updateLicenseAsync(
                this._license.licenseId,
                {
                    presentations: this._obsAutomatPresentations.value.map((p) => ({
                        presentationIdentity: p.presentationId,
                        displayFactor: p.displayFactor
                    }))
                }
            );

            if (updateResult.resultType === "error") {
                return new ExecuteErrorApi(updateResult);
            }

            this._obsPresentationListMode.emit("View");
            return new ExecuteOk(true);
        });
    }

    public addPresentation(presentation: IPresentationHeader): void {
        const automatPresentation: IAutomatPresentationHeader = {
            ...presentation,
            displayFactor: this.getAvarageDisplayFactor()
        };
        let presentations = [...this._obsAutomatPresentations.value, automatPresentation];
        presentations = this.normalizeDisplayFactor(presentations);
        this._obsAutomatPresentations.emit(presentations);
    }

    public removePresentation(presentation: IAutomatPresentationHeader): void {
        let currentPresentations = this._obsAutomatPresentations.value;
        currentPresentations = currentPresentations.filter((cp) => cp.presentationId !== presentation.presentationId);
        currentPresentations = this.normalizeDisplayFactor(currentPresentations);
        this._obsAutomatPresentations.emit([...currentPresentations]);
    }

    public increaseDisplayFactor(presentation: IAutomatPresentationHeader): void {
        this.changeDisplayFactorBy(presentation, 1 + this._displayFactorStep);
    }

    public decreaseDisplayFactor(presentation: IAutomatPresentationHeader): void {
        this.changeDisplayFactorBy(presentation, 1 - this._displayFactorStep);
    }

    public resetDisplayfactor(): void {
        let presentations = this._obsAutomatPresentations.value.map((p) => ({
            ...p,
            displayFactor: 1,
        }));
        presentations = this.normalizeDisplayFactor(presentations);
        this._obsAutomatPresentations.emit(presentations);
    }

    private getAvarageDisplayFactor(): number {
        const presentations = this._obsAutomatPresentations.value;
        if (presentations.length === 0) {
            return 1;
        }
        let sum = 0;
        for (const p of presentations) {
            sum += p.displayFactor;
        }
        return sum / presentations.length;
    }

    private changeDisplayFactorBy(presentation: IAutomatPresentationHeader, factor: number) {
        let presentations = [...this._obsAutomatPresentations.value];
        const index = presentations.findIndex((p) => p.presentationId === presentation.presentationId);
        presentations[index] = {
            ...presentations[index],
            displayFactor: presentations[index].displayFactor * factor,
        }
        presentations = this.normalizeDisplayFactor(presentations);
        this._obsAutomatPresentations.emit(presentations);
    }

    private normalizeDisplayFactor(presentations: IAutomatPresentationHeader[]): IAutomatPresentationHeader[] {
        let sum = 0;
        for (const p of presentations) {
            sum += p.displayFactor;
        }
        const fac = 1 / sum;
        const nPresentations: IAutomatPresentationHeader[] = [];
        for (const p of presentations) {
            nPresentations.push({
                ...p,
                displayFactor: p.displayFactor * fac
            });
        }
        return nPresentations;
    }
}
