import { FetchResultContent, FetchResultNoContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';
import { IAccessTokenProvider } from '../services/token/Interfaces';

export class SpotifyAccountApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(accessTokenProvider: IAccessTokenProvider, configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");
        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await accessTokenProvider.getTokenAsync();
            headers.set("recordcasetoken", token.token);
        });
    }

    public getSpotifyAccountAsync(data: IApiSpotifyLicenseRequest): Promise<FetchResultContent<IApiSpotifyLicenseResponse>> {
        return this._fetchService.fetchPostWithResultAsync<IApiSpotifyLicenseResponse>(this.getUri() + "/license", data);
    }

    public saveSpotifyTokenAsync(data: IApiSpotifySaveTokenRequest): Promise<FetchResultNoContent> {
        return this._fetchService.fetchPostAsync(this.getUri() + "/savetoken", data);
    }

    public clearSpotifyTokenAsync(data: IApiSpotifyClearTokenRequest): Promise<FetchResultNoContent> {
        return this._fetchService.fetchPostAsync(this.getUri() + "/cleartoken", data);
    }

    private getUri(): string {
        return this._configService.config.spotifyaccountApiUrl;
    }
}

export interface IApiSpotifyLicenseRequest {
    licenseIdentity: string;
    redirectUrl: string;
}

export interface IApiSpotifyLicenseResponse {
    lastLoginTimestamp?: string;
    licenseIdentity: string;
    loginUrl: string;
}

export interface IApiSpotifySaveTokenRequest {
    licenseIdentity: string;
    token: string;
}

export interface IApiSpotifyClearTokenRequest {
    licenseIdentity: string;
}
