import React from "react";
import { ObsDataComponent } from "../../../../../../libs/react";
import { ICustomerContext } from "../../../../../../services/customers/Interfaces";
import { DialogExecutor } from "../../../../../../libs/react/DialogExecutor";
import { IPresentationHeader } from "../../../../../../services/presentations/Interfaces";
import { PbHeader2, PbPresentationList, PbButton, PbTheme } from "../../../../../../components/pd_themed";
import { ActivatorComponent } from "../../../../../../components/datawaiting/ActivatorComponent";
import { CircularProgress } from "@material-ui/core";
import { style } from "typestyle";
import { TextStrings } from "../../../../../../lang/TextStrings"
import { withPbDialog, WithPbDialogProps } from "../../../../../../components/overlay";
import { useCustomerContext } from "../../../UseCustomer";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content 1fr min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden"
});

const scrollContainer = style({
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: PbTheme.colors.backgroundBlockLv3
});
const loaderStyle = style({
    display: "grid",
    minHeight: "300px",
    alignItems: "center",
    justifyItems: "center"
});

type IInput = void;

interface IOutput {
    presentation: IPresentationHeader;
}

export class SelectCustomerPresentationDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectCustomerPresentationDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const customerContext = useCustomerContext();
    return customerContext ? (
        <div className={dialogContainer}>
            <PbHeader2>{TextStrings.textMyPlayerSelectPresentation}</PbHeader2>
            <div className={scrollContainer}>
                <ActivatorComponent activator={customerContext.presentationServiceActivator}
                    content={(presentationService) => (
                        <ObsDataComponent obsData={presentationService.obsPresentationHeaders}
                            content={(presentationHeaders) => (
                                <PbPresentationList presentations={presentationHeaders} onClick={(presentation) => onPresentationSelected(presentation)} ></PbPresentationList>
                            )}
                        />
                    )}
                    loadingContent={
                        <div className={loaderStyle}>
                            <CircularProgress />
                        </div>
                    }
                />
            </div>
            <PbButton onClick={() => closeDialog()}>{TextStrings.textBackAction}</PbButton>
        </div>
    ) : null;

    function closeDialog(): void {
        props.dialogModel.close();
    }

    function onPresentationSelected(presentation: IPresentationHeader): void {
        props.dialogModel.resolve({ presentation: presentation });
    }
}

export const SelectCustomerPresentationDialog = withPbDialog<IInput, IOutput>(SelectCustomerPresentationDialogForm);
