import { IObservableDef, ObservableDef } from "../../libs/observables";
import { IElement, IElementData } from "./Interfaces";
import { IApiElement } from "../../apis/ElementApi";
import { ILicenseContext } from "../licenses/Interfaces";
import { ElementService } from "./ElementService";

export class Element implements IElement {
    private readonly _elementss: ElementService;
    private readonly _elementId: string;
    private readonly _obsData = new ObservableDef<IElementData | undefined>(undefined);
    private readonly _licenseSet = new Set<ILicenseContext>();

    constructor(parent: ElementService, elementId: string) {
        this._elementss = parent;
        this._elementId = elementId;
    }

    public get elementId(): string { return this._elementId; }

    public get obsData(): IObservableDef<IElementData | undefined> { return this._obsData; }

    public update(data: IApiElement): void {
        this._obsData.emit({
            elementId: data.elementId,
            name: data.name,
            url: data.url,
            thumbnailUrl: data.thumbnailUrl,
            elementType: data.elementType,
            topicName: data.topicName
        });
    }

    public clear(): void {
        this._obsData.emit(undefined);
    }
}
