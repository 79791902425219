import React from 'react';
import { BreadCrumbItem } from '../../../../components/pd_themed';
import { PresentationsBlock } from './PresentationsBlock';

export function PresentationsRoute(): JSX.Element {
    return (
        <>
            <BreadCrumbItem key="Presentations" title="Presentations" />
            <PresentationsBlock />
        </>
    );
}
