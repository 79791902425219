import React from "react";
import { CircularProgress } from "@material-ui/core";
import { style } from "typestyle";
import { IData, IInitData, IInitErrorData, IUpdateData, IUpdateErrorData, ModelState } from "../../libs/activators";
import { PbButtonText, PbHeader3, PbParagraph } from "../pd_themed";

interface IActivatorComponentRenderProps<TModel> {
  modelState: ModelState<TModel>;
  content: (model: TModel) => JSX.Element | null;
  loadingContent?: JSX.Element;
  onTryResetError?: () => boolean;
}

export function ModelStateView<TModel>(props: IActivatorComponentRenderProps<TModel>): JSX.Element | null {
  if (!props.modelState) {
    return null;
  }

  return (
    <div className={outerStyle}>
      {render(props.modelState)}
    </div>
  );

  function render(modelState: ModelState<TModel>): JSX.Element | null {
    switch (modelState.type) {
      case "data":
        return renderData(modelState);

      case "init":
        return renderInit(modelState);

      case "initerror":
        return renderInitError(modelState);

      case "update":
        return renderUpdate(modelState);

      case "updateerror":
        return renderUpdateError(modelState);

      default:
        return null;
    }
  }

  function renderData(modelState: IData<TModel>): JSX.Element | null {
    return (
      <Content model={modelState.data} content={props.content} dimmed={false} />
    );
  }

  function renderInit(modelState: IInitData): JSX.Element | null {
    return (
      <Spinner loadingContent={props.loadingContent} message={modelState.message} />
    );
  }

  function renderInitError(modelState: IInitErrorData) {
    return (
      <Error error={modelState.error} onRetry={modelState.onRetry} />
    );
  }

  function renderUpdate(modelState: IUpdateData<TModel>): JSX.Element | null {
    return (
      <>
        <Content model={modelState.data} content={props.content} dimmed={true} />
        <Spinner loadingContent={props.loadingContent} message={modelState.message} />
      </>);
  }

  function renderUpdateError(modelState: IUpdateErrorData<TModel>) {
    return (
      <>
        <Content model={modelState.data} content={props.content} dimmed={true}/>
        <Error error={modelState.error} onRetry={modelState.onRetry} onClose={modelState.onClose} />
      </>
    );
  }
}

function Content<TModel>(props: { model: TModel, content: (model: TModel) => JSX.Element | null, dimmed: boolean }): JSX.Element | null {
  return (
    <div className={contentStyle} style={{ opacity: props.dimmed ? 0.5 : 1 }}>
      {props.content(props.model)}
    </div>
  );
}

function Spinner(props: { loadingContent?: JSX.Element, message: string }): JSX.Element | null {
  return (
    <div className={spinnerStyle} onClick={(e) => e.stopPropagation()} >
      {props.loadingContent ||
        <div style={{ margin: "auto" }}>
          <CircularProgress />
        </div>}
    </div>
  );
}

function Error(props: { error: string, onRetry?: () => void, onClose?: () => void }): JSX.Element | null {
  return (
    <div className={errorStyle}>
      <div className={messageContentStyle}>
        <PbHeader3>Er is een fout opgetreden tijdens het verwerken van de gegevens</PbHeader3>
        <PbParagraph>{props.error}</PbParagraph>
        {props.onRetry && <PbButtonText onClick={props.onRetry}>Probeer opnieuw</PbButtonText>}
        {props.onClose && <PbButtonText onClick={props.onClose}>Sluiten</PbButtonText>}
      </div>
    </div>
  );
}

const outerStyle = style({
  display: "grid",
  gridTemplateColumns: "1fr",
  gridTemplateRows: "1fr",
});

const contentStyle = style({
  gridArea: "1 / 1 / 2 / 2",
  display: "grid",
})

const spinnerStyle = style({
  gridArea: "1 / 1 / 2 / 2",
  //background: "#1a1a1a80",
  display: "grid",
  alignItems: "center",
  justifyItems: "center",
  zIndex: 2000,
});

const errorStyle = style({
  gridArea: "1 / 1 / 2 / 2",
  background: "#9d2e2ef2",
  display: "grid",
  alignItems: "center",
  justifyItems: "center"
});

const messageContentStyle = style({
  display: "grid",
  gridAutoFlow: "row",
  gridAutoRows: "auto",
  gridTemplateColumns: "1fr",
  gridRowGap: "10px",
  padding: "10px"
});
