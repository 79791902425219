import React, { PureComponent } from 'react';
import { Guid } from "guid-typescript";

export interface IBreadcrumbPathPart {
    title: string;
    location: string;
    id: Guid
}

export interface IBreadcrumbData {
    path: IBreadcrumbPathPart[];
    addCrumb: (path: IBreadcrumbPathPart) => void;
    removeCrumb: (path: IBreadcrumbPathPart) => void;
    updateCrumb: (path: IBreadcrumbPathPart) => void;
}

const BreadCrumbsContext = React.createContext<IBreadcrumbData | undefined>(undefined);


// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IProps {
}

interface IState {
    ctx: IBreadcrumbData;
}

export class BreadCrumbsProvider extends PureComponent<IProps, IState>{
    private path: IBreadcrumbPathPart[] = [];
    constructor(props: IProps) {
        super(props);
        this.state = { ctx: this.getContext() };
    }

    private addPath(p: IBreadcrumbPathPart) {
        this.path.push(p);
        this.setState({
            ctx: this.getContext()
        });
    }

    private removePath(p: IBreadcrumbPathPart) {
        const index = this.path.findIndex((i) => i.id === p.id);
        if (index >= 0) {
            this.path.splice(index, 1);
        }
        this.setState({
            ctx: this.getContext()
        });
    }

    private updatePath(p: IBreadcrumbPathPart) {
        const index = this.path.findIndex((i) => i.id === p.id);
        if (index >= 0) {
            this.path[index] = p;
        }
        this.setState({
            ctx: this.getContext()
        });
    }

    private getContext(): IBreadcrumbData {
        return {
            path: this.path,
            addCrumb: (p) => this.addPath(p),
            removeCrumb: (p) => this.removePath(p),
            updateCrumb: (p) => this.updatePath(p)
        }
    }

    public render(): JSX.Element {
        return (
            <BreadCrumbsContext.Provider value={this.state.ctx}>
                {this.props.children}
            </BreadCrumbsContext.Provider>
        );
    }
}


export { BreadCrumbsContext };