import React, { useContext } from 'react';
import { ActivatorComponent } from '../../../../components/datawaiting/ActivatorComponent';
import { PbBlockContainer, PbHeader2 } from '../../../../components/pd_themed';
import { PresentationHeaderList } from './PresentationHeaders';
import { style } from 'typestyle';
import { TextStrings } from '../../../../lang/TextStrings';
import { useDeviceContext } from '../../../main/AppLayout';
import { useCustomerContext } from '../UseCustomer';

const blockStyle = style({
    gridColumn: " 1/ span 2"
});

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

export function PresentationsBlock(): JSX.Element | null {
    const customerContext = useCustomerContext();
    const deviceContext = useDeviceContext();
    return customerContext && deviceContext ? (
        <PbBlockContainer className={blockStyle} span={2}>
            <div className={containerStyle}>
                <PbHeader2>{TextStrings.textMyPresentations}</PbHeader2>
                <ActivatorComponent activator={customerContext.presentationServiceActivator}
                    content={(presentationService) => (
                        <PresentationHeaderList deviceContext={deviceContext} customer={customerContext} presentationService={presentationService} />
                    )}
                />
            </div>
        </PbBlockContainer>
    ) : null;
}