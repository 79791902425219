import { ConfigService } from "./ConfigService";
import { ServiceActivator, ServiceOk, ServiceErrorApi } from "../../libs/activators";
import { ConfigApi } from "../../apis/ConfigApi";

export class ConfigServiceActivator extends ServiceActivator<ConfigService> {
    constructor() {
        const api = new ConfigApi();

        super(async() => {
            const apiResult = await api.getConfigAsync();
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const service = new ConfigService(apiResult.data);
            return new ServiceOk(service);
        });
    }
}
