import React, { useEffect } from "react";
import { style } from "typestyle";
import { withPbDialog, WithPbDialogProps } from "../../components/overlay";
import { PbButton, PbHeader3 } from "../../components/pd_themed";
import { TextStrings } from "../../lang/TextStrings";
import { IAlertDialogInput } from "../../services/device/Interfaces";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "auto min-content",
    gridTemplateColumns: "auto",
    gridRowGap: "15px",
    overflow: "hidden",
    maxWidth: "400px"
});

export const AlertDialog = withPbDialog(
    (props: WithPbDialogProps<IAlertDialogInput, void>): JSX.Element => {
        useEffect(() => {
            if (props.dialogModel.input.autoClose !== false) {
                const timer = setTimeout(() => {
                    props.dialogModel.close();
                }, props.dialogModel.input.autoClose)
                return () => {
                    clearTimeout(timer);
                }
            }
            return undefined;
        },[props.dialogModel]);

        return (
            <div className={dialogContainer}>
                <PbHeader3>{props.dialogModel.input.message}</PbHeader3>
                <PbButton onClick={closeDialog}>{TextStrings.textCloseAction}</PbButton>
            </div>
        );

        function closeDialog(): void {
            props.dialogModel.close();
        }
    }
);