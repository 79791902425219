import { IApiSpotifyLicenseResponse } from "../../apis/SpotifyAccountApi";
import { StoreService } from "../../libs/activators";
import { ILicenseSpotifyModel } from "./Interfaces";
import { LicenseSpotifyActivator } from "./LicenseSpotifyActivator";
import { DateTimeValue } from '../../libs/valueobjects';


export class LicenseSpotify extends StoreService<ILicenseSpotifyModel> {
    private readonly _spotifyLoginUrl: string;
    private readonly _activator: LicenseSpotifyActivator;

    constructor(activator: LicenseSpotifyActivator, data: IApiSpotifyLicenseResponse) {
        super({
            status: LicenseSpotify.getStatus(data),
            canConnect: !data.lastLoginTimestamp,
            canDisconnect: !!data.lastLoginTimestamp,
            connect: () => this.onConnect(),
            disconnect: () => this.onDisconnect(),
        });

        this._activator = activator;
        this._spotifyLoginUrl = data.loginUrl;
    }

    public updateData(data: IApiSpotifyLicenseResponse): void {
        this.store.updateModel({
            status: LicenseSpotify.getStatus(data),
            canConnect: !data.lastLoginTimestamp,
            canDisconnect: !!data.lastLoginTimestamp,
        });
    }

    private onConnect(): void {
        window.location.href = this._spotifyLoginUrl;
    }

    private onDisconnect(): void {
        this._activator.disconnectSpotify();
    }

    private static getStatus(data: IApiSpotifyLicenseResponse): string {
        const dt = data.lastLoginTimestamp ? DateTimeValue.fromApi(data.lastLoginTimestamp) : undefined;

        const status = dt
            ? `Laatste koppeling uitgevoerd op ${dt.uiString}`
            : "Er is nog geen koppeling uitgevoerd";

        return status;
    }
}
