import React, { useEffect, useState } from 'react';
import { IDeviceContext } from '../../../services/device/Interfaces';
import { PbHeader4, PbParagraph, PbTextInput, RouterListButton } from '../../../components/pd_themed';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';
import { useDebounce } from '../../../libs/utils';
import { ICustomerSearchData, ICustomerSearchModel } from '../../../services/customersearch/Interfaces';
import { useCustomerContext } from './UseCustomer';
import { useDeviceContext } from '../../main/AppLayout';
import { useNavigate } from 'react-router-dom';

const listStyle = style({
    overflow: "auto",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "min-content"
});

const searchWrapper = style({
    display: "grid",
    gridTemplateRows: "auto auto",
    gridTemplateColumns: "1fr"
});

interface IProps {
    deviceContext: IDeviceContext;
    customerSearchModel: ICustomerSearchModel;
}

export function CustomerList(props: IProps): JSX.Element {
    const [searchQuery, setSearchQuery] = useState<string>("");
    const customerContext = useCustomerContext();
    const selectedCustomerId = customerContext?.customerId;
    const debouncedSearchQuery = useDebounce<string>(searchQuery, 800);

    useEffect(() => {
        if (debouncedSearchQuery !== props.customerSearchModel.searchText) {
            props.customerSearchModel.searchCustomerAsync(debouncedSearchQuery);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedSearchQuery]);

    return (
        <>
            {props.customerSearchModel.isSearchNeeded && (
                <div className={searchWrapper}>
                    <PbHeader4>{TextStrings.textMyCustomersSearch}</PbHeader4>
                    <PbTextInput
                        placeholder={TextStrings.textMyCustomersSearchPlaceholder}
                        value={searchQuery ?? ""}
                        onTextChange={(query) => setSearchQuery(query)}
                        autoFocus />
                </div>
            )}

            <DisplayCustomers model={props.customerSearchModel} selectedCustomerId={selectedCustomerId} />

            {props.customerSearchModel.isMoreAvailable && (
                <PbParagraph>{TextStrings.textMyCustomersSearchMoreResults}</PbParagraph>
            )}
        </>);
}

function DisplayCustomers(props: { model: ICustomerSearchModel, selectedCustomerId: string | undefined }): JSX.Element {
    const navigate = useNavigate();
    const deviceContext = useDeviceContext();
    return (
        <div className={listStyle} >
            {props.model.customers?.map((customer) => {
                return (
                    <RouterListButton
                        key={customer.customerId}
                        onClick={() => onSelectCustomer(customer)}
                        text={customer.company}
                        selected={customer.customerId === props.selectedCustomerId}
                    />
                )
            }) ?? null}
        </div>);

    function onSelectCustomer(customer: ICustomerSearchData): void {
        if (deviceContext) {
            deviceContext._customers.select(customer.customerId);
            navigate("/");
        }
    }
}
