import React, { PureComponent } from "react";
import { style } from "typestyle";
import { PbButtonFab, PbTheme } from "../../../../components/pd_themed";
import { ObsDataComponent } from "../../../../libs/react";
import { IElement } from "../../../../services/elements/Interfaces";
import DeleteIcon from '@material-ui/icons/Delete';

const fixedAspectRatioDiv = style({
    position: "relative",
    zIndex: PbTheme.content.zIndex,
    paddingTop: "56.25%",
    width: "100%"
});

const imageBox = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    alignItems: "center",
    height: "100%"

});

const imageButtonsBox = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "auto",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%"
});

const deleteButtonStyle = style({
    transition: PbTheme.transitions.all05x,
    backgroundColor: "rgba(255,30,30,0.3)",
    color: "red",
    $nest: {
        "&:hover": {
            backgroundColor: "rgba(255,30,30,0.6)",
            color: "white",
        },
        "&:active": {
            color: "white",
            backgroundColor: "rgba(255,50,50,0.6)",
        },
    }
});

const thumpnailStyle = style({
    gridRow: "1 / span 2",
    maxWidth: "100%",
    maxHeight: "100%",
});

interface IProps {
    element: IElement;
    onclick?: () => void;
    onDeleteClicked?: () => void;
    showEditButtons?: boolean;
}

export class MediaTile extends PureComponent<IProps>{
    public render(): JSX.Element {
        const element = this.props.element;
        return (
            <>
                <div className={fixedAspectRatioDiv}>
                    <div className={imageBox}>
                        <ObsDataComponent obsData={element.obsData} content={(element) => <img src={element.thumbnailUrl} alt={element.name} className={thumpnailStyle} />
                        } />
                    </div>
                    {this.props.showEditButtons ? <div className={imageButtonsBox}>
                        <PbButtonFab onClick={() => this.removeImage()} className={deleteButtonStyle} ><DeleteIcon /></PbButtonFab>
                    </div> : null}
                </div>
            </>
        );
    }

    private removeImage(){
        if(this.props.onDeleteClicked){
            this.props.onDeleteClicked();
        }
    }
}