import React from 'react';
import { ChangePasswordDialog } from '../loggedout/ChangePasswordDialog';
import { ConnectSystemDialog } from '../../dialogs/connect_system/ConnectSystemDialog';
import { LoginDialog } from '../loggedout/LoginDialog';
import { MessageDialog } from '../loggedout/MessageDialog';
import { ResetPasswordDialog } from '../loggedout/ResetPasswordDialog';
import { SubscribeDialog } from '../loggedout/SubscribeDialog';
import { useDeviceContext } from '../main/AppLayout';
import { AlertDialog } from '../../dialogs/alert_dialog/AlertDialog';
import { ConfirmDeletionDialog } from '../editors/presentations/ConfirmDeletionDialog';
import { EditPresentationExpireDateTimeDialog } from '../editors/presentations/EditPresentationExpireDateTimeDialog';
import { EditPresentationSlideDurationDialog } from '../editors/presentations/EditPresentationSlideDurationDialog';
import { EditPresentationNameDialog } from '../editors/presentations/EditPresentationNameDialog';
import { SelectElementsDialog } from '../editors/presentations/SelectElementsDialog';
import { SlideSettingsDialog } from '../editors/presentations/SlideSettingsDialog';

export function HomePageDialogsRegistrations(): JSX.Element | null {
    const deviceContext = useDeviceContext();
    const loginLogic = deviceContext?._loginLogic;
    return loginLogic ? (
        <div id="dialogs">
            <ConnectSystemDialog obsDialogModel={loginLogic.obsConnectSystemDialogModel} />
            <LoginDialog obsDialogModel={loginLogic.obsLoginDialogModel} />
            <ChangePasswordDialog obsDialogModel={loginLogic.obsChangePasswordDialogModel} />
            <ResetPasswordDialog obsDialogModel={loginLogic.obsResetPasswordDialogModel} />
            <SubscribeDialog obsDialogModel={loginLogic.obsSubscribeDialogModel} />
            <MessageDialog obsDialogModel={loginLogic.obsMessageDialogModel} />
            <AlertDialog obsDialogModel={deviceContext.obsAlertDialogModel} />

            <ConfirmDeletionDialog obsDialogModel={deviceContext.dialogContext.confirmDeletionDialogExecutor.obsDialogModel} />
            <EditPresentationExpireDateTimeDialog obsDialogModel={deviceContext.dialogContext.editPresentationExpireDateTimeDialogExecutor.obsDialogModel} />
            <EditPresentationSlideDurationDialog obsDialogModel={deviceContext.dialogContext.editPresentationSlideDurationDialogExecutor.obsDialogModel} />
            <EditPresentationNameDialog obsDialogModel={deviceContext.dialogContext.editPresentationNameDialogExecutor.obsDialogModel} />
            <SelectElementsDialog obsDialogModel={deviceContext.dialogContext.selectElementsDialogExecutor.obsDialogModel} />
            <SlideSettingsDialog obsDialogModel={deviceContext.dialogContext.slideSettingsDialogExecutor.obsDialogModel} />
        </div>
    ) : null;
}
