import React from "react";
import { PbButton, PbHeader2, PbParagraph } from '../../components/pd_themed';
import { withPbDialog, WithPbDialogProps } from '../../components/overlay';
import { TextStrings } from '../../lang/TextStrings';

export interface IMessageDialogInput {
    title: string,
    message: string,
}

export const MessageDialog = withPbDialog(
    (props: WithPbDialogProps<IMessageDialogInput, void>): JSX.Element | null => {
        return (
            <>
                <PbHeader2>
                    {props.dialogModel.input.title}
                </PbHeader2>
                <div>
                    <PbParagraph>
                        {props.dialogModel.input.message}
                    </PbParagraph>
                </div>
                <PbButton onClick={handleClose} color="primary" autoFocus>
                    {TextStrings.textCloseAction}
                </PbButton>
            </>
        );

        function handleClose(): void {
            props.dialogModel.close();
        }
    }
);
