import { FetchResultContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';
import { IAccessTokenProvider } from '../services/token/Interfaces';

export class LicensesApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(accessTokenProvider: IAccessTokenProvider, configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");
        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await accessTokenProvider.getTokenAsync();
            headers.set("recordcasetoken", token.token);
        });
    }

    public getLicensesAsync(): Promise<FetchResultContent<IApiLicenses>> {
        return this._fetchService.fetchGetAsync<IApiLicenses>(this.getUri() + "/getlicenses2");
    }

    private getUri(): string {
        return this._configService.config.advertisementLicenseApiUrl;
    }
}

export interface IApiLicenses {
    licenses: IApiLicensesLicense[];
}

export interface IApiLicensesLicense {
    licenseIdentity: string;
    licenseName: string;
}
