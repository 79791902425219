import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveIcon from '@material-ui/icons/Remove';
import { IAutomatPresentationHeader } from '../../../../../../services/licenses/Interfaces';
import { PbButtonFab, PbHeader4, PbTheme } from '../../../../../../components/pd_themed';
import { PureComponentWithLifetime } from '../../../../../../libs/react';
import { style } from 'typestyle';
import { TextStrings } from '../../../../../../lang/TextStrings';

const presentationWrapperStyle = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "0.5fr 0.5fr",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    height: "140px",
    borderRadius: "10px",
});

const automatControlsWrapperStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    alignContent: "center"
});

const automatControlButtonsWrapperStyle = style({
    display: "grid",
    gridTemplateRows: "auto",
    gridTemplateColumns: "1fr 1fr 1fr",
    justifyItems: "center",
    alignItems: "center",
});

const automatDisplayFactorWrapperStyle = style({
    display: "grid",
    gridTemplateRows: "auto",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    alignItems: "center",
});

const automatControlButtonFabStyle = style({
    width: "40px",
    height: "40px"

});

const presentationHeaderStyle = style({
    margin: "5px",
    overflow: "hidden",
    maxWidth: "560px",
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    display: "grid",
    borderRadius: "10px",
});

const fixedAspectRatioDiv = style({
    position: "relative",
    zIndex: PbTheme.content.zIndex,
    //paddingTop: "56.25%",
    width: "100%",
});

const backgroundStyle = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    justifyItems: "center",
    alignItems: "center"
});

const detailOverlayStyle = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "grid",
    gridTemplateRows: "1fr minmax(40px, auto)",
});

const presentationTitleOverlayStyle = style({
    gridRow: "2",
    gridColumn: "1",
    display: "grid",
    gridTemplateRows: "1fr",
    backgroundColor: "rgba(60,60,60,0.6)",
    alignItems: "center",
    padding: "0 5px"
});

const presentationTopOverlayStyle = style({
    gridRow: "1",
    gridColumn: "1",
    display: "grid",
    gridTemplateRows: "min-content",
    gridTemplateColumns: "min-content",
    alignContent: "center",
    justifyContent: "center",
});

const thumpnailStyle = style({
    gridRow: "1 / span 2",
    maxWidth: "100%",
    maxHeight: "100%",
});

const deleteButtonStyle = style({
    transition: PbTheme.transitions.all05x,
    backgroundColor: "rgba(255,30,30,0.3)",
    color: "red",
    $nest: {
        "&:hover": {
            backgroundColor: "rgba(255,30,30,0.6)",
            color: "white",
        },
        "&:active": {
            color: "white",
            backgroundColor: "rgba(255,50,50,0.6)",
        },
    }
});

const displayFactorTitleStyle = style({
    textAlign: "center",
});

interface IPropsEdit {
    presentation: IAutomatPresentationHeader;
    editMode: true;
    onDeleteButtonClick: ()=>void;
    onIncreaseButtonClick: ()=>void;
    onDecreaseButtonClick: ()=>void;
}

interface IPropsView {
    presentation: IAutomatPresentationHeader;
    editMode: false;
}

type IProps = IPropsView | IPropsEdit;

export class AutomatPresentationRow extends PureComponentWithLifetime<IProps> {
    private onDeleteClick = () => {
        if(this.props.editMode){
            this.props.onDeleteButtonClick();
        }
    }

    private formatDisplayFactor(factor: number){
        const roundedPercentage = Math.round(factor * 100);
        return `${roundedPercentage}%`;
    }

    public render(): JSX.Element {
        const {presentation} = this.props;
        return (
            <div className={presentationWrapperStyle}>
                <div className={presentationHeaderStyle} >
                    <div className={fixedAspectRatioDiv}>
                        <div className={backgroundStyle}>
                            <img alt={`thumbnail ${presentation.name}`} className={thumpnailStyle} src={presentation.thumbnail} />
                        </div>
                        <div className={detailOverlayStyle}>
                            <div className={presentationTopOverlayStyle}>
                                {
                                    this.props.editMode
                                    && <PbButtonFab onClick={this.onDeleteClick} className={deleteButtonStyle} ><DeleteIcon /></PbButtonFab>
                                }
                            </div>
                            <div className={presentationTitleOverlayStyle}>
                                <PbHeader4>{presentation.name}</PbHeader4>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={automatControlsWrapperStyle}>
                    <div>
                        <PbHeader4 className={displayFactorTitleStyle}>{TextStrings.textMyPlayerPresentationDisplayFactor}</PbHeader4>
                    </div>
                    {
                        this.props.editMode
                        && <div className={automatControlButtonsWrapperStyle}>
                            <PbButtonFab onClick={this.props.onDecreaseButtonClick} className={automatControlButtonFabStyle} ><RemoveIcon /></PbButtonFab>
                            <PbHeader4>{this.formatDisplayFactor(presentation.displayFactor)}</PbHeader4>
                            <PbButtonFab onClick={this.props.onIncreaseButtonClick} className={automatControlButtonFabStyle} ><AddIcon /></PbButtonFab>
                        </div>
                    }
                    {
                        !this.props.editMode
                        && <div className={automatDisplayFactorWrapperStyle}>
                            <PbHeader4>{this.formatDisplayFactor(presentation.displayFactor)}</PbHeader4>
                        </div>
                    }
                </div>
            </div>
        );
    }
}