export class PromiseAggregator<T> {
    private readonly _useSingleResult: boolean;
    private _resolvers: (Array<(token: T) => void>) | undefined;
    private _rejectors: (Array<(reason?: unknown) => void>) | undefined;
    private _callback: () => Promise<T>;
    private _result: T | undefined;

    constructor(useSingleResult: boolean, callback: () => Promise<T>) {
        this._useSingleResult = useSingleResult;
        this._callback = callback;
    }

    public executeAsync(): Promise<T> {
        if (this._result) {
            return Promise.resolve(this._result);
        }

        return new Promise<T>((resolve, reject) => {
            if (!this._resolvers || !this._rejectors) {
                this._resolvers = [];
                this._rejectors = [];
                this._resolvers.push(resolve);
                this._rejectors.push(reject);
                this.start();
            } else {
                this._resolvers.push(resolve);
                this._rejectors.push(reject);
            }
        });
    }

    private async start(): Promise<void> {
        try {
            const result = await this._callback();

            if (this._useSingleResult) {
                this._result = result;
            }

            const resolvers = this._resolvers;
            this._rejectors = undefined;
            this._resolvers = undefined;
            if (resolvers) {
                for (const resolver of resolvers) {
                    resolver(result);
                }
            }
        } catch (reason) {
            const rejectors = this._rejectors;
            this._rejectors = undefined;
            this._resolvers = undefined;
            if (rejectors) {
                for (const rejector of rejectors) {
                    rejector(reason);
                }
            }
        }
    }
}
