import React, { } from 'react';
import { HomePageRoute } from './home/HomePageRoute';
import { Route, Routes } from 'react-router-dom';
import { RouteNotFound as RouteNotFoundPage } from './main/RouteNotFoundPage';
import { PlayerSettingsRoute } from './home/customer/menu/narrow_casting/LicenseDing';
import { PresentationsRoute } from './home/customer/presentations/PresentationsRoute';
import { MediaRoute } from './home/customer/media/MediaBlock';
import { NarrowCastingRoute } from './home/customer/menu/narrow_casting/NarrowCastingRoute';
import { useSearchParamMapping } from './SearchParamMapping';
import { SpotifyRoute } from './home/customer/menu/spotify/SpotifyRoute';
import { SpotifyRedirectRoute } from './home/customer/menu/spotify/SpotifyRedirectRoute';

export function PageRouter(): JSX.Element | null {
    useSearchParamMapping();
    return (
        <Routes>
            <Route path="/" element={<HomePageRoute />} >
                <Route path="narrow_casting" element={<NarrowCastingRoute />} >
                    <Route path="presentations" element={<PresentationsRoute />} >
                    </Route>
                    <Route path="media" element={<MediaRoute />} >
                    </Route>
                    <Route path="license" element={<PlayerSettingsRoute />} >
                    </Route>
                </Route>
                <Route path="spotify" element={<SpotifyRoute />} >
                    <Route path="redirect" element={<SpotifyRedirectRoute/>} >
                    </Route>
                </Route>
            </Route>
            <Route path="*" element={
                <RouteNotFoundPage />
            } />
        </Routes>);
}
