import React, { FormEvent, useState } from "react";
import { withPbDialog, WithPbDialogProps } from "../../components/overlay";
import { PbHeader2, PbParagraph, PbButtonText, PbTextInput, PbButtonSubmit } from "../../components/pd_themed";
import { style } from "typestyle";
import { TextStrings } from "../../lang/TextStrings";

const formInputs = style({
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridTemplateColumns: "1fr",
    gridRowGap: "10px"
});

const passwordInputContainer = style({
    display: "grid",
    gridTemplateRows: "min-content min-content",
    gridTemplateColumns: "1fr",
});

const forgotPassButtonStyle = style({
    textAlign: "left",
});

interface ILoginDialogResultCancel {
    type: "cancel";
}

interface ILoginDialogResultLogin {
    type: "login";
    email: string;
    password: string;
}

interface ILoginDialogResultReset {
    type: "reset";
    email: string;
}

export type LoginDialogResult = ILoginDialogResultCancel | ILoginDialogResultLogin | ILoginDialogResultReset;

function LoginDialogForm(props: WithPbDialogProps<void, LoginDialogResult>): JSX.Element | null {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [showPassword] = useState<boolean>(false);

    return (
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
            <PbHeader2>Login</PbHeader2>
            <div className={formInputs}>
                <PbParagraph>
                    {TextStrings.textLoginForm}
                </PbParagraph>
                <PbTextInput
                    id="email-input"
                    placeholder="Email"
                    type="email"
                    autoComplete="email"
                    value={email}
                    onTextChange={(text) => handleEmailChange(text)}
                    autoFocus
                />

                <div className={passwordInputContainer}>
                    <PbTextInput
                        placeholder="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        value={password}
                        onTextChange={(text) => handlePasswordChange(text)}
                    />
                    <PbButtonText onClick={handlePasswordReset} className={forgotPassButtonStyle}>
                        {TextStrings.textLoginResetPasswordAction}
                    </PbButtonText>
                </div>
                <PbButtonSubmit text={TextStrings.textLoginLoginAction} />
                <PbButtonText onClick={handleClose} >
                    {TextStrings.textCloseAction}
                </PbButtonText>
            </div>
        </form>
    );

    function handleClose(): void {
        props.dialogModel.resolve({
            type: "cancel",
        });
    }

    function handlePasswordReset(): void {
        props.dialogModel.resolve({
            type: "reset",
            email: email,
        });
    }

    function onSubmit(event: FormEvent<HTMLFormElement>): void {
        event.preventDefault();
        props.dialogModel.resolve({
            type: "login",
            email: email,
            password: password,
        });
    }

    function handleEmailChange(text: string): void {
        setEmail(text);
    }

    function handlePasswordChange(text: string): void {
        setPassword(text);
    }

    // function handleClickShowPassword(): void {
    //     setShowPassword(!showPassword);
    // }
}

export const LoginDialog = withPbDialog<void, LoginDialogResult>(LoginDialogForm);
