import React, { useState } from "react";
import { style } from "typestyle";
import { ActivatorComponent } from "../../../components/datawaiting/ActivatorComponent";
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay";
import { PbButton, PbHeader2 } from "../../../components/pd_themed";
import { TextStrings } from "../../../lang/TextStrings";
import { DialogExecutor } from "../../../libs/react/DialogExecutor";
import { ICustomerContext } from "../../../services/customers/Interfaces";
import { IElement } from "../../../services/elements/Interfaces";
import { SelectMediaDialogContent } from "./SelectMediaDialogContent";


const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

interface IInput {
    customerContext: ICustomerContext;
}

interface IOutput {
    elements: IElement[];
}

export class SelectMediaDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectMediaDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const [hasSelection, setHasSelection] = useState<boolean>(false);
    const [selectedElements, setSelectedElements] = useState<IElement[]>([]);
    const customerContext = props.dialogModel.input.customerContext;

    return (
        <div className={dialogContainer}>
            <PbHeader2>{TextStrings.textEditPresentationSelectMedia}</PbHeader2>
            <ActivatorComponent activator={customerContext.elementServiceActivator} content={(elementService) => (
                <SelectMediaDialogContent customer={customerContext} elementService={elementService.selectionService} onSelectionChanged={(selection) => onSelectionChanged(selection)} />
            )} />
            <PbButton onClick={() => closeDialog()}>{hasSelection ? TextStrings.textSelectAction : TextStrings.textCloseAction}</PbButton>
        </div>
    );

    function closeDialog(): void {
        if (hasSelection) {
            props.dialogModel.resolve({ elements: selectedElements });
        } else {
            props.dialogModel.close();
        }
    }

    function onSelectionChanged(elements: IElement[]) {
        setSelectedElements(elements);
        if ((elements.length > 0) !== hasSelection) {
            setHasSelection(elements.length > 0);
        }
    }
}

export const SelectMediaDialog = withPbDialog<IInput, IOutput>(SelectMediaDialogForm);
