import React from "react";
import { Typography, Paper } from "@material-ui/core";
import { Link } from "react-router-dom";

export class RouteNotFound extends React.Component {
    public render(): JSX.Element {
        return (<Paper color="white">
            <Typography variant="h6">Pagina niet gevonden</Typography>
            <Typography paragraph>De opgegeven pagina is niet gevonden. Selecteer een optie via het menu.</Typography>
            <Link to="/" >Restart</Link>
        </Paper>);
    }
}
