import { IObservableDef, ObservableDef } from "../../libs/observables";
import { IElement, IElementSelectionService,  IElementService } from "./Interfaces";

export class ElementSelectionService implements IElementSelectionService {
    private readonly _parent: IElementService;

    private readonly _obsSelectedElements = new ObservableDef<IElement[]>([]);

    constructor(parent: IElementService, ) {
        this._parent = parent;
    }

    public get obsElements(): IObservableDef<IElement[]> {
        return this._parent.obsElements;
    }

    public get obsSelectedElements(): IObservableDef<IElement[]> {
        return this._obsSelectedElements;
    }

    public refresh(): void {
        this._parent.refresh();
    }

    public clearSelection(): void {
        this._obsSelectedElements.emit([]);
    }

    public removeFromSelection(element: IElement): void {
        const elementIndex = this._obsSelectedElements.value.findIndex((p) => p.elementId === element.elementId);
        const list = [...this._obsSelectedElements.value];
        list.splice(elementIndex, 1);
        this._obsSelectedElements.emit(list);
    }

    public addToSelection(elements: IElement): void {
        this._obsSelectedElements.emit([...this._obsSelectedElements.value, elements]);
    }
}
