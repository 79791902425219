import { ILifetime } from "./ILifetime";
import { ILifetimeContainer } from "./ILifetimeContainer";

export class LifetimeContainer implements ILifetimeContainer, ILifetime {
    private _lifetimeItems: Set<ILifetime> | undefined;
    private _parent?: ILifetimeContainer;

    constructor(parent?: ILifetimeContainer) {
        this._lifetimeItems = new Set<ILifetime>();

        if (parent) {
            parent.addLifetime(this);
        }
    }

    public get lifetimeIsCreated(): boolean {
        return !!this._lifetimeItems;
    }

    public addLifetime(lifetime: ILifetime): void {
        if (!this._lifetimeItems) {
            throw new Error("Cannot add a lifetime to a disposed lifetime");
        }

        this._lifetimeItems.add(lifetime);
    }

    public removeLifetime(lifetime: ILifetime): void {
        if (this._lifetimeItems) {
            this._lifetimeItems.delete(lifetime);
        }
    }

    public dispose(): void {
        if (this._parent) {
            this._parent.removeLifetime(this);
            this._parent = undefined;
        }
        this.disposeRegisteredLifetimes();
    }

    private disposeRegisteredLifetimes() {
        if (this._lifetimeItems) {
            for (const sub of this._lifetimeItems) {
                if (sub !== null) {
                    sub.dispose();
                }
            }
            this._lifetimeItems.clear();
            this._lifetimeItems = undefined;
        }
    }
}
