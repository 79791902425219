import { ObservableDef, IObservableDef } from "../observables";
import { ApiStatus } from "./ApiStatus";
import { FetchResultContent, FetchResultOkNoContent, FetchResultError } from "./FetchResult";


export class ApiStatusService {
    private readonly _obsApiStatus: ObservableDef<ApiStatus>;
    private readonly _obsApiBusy: ObservableDef<boolean>;

    constructor() {
        this._obsApiStatus = new ObservableDef<ApiStatus>({ status: "idle" });
        this._obsApiBusy = new ObservableDef<boolean>(false);
    }

    public get obsApiStatus(): IObservableDef<ApiStatus> { return this._obsApiStatus; }
    public get obsApiBusy(): IObservableDef<boolean> { return this._obsApiBusy; }

    public async load<TResult>(callback: () => Promise<FetchResultContent<TResult>>): Promise<TResult | undefined> {
        this.setStatusBusy();
        try {
            const fetchResult = await callback();

            switch (fetchResult.resultType) {
                case "data":
                    this.setStatusOk();
                    return fetchResult.data;
                case "error":
                    this.setStatusApiError(fetchResult);
                    return undefined;
                default:
            }
        } catch (e) {
            this.setStatusException(e);
        }
    }

    public async updateWithContent<TResult>(callback: () => Promise<FetchResultContent<TResult>>): Promise<TResult | undefined> {
        this.setStatusBusy();
        try {
            const fetchResult = await callback();

            this.setStatusOk();

            if (fetchResult.resultType === "data") {
                return fetchResult.data;
            }
        } catch (e) {
            this.setStatusOk();
        }

        return undefined;
    }

    public async update(callback: () => Promise<FetchResultOkNoContent>): Promise<boolean> {
        this.setStatusBusy();
        try {
            const fetchResult = await callback();

            this.setStatusOk();

            if (fetchResult.resultType === "ok") {
                return true;
            }
        } catch (e) {
            this.setStatusOk();
        }

        return false;
    }


    private setStatusOk(): void {
        this._obsApiStatus.emit({
            status: "ok",
        });

        this._obsApiBusy.emit(false);
    }

    private setStatusBusy(): void {
        this._obsApiStatus.emit({
            status: "busy",
        });

        this._obsApiBusy.emit(true);
    }

    private setStatusApiError(error: FetchResultError): void {
        this._obsApiStatus.emit({
            status: "error",
            userError: error.userError,
            error: error.error,
        });

        this._obsApiBusy.emit(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private setStatusException(exception: any): void {
        this._obsApiStatus.emit({
            status: "error",
            userError: "Unexpected error",
            error: exception.toString(),
        });

        this._obsApiBusy.emit(false);
    }
}
