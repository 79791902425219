import React from 'react';
import { Location, NavigateFunction, Params, useLocation, useNavigate, useParams } from "react-router-dom";


// type TestProps = {
//     firstName : string;
// }

// function test(props: WithRouterProps<TestProps>): JSX.Element {
//     return <h1>{props.firstName} {props.router.location.pathname}</h1>;
// }

// export const Test = withRouter(test);

interface IRouterProps {
    location: Location;
    navigate: NavigateFunction;
    params: Readonly<Params<string>>;
}

export type WithRouterProps<Props> = Props &
{
    router: IRouterProps;
}

export function withRouter<P>(WrappedComponent: React.ComponentType<WithRouterProps<P>>): React.ComponentType<P> {
    return function ComponentWithRouterProp(props: P): JSX.Element {
        const router: IRouterProps = {
            location: useLocation(),
            navigate: useNavigate(),
            params: useParams(),
        };

        return <WrappedComponent router={router} {...props} />;
    }
}
