import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { PbButton, PbHeader2, PbParagraph } from '../../../components/pd_themed';
import { withPbDialog, WithPbDialogProps } from '../../../components/overlay';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content min-content  min-content",
    gridTemplateColumns: "auto",
    gridRowGap: "10px",
    overflow: "hidden",
    maxWidth: "350px",
});

const buttonsWrapper = style({
    display: "grid",
    gridTemplateRows: "min-content",
    gridTemplateColumns: "0.5fr 0.5fr",
    gridColumnGap: "10px"
});

const buttonStyle = style({
    display: "grid",
    gridTemplateColumns: "auto min-content",
    gridTemplateRows: "1fr",
    alignItems: "center"
});

export const ConfirmDeletionDialog = withPbDialog(
    (props: WithPbDialogProps<string, void>): JSX.Element => {
        return (
            <div className={dialogContainer}>
                <PbHeader2>{TextStrings.textDeletePresentationDialogTitle}</PbHeader2>
                <PbParagraph>{TextStrings.formatConfirmDeletionOfPresentation(props.dialogModel.input)}</PbParagraph>
                <div className={buttonsWrapper}>
                    <PbButton className={buttonStyle} onClick={() => cancelDeletion()}>{TextStrings.textNoAction}<CloseIcon /></PbButton>
                    <PbButton className={buttonStyle} onClick={() => approveDeletion()}>{TextStrings.textYesAction}<CheckIcon /></PbButton>
                </div>
            </div>
        );

        function cancelDeletion(): void {
            props.dialogModel.close();
        }

        function approveDeletion(): void {
            props.dialogModel.resolve();
        }
    }
);
