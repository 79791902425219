import React, { PureComponent } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme";
import { Guid } from "guid-typescript";

const buttonStyle = style({
    display: "fixed",
    top: "-20px",
    left: "-20px",
    width: 0,
    height: 0
});

const labelStyle = style({
    display: "block",
    color: PbTheme.colors.textNormal,
    backgroundColor: PbTheme.colors.inputBackground,
    transition: PbTheme.transitions.all05x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    border: "none",
    width: "20px",
    height: "20px",
    outline: "0",
    alignContent: "center",
    boxShadow: "0px 4px 4px #181818",
    margin: "0px 0 4px 0",
    textAlign: "center",
    borderRadius: "3px",
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: PbTheme.colors.buttonPrimaryHover,
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            backgroundColor: PbTheme.colors.buttonPrimaryActive,
            margin: "2px 0 2px 0",
        },
        "&:disabled": {
            backgroundColor: PbTheme.colors.buttonPrimaryDisabled,
            color: "#AAA"
        }
    }
});
const labelSelectedStyle = style({
    boxShadow: "0px 0px 0px #282828",
    backgroundColor: PbTheme.colors.buttonPrimary,
    $nest: {
        "&::after": {
            content: "'✔'"
        }
    }
});
const labeDisabledStyle = style({
    backgroundColor: PbTheme.colors.buttonPrimaryDisabled,
    color: "#AAA"
});

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    checked?: boolean;
    onStateChanged?: (state: boolean) => void;
}

interface IState {
    className?: string;
    checked: boolean;
}

export class PbCheckbox extends PureComponent<IProps, IState> {
    private _id: Guid = Guid.create();

    constructor(props: IProps) {
        super(props);
        this.state = {
            checked: this.props.checked || false
        };
    }

    public componentDidUpdate(): void {
        if (this.props.checked !== undefined) {
            this.setState({
                checked: this.props.checked
            });
        }
    }

    public render(): JSX.Element {
        const props = { ...this.props };
        delete props.onStateChanged;
        delete props.className;
        let labelClassname = labelStyle;
        if (this.props.disabled) {
            labelClassname = classes(this.props.className, labeDisabledStyle, labelStyle);
        } else {
            if (this.state.checked) {
                labelClassname = classes(this.props.className, labelSelectedStyle, labelStyle);
            } else {
                labelClassname = classes(this.props.className, labelStyle);
            }
        }
        return (
            <>
                <label htmlFor={this._id.toString()} className={labelClassname} />
                <input id={this._id.toString()} type="checkbox" {...props} checked={this.state.checked} className={buttonStyle} onChange={(ev) => this.changeValue(ev)} />
            </>
        );
    }

    private changeValue(ev: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onStateChanged) {
            this.props.onStateChanged(ev.target.checked);
        }
        this.setState({ checked: ev.target.checked || false });
    }
}
