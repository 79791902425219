import { IObservable, ObservableDef } from "../../libs/observables";
import { IPresentationTopic, IPresentationTopicData } from "./Interfaces";
import { IApiTopicPresentations } from "../../apis/PresentationApi";

export class PresentationTopic implements IPresentationTopic {
    private readonly _topicId: string;

    private readonly _obsData: ObservableDef<IPresentationTopicData | undefined> = new ObservableDef<IPresentationTopicData | undefined>(undefined);

    public constructor(apiTopic: IApiTopicPresentations) {
        this._topicId = apiTopic.topicId;
        this.update(apiTopic);
    }

    public get topicId(): string { return this._topicId; }
    public get obsData(): IObservable<IPresentationTopicData | undefined> { return this._obsData; }

    public update(data: IApiTopicPresentations): void {
        //this._presentationService.updateElementsFromSlides(data.elements);

        this._obsData.emit({
            id: data.topicId,
            name: data.name,
            presentations: data.presentations.map(p => ({
                presentationId: p.presentationId,
                name: p.name,
                thumbnail: p.thumbnailUrl,
                expireDate: undefined
            })),
        });
    }
}