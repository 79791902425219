import { FetchResultContent, FetchService } from '../libs/apilib';
import { IAccessToken } from '../services/token/Interfaces';
import { IConfigService } from '../services/config/Interfaces';


export interface UploadFileResult {
    elementId: string;
}

export class UploadApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(tokenGenerator: (() => Promise<IAccessToken>), configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");
        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await tokenGenerator();
            if (token) {
                headers.set("recordcasetoken", token.token);
            }
        });
    }

    public uploadFileAsync(file: File): Promise<FetchResultContent<UploadFileResult>> {
        const url = this.getUri() + "/upload/" + encodeURI(file.name);
        return this._fetchService.fetchPostFileWithResultAsync<UploadFileResult>(url, file);
    }

    private getUri(): string {
        return this._configService.config.elementApiUrl;
    }
}
