import { IStoreReader, IStoreWriter, IStoreWriterInit, StoreWithReader } from "../store";
import { IStoreService } from "./IStoreService";
/* eslint-disable @typescript-eslint/ban-types */

export class StoreService<TModel extends object> implements IStoreService<TModel> {
  private readonly _store = new StoreWithReader<TModel>();

  constructor(model: TModel) {
    this._store = new StoreWithReader<TModel>(model);
  }

  public get storeReader(): IStoreReader<TModel> { return this._store; }

  protected get store(): IStoreWriter<TModel> { return this._store; }
}

export abstract class StoreServiceBase<TModel extends object> implements IStoreService<TModel> {
  private readonly _store = new StoreWithReader<TModel>();

  constructor() {
    this._store = new StoreWithReader<TModel>();
  }

  public get storeReader(): IStoreReader<TModel> { return this._store; }

  protected get store(): IStoreWriterInit<TModel> { return this._store; }
}