import { ConnectCodeApi2 } from '../../apis/ConnectCodeApi2';
import { ConnectCodeService } from './ConnectCodeService';
import { IConnectCodeServiceActivator } from './Interfaces';
import { IDeviceContext } from '../device/Interfaces';
import { ServiceActivator, ServiceOk } from '../../libs/activators';

export class ConnectCodeServiceActivator extends ServiceActivator<ConnectCodeService> implements IConnectCodeServiceActivator {
    constructor(deviceContext: IDeviceContext) {
        const api = new ConnectCodeApi2(deviceContext);

        super(async() => {
            const service = new ConnectCodeService(this, api, ()=>deviceContext.refreshDevice());
            return new ServiceOk(service);
        });
    }
}
