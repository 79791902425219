import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import { AutomatPresentationRow } from './AutomatPresentationRow';
import { ConditionSwitch, ObsDataComponent, PureComponentWithLifetime } from '../../../../../../libs/react';
import { IAutomatPresentationHeader, ILicenseAdvertisement } from '../../../../../../services/licenses/Interfaces';
import { ICustomerContext } from '../../../../../../services/customers/Interfaces';
import { PbButton, PbButtonIcon, PbHeader4, PbParagraph, PbTheme } from '../../../../../../components/pd_themed';
import { SelectPresentationDialog, SelectPresentationDialogExecutor } from './SelectPresentationDialog';
import { style } from 'typestyle';
import { TextStrings } from '../../../../../../lang/TextStrings';

const noPresentationWrapper = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    alignContent: "center",
    justifyContent: "center",
});

const presentationsWrapper = style({
    display: "grid",
    gridTemplateRows: "auto auto",
    gridTemplateColumns: "1fr auto",
    borderRadius: "5px",
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    padding: "5px",
    // alignContent: "center",
    // justifyContent: "center",
});

const editorControlButtonsWrapper = style({
    display: "grid",
    gridTemplateRows: "auto ",
    gridAutoFlow: "column",
    gridAutoColumns: "auto",
});

const presentationsList = style({
    gridColumn: "1 / span 2",
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridTemplateColumns: "1fr",
    rowGap: "5px",
});


const noPresentationAddbuttonStyle = style({
    display: "grid",
    gridTemplateColumns: "auto min-content",
    gridTemplateRows: "1fr",
    alignItems: "center",
    gridColumn: "1 / span 2",
});

const addButtonStyle = style({
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    border: "1px solid gray",
    backgroundColor: "#666",
    boxShadow: "0px 4px 4px #181818",
    margin: "2px 0 2px 0",
    height: "30px",
    borderRadius: "10px",
    transition: PbTheme.transitions.all05x,
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: "#696969",
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "4px 0 0px 0",
            backgroundColor: "#777",
        },
    }
});

interface IProps {
    customer: ICustomerContext;
    licenseAdvertisement: ILicenseAdvertisement;
}

interface IState {
    showNoPresentationMessage: boolean;
    automatPresentations: IAutomatPresentationHeader[];
}

export class AutomatPresentationTable extends PureComponentWithLifetime<IProps, IState>{
    private readonly _selectPresentationDialogExecutor = new SelectPresentationDialogExecutor();

    constructor(props: IProps) {
        super(props);
        this.state = {
            showNoPresentationMessage: false,
            automatPresentations: [],
        };
        this.props.licenseAdvertisement.obsAutomatPresentations.subscribeInitial(this, (presentations) => {
            this.setState({
                showNoPresentationMessage: presentations.length === 0,
                automatPresentations: presentations
            });
        });
    }

    private renderViewMode(): JSX.Element {
        return (
            <div className={presentationsWrapper}>
                <PbHeader4>{TextStrings.textMyPlayerSelectedPresentations}</PbHeader4>
                <PbButtonIcon onClick={() => this.onStartEdit()} ><EditIcon /></PbButtonIcon>
                <ConditionSwitch state={!this.state.showNoPresentationMessage} >


                    <div className={presentationsList}>
                        {this.state.automatPresentations.map((p) => (
                            <AutomatPresentationRow key={p.presentationId} presentation={p} editMode={false} />
                        ))}
                    </div>


                </ConditionSwitch>
                <ConditionSwitch state={this.state.showNoPresentationMessage} >
                    <div className={noPresentationWrapper}>
                        <PbParagraph>Deze speler heeft geen presentaties.</PbParagraph>
                    </div>
                </ConditionSwitch>
            </div>
        );
    }

    private renderEditMode(): JSX.Element {
        return (
            <div className={presentationsWrapper}>
                <PbHeader4>{TextStrings.textMyPlayerSelectedPresentations}</PbHeader4>
                <div className={editorControlButtonsWrapper}>
                    <PbButtonIcon onClick={() => this.onCancel()} ><CloseIcon /></PbButtonIcon>
                    <PbButtonIcon onClick={() => this.onSave()} accentColor={PbTheme.colors.primary} ><CheckIcon /></PbButtonIcon>
                </div>
                <ConditionSwitch state={!this.state.showNoPresentationMessage} >
                    <div className={presentationsList}>
                        <PbButton onClick={() => this.props.licenseAdvertisement.resetDisplayfactor()}>{TextStrings.textMyPlayerPresentationResetDisplayFactor}</PbButton>
                        {this.state.automatPresentations.map((p) => (
                            <AutomatPresentationRow
                                key={p.presentationId}
                                presentation={p}
                                editMode
                                onDeleteButtonClick={() => this.removePresentation(p)}
                                onIncreaseButtonClick={() => this.onIncreaseDisplayFactor(p)}
                                onDecreaseButtonClick={() => this.onDecreaseDisplayFactor(p)}
                            />
                        ))}
                        <div className={addButtonStyle} onClick={this.onAddPresentation}>
                            <AddIcon />
                        </div>
                    </div>
                </ConditionSwitch>
                <ConditionSwitch state={this.state.showNoPresentationMessage} >
                    <PbButton className={noPresentationAddbuttonStyle} onClick={this.onAddPresentation}>Presentatie toevoegen<AddIcon /></PbButton>
                </ConditionSwitch>
            </div>
        );
    }

    public render(): JSX.Element {
        return (
            <>
                <SelectPresentationDialog obsDialogModel={this._selectPresentationDialogExecutor.obsDialogModel} />

                <ObsDataComponent obsData={this.props.licenseAdvertisement.obsPresentationListMode}
                    content={
                        (editMode) => {
                            if (editMode === "View") { return this.renderViewMode() }

                            if (editMode === "Edit") { return this.renderEditMode() }
                            return undefined;
                        }
                    } />
            </>
        );
    }

    private async onStartEdit(): Promise<void> {
        this.props.licenseAdvertisement.switchToEditMode();
    }

    private onAddPresentation = async() => {
        const selectPresentationResult = await this._selectPresentationDialogExecutor.executeAsync();
        if (selectPresentationResult.result) {
            this.props.licenseAdvertisement.addPresentation(selectPresentationResult.output.presentation);
        }
    }

    private onIncreaseDisplayFactor = (presentation: IAutomatPresentationHeader) => {
        this.props.licenseAdvertisement.increaseDisplayFactor(presentation);
    };

    private onDecreaseDisplayFactor = (presentation: IAutomatPresentationHeader) => {
        this.props.licenseAdvertisement.decreaseDisplayFactor(presentation);
    };

    private onCancel = () => {
        this.props.licenseAdvertisement.cancelEdit();
    }

    private onSave = () => {
        this.props.licenseAdvertisement.saveAsync();
    }

    private removePresentation = (presentation: IAutomatPresentationHeader) => {
        this.props.licenseAdvertisement.removePresentation(presentation);
    }
}