import React from "react";

import { IBreadcrumbPathPart, BreadCrumbsContext } from "./BreadCrumbsProvider";
import { PureComponentWithLifetime } from "../../libs/react";
import { Guid } from "guid-typescript";

interface IProps {
    title: string;
    location: string;
}

export class BreadCrumbItemClass extends PureComponentWithLifetime<IProps>{
    private breadCrumbData: IBreadcrumbPathPart;

    static contextType = BreadCrumbsContext;

    context!: React.ContextType<typeof BreadCrumbsContext>;

    constructor(props: IProps) {
        super(props);
        this.breadCrumbData = {
            location: this.props.location,
            title: this.props.title,
            id: Guid.create()
        };
    }

    public componentDidMount(): void {
        if (this.context) {
            this.context.addCrumb(this.breadCrumbData);
        }
    }

    public componentWillUnmount(): void {
        if (this.context) {
            this.context.removeCrumb(this.breadCrumbData);
        }
    }

    public componentDidUpdate(): void {
        if (this.context && (this.breadCrumbData.title !== this.props.title || this.breadCrumbData.location !== this.props.location)) {
            this.updateFromProps();
            this.context.updateCrumb(this.breadCrumbData);
        }
    }

    public render(): JSX.Element | null {
        return null;//this component has no view
    }

    private updateFromProps() {
        this.breadCrumbData.title = this.props.title;
        this.breadCrumbData.location = this.props.location;
    }
}

BreadCrumbItemClass.contextType = BreadCrumbsContext;