export class DateTimeConversions {
    public static parseDateFromString(dateString: string | undefined): Date | undefined {
        if (!dateString) {
            return undefined;
        }
        const dateInt = Date.parse(dateString);
        if (isNaN(dateInt)) {
            return undefined;
        }
        else {
            return new Date(dateInt);
        }
    }
}