import { FetchResultContent, FetchService } from '../libs/apilib';

export interface IApiConfig {
    securityApiUrl: string;
    customerApiUrl: string;
    licenseApiUrl: string;
    pictureApiUrl: string;
    presentationApiUrl: string;
    connectCodesApiUrl: string;
    advertisementLicenseApiUrl: string;
    myPbSoundApiUrl: string;
    spotifyaccountApiUrl: string;
}
export class ConfigApi {
    public getConfigAsync(): Promise<FetchResultContent<IApiConfig>> {
        const fetchService = new FetchService("same-origin");
        const uri = window.location.origin + "/api/config";
        return fetchService.fetchGetAsync<IApiConfig>(uri);
    }
}


const configApi = new ConfigApi();
export default configApi;
