import React from 'react';
import { BreadCrumbItem } from '../../../../../components/pd_themed';
import { TextStrings } from '../../../../../lang/TextStrings';
import { Outlet } from 'react-router-dom';
import { PbBlockLicenses } from '../../license/PbBlockLicenses';
import { SpotifyBlock } from './SpotifyBlock';

export function SpotifyRoute(): JSX.Element | null {
    return (
        <>
            <BreadCrumbItem title={TextStrings.textNarrowCastingBlockTitle} />
            <PbBlockLicenses />
            <SpotifyBlock />
            <Outlet />
        </>
    );
}
