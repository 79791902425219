import React, { PropsWithChildren } from "react";
import { style } from "typestyle";
import { PbTheme } from "../pd_themed/PbTheme";
import { DialogModel, DialogModelUntyped, DialogObservableProps } from "../../libs/react/DialogExecutor";
import { useObservable } from "../../libs/react";

const dialogContainerStyle = style({
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.7)",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    alignItems: "center",
    justifyItems: "center",
    zIndex: 201,
});

const dialogContentStyle = style({
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    padding: "10px",
    borderRadius: "5px",
    margin: "10px",
    maxHeight: "90%",
    maxWidth: "100%",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
});

export type WithPbDialogProps<TInput, TOutput> = {
    dialogModel: DialogModel<TInput, TOutput>;
}

interface IProps {
    open?: boolean;
    dialogModel?: DialogModelUntyped;
}

export function PbDialog(props: PropsWithChildren<IProps>): JSX.Element | null {
    return props.open === true || props.dialogModel ? (
        <div className={dialogContainerStyle} onClick={onClose} >
            <div className={dialogContentStyle} onClick={onClickForm}>
                {props.children}
            </div>
        </div>) : null;

    function onClose(): void {
        if (props.dialogModel) {
            props.dialogModel.close();
        }
    }

    function onClickForm(event: React.MouseEvent): void {
        event.stopPropagation();
    }
}

export function withPbDialog<TInput, TOutput>(WrappedComponent: React.ComponentType<WithPbDialogProps<TInput, TOutput>>) {
    return (props: DialogObservableProps<TInput, TOutput>): JSX.Element | null => {
        const dialogModel = useObservable(props.obsDialogModel);
        return dialogModel ? (
            <PbDialog dialogModel={dialogModel} >
                <WrappedComponent dialogModel={dialogModel} />
            </PbDialog>
        ) : null;
    }
}
