// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { useObservable } from ".";
import { IObservable } from "../observables";

interface IProps<TData> {
    obsData: IObservable<TData | undefined>;
    content: (x: TData) => JSX.Element | null | undefined;
    contentNoData?: () => JSX.Element | null;
}

export function ObsDataComponent<TData>(props: IProps<TData>): JSX.Element | null {
    const data = useObservable(props.obsData)

    if (data) {
        return props.content(data) ?? null;
    }

    const contentNoData = props.contentNoData;
    if (contentNoData) {
        return contentNoData();
    }

    return null;
}