
import { IObservableDef, ObservableDef } from "../../libs/observables";
import { CustomerContext } from "../customers/CustomerContext";
import { IElementTopicService } from "./Interfaces";
import { ElementTopicActivator } from "./ElementTopicActivator";
import { ElementTopicHeader } from "./ElementTopicHeader";
import { ElementTopicServiceActivator } from "./ElementTopicServiceActivator";
import { ElementsApi, IApiTopicHeader } from "../../apis/ElementApi";

export class ElementTopicService implements IElementTopicService {
    private readonly _customer: CustomerContext;
    private readonly _activator: ElementTopicServiceActivator;
    private readonly _obsTopicHeaders: ObservableDef<ElementTopicHeader[]>;
    private readonly _api: ElementsApi;
    private readonly _topicElementsAcivatorMap: Map<string, ElementTopicActivator> = new Map();

    public constructor(customer: CustomerContext, activator: ElementTopicServiceActivator, api: ElementsApi, topics: IApiTopicHeader[]) {
        this._customer = customer;
        this._activator = activator;
        this._api = api;
        this._obsTopicHeaders = new ObservableDef(topics.map(
            t => {
                const topic = new ElementTopicHeader(this, t.topicId, t.name);
                return topic;
            }
        ));
    }

    getTopicElementsActivator(topicId: string): ElementTopicActivator {
        let topicElementsActivator = this._topicElementsAcivatorMap.get(topicId);
        if(!topicElementsActivator){
            topicElementsActivator = new ElementTopicActivator(this._customer, this._api, topicId);
            this._topicElementsAcivatorMap.set(topicId, topicElementsActivator);
        }
        return topicElementsActivator
    }

    public get obsTopics(): IObservableDef<ElementTopicHeader[]> { return this._obsTopicHeaders }
}
