import React, { useState } from "react";
import { DialogExecutor } from "../../../libs/react/DialogExecutor";
import { PbHeader2, PbButton, PBSlider, PbParagraph } from "../../../components/pd_themed"
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay"
import { style } from "typestyle";
import { TextStrings } from "../../../lang/TextStrings";

const dialogContentStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridRowGap: "10px",
    margin: "0 10px"
});

const inputContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto auto",
});

const buttonsContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    gridColumnGap: "6px",
});

interface IInput {
    duration: number;
}

interface IOutput {
    duration: number;
}

export class EditPresentationSlideDurationDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function EditPresentationSlideDurationDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const oldDuration = props.dialogModel.input.duration;
    const [duration, setDuration] = useState<number>(oldDuration);
    const [isValid, setIsValid] = useState<boolean>(false);
    return (
        <div className={dialogContentStyle}>
            <PbHeader2>
                {TextStrings.textPresentationEditSlideDuration}
            </PbHeader2>
            <div className={inputContainerStyle}>
                <PbParagraph>{formatSlideDuration(duration)}</PbParagraph>
                <PBSlider min={1} max={120} initValue={duration} onChange={onSliderChange} expCurve={2} />
            </div>
            <div className={buttonsContainerStyle}>
                <PbButton onClick={() => closeDialog()} >Annuleren</PbButton>
                <PbButton onClick={() => confirmDialog()} disabled={!isValid}>Opslaan</PbButton>
            </div>
        </div>
    );


    function onSliderChange(value: number): void {
        setDuration(value);
        setIsValid(oldDuration !== value);
    }

    function formatSlideDuration(duration: number) {
        const val = Math.round(duration * 10) / 10;
        return `${val}sec`;
    }

    function closeDialog(): void {
        props.dialogModel.close();
    }

    function confirmDialog(): void {
        props.dialogModel.resolve({ duration: duration });
    }
}

export const EditPresentationSlideDurationDialog = withPbDialog<IInput, IOutput>(EditPresentationSlideDurationDialogForm);
