import { Presentation } from "./Presentation";
import { ServiceOk, ServiceActivator, ServiceErrorApi, IActivator } from "../../libs/activators";
import { PresentationService } from "./PresentationService";
import { PresentationApi } from "../../apis/PresentationApi";
import { IElementService } from "../elements/Interfaces";

export class PresentationActivator extends ServiceActivator<Presentation> {
    private readonly _presentationId: string;

    constructor(presentationService: PresentationService, presentationApi: PresentationApi, presentationId: string, elementServiceActivator: IActivator<IElementService>) {
        super(async() => {
            const elementServiceResult = await elementServiceActivator.getResultAsync();
            if (elementServiceResult.isError) {
                return elementServiceResult;
            }
            const elementService = elementServiceResult.service;

            const apiResult = await presentationApi.getPresentationAsync(this._presentationId);
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const service = new Presentation(this, presentationService, presentationApi, presentationId, elementService, apiResult.data);
            return new ServiceOk(service);
        });

        this._presentationId = presentationId;
    }

    public get presentationId(): string { return this._presentationId; }
}


