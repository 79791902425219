import React, { useMemo } from "react";
import { style } from "typestyle";
import { withPbDialog, WithPbDialogProps } from "../../../../../../components/overlay";
import { PbButton, PbButtonText, PbHeader2 } from "../../../../../../components/pd_themed";
import { TextStrings } from "../../../../../../lang/TextStrings";
import { DialogExecutor } from "../../../../../../libs/react/DialogExecutor";
import { IPresentationHeader } from "../../../../../../services/presentations/Interfaces";
import { SelectCustomerPresentationDialog, SelectCustomerPresentationDialogExecutor } from "./SelectCustomerPresentationDialog";
import { SelectPresentationTopicDialog, SelectPresentationTopicDialogExecutor } from "./SelectPresentationTopicDialog";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

type IInput = void;

interface IOutput {
    presentation: IPresentationHeader;
}

export class SelectPresentationDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectPresentationDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const selectCustomerPresentationDialogExecutor = useMemo(() => new SelectCustomerPresentationDialogExecutor(), []);
    const selectPresentationTopicDialog = useMemo(() => new SelectPresentationTopicDialogExecutor(), []);

    return (
        <>
            <div className={dialogContainer}>
                <PbHeader2>{TextStrings.textPresentationSelectorTitle}</PbHeader2>
                <PbButton onClick={() => openCustomerPresentationDialog()}>{TextStrings.textPresentationSelectorFromCustomer}</PbButton>
                <PbButton onClick={() => openPresentationTopicsDialog()}>{TextStrings.textPresentationSelectorFromTopic}</PbButton>
                <PbButtonText onClick={() => closeDialog()}>{TextStrings.textCloseAction}</PbButtonText>
            </div>
            <SelectCustomerPresentationDialog obsDialogModel={selectCustomerPresentationDialogExecutor.obsDialogModel} />
            <SelectPresentationTopicDialog obsDialogModel={selectPresentationTopicDialog.obsDialogModel} />
        </>
    );

    function closeDialog(): void {
        props.dialogModel.close();
    }

    async function openPresentationTopicsDialog() {
        const selection = await selectPresentationTopicDialog.executeAsync();
        if (selection.result) {
            props.dialogModel.resolve({
                presentation: selection.output.presentation,
            });
        }
    }

    async function openCustomerPresentationDialog() {
        const selection = await selectCustomerPresentationDialogExecutor.executeAsync();

        if (selection.result) {
            props.dialogModel.resolve({
                presentation: selection.output.presentation,
            });
        }
    }
}

export const SelectPresentationDialog = withPbDialog<IInput, IOutput>(SelectPresentationDialogForm);
