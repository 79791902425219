import CompareResult from "./CompareResult";

export default class TimeValue {
  private readonly internalHours: number;
  private readonly internalMinutes: number;

  public static fromValues(hours: number, minutes: number): TimeValue {
    this.validateAndThrow(hours, minutes);

    return new TimeValue(hours, minutes);
  }

  public static fromApiOrNull(time: string | null | undefined): TimeValue | null {
    if (time) {
      return this.fromApi(time);
    }

    return null;
  }

  public static fromApiOrUndefined(time: string | null | undefined): TimeValue | undefined {
    if (time) {
      return this.fromApi(time);
    }

    return undefined;
  }

  public static fromApi(time: string): TimeValue {
    if (!time) {
      throw new Error("time must have a value");
    }

    const splitted = time.split(":");

    if (splitted.length !== 2) {
      throw new Error("time invalid format");
    }

    const hours = Number.parseInt(splitted[0].trim());
    const minutes = Number.parseInt(splitted[1].trim());

    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error("Hours/minutes NaN");
    }

    return new TimeValue(hours, minutes);
  }

  public static fromUiStringOrUndefined(time: string | undefined): TimeValue | undefined {
    if (!time) {
      return undefined;
    }

    let splitted = time.split(":");

    if (splitted.length !== 2) {
      splitted = time.split(" ");
    }

    if (splitted.length !== 2) {
      switch (time.length) {
        case 1: splitted = [time, "0"]; break;
        case 2: {
          if (!/\d\d/.test(time)) {
            return undefined;
          }

          const timeNumber = Number.parseInt(time);

          if (timeNumber < 24) {
            splitted = [time, "0"];
          } else {
            splitted = [time.slice(0, 1), time.slice(1, 2)];
          }

          break;
        }

        case 3:
        case 4:
          splitted = [
            time.slice(0, time.length - 2),
            time.slice(time.length - 2),
          ];
          break;
        default: return undefined;
      }
    }

    const hours = Number.parseInt(splitted[0].trim());
    const minutes = Number.parseInt(splitted[1].trim());

    if (isNaN(hours) || isNaN(minutes)) {
      return undefined;
    }

    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 59) {
      return undefined;
    }

    return TimeValue.fromValues(hours, minutes);
  }

  public static fromUiString(time: string): TimeValue {
    const value = this.fromUiStringOrUndefined(time);

    if (!value) {
      throw new Error("Time must have a valid value");
    }

    return value;
  }

  public static fromJsDateOrNull(date: Date | null | undefined): TimeValue | null {
    if (date) {
      return new TimeValue(date.getHours(), date.getMinutes());
    }

    return null;
  }

  public static fromJsDateOrUndefined(date: Date | null | undefined): TimeValue | undefined {
    if (date) {
      return new TimeValue(date.getHours(), date.getMinutes());
    }

    return undefined;
  }

  public static fromJsDate(date: Date): TimeValue {
    return new TimeValue(date.getHours(), date.getMinutes());
  }

  private constructor(hours: number, minutes: number) {
    this.internalHours = hours;
    this.internalMinutes = minutes;
  }

  public get hours(): number {
    return this.internalHours;
  }

  public get minutes(): number {
    return this.internalMinutes;
  }

  public get uiString(): string {
    const hours = this.internalHours.toString().padStart(2, "0");
    const minutes = this.internalMinutes.toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  public get jsDate(): Date {
    const date = new Date(0);

    date.setHours(this.internalHours);
    date.setMinutes(this.internalMinutes);

    return date;
  }

  public get toApiAsTime(): string {
    const hours = this.internalHours.toString().padStart(2, "0");
    const minutes = this.internalMinutes.toString().padStart(2, "0");

    return `${hours}:${minutes}`;
  }

  public toString(): string {
    return `${this.internalHours}:${this.internalMinutes}`;
  }

  public addMinutes(mins: number): TimeValue {
    let minutes = this.internalMinutes;
    let hours = this.internalHours;

    minutes += mins;
    hours = (hours + Math.floor(minutes / 60)) % 24;
    while (this.internalMinutes < 0) { minutes += 60; }

    minutes %= 60;

    return new TimeValue(hours, minutes);
  }

  private static validateAndThrow(hours: number, minutes: number): void {
    if (hours > 24 || hours < 0) {
      throw new Error(`Hours is not of a valid value (${hours})`);
    }

    if (minutes > 59 || minutes < 0) {
      throw new Error(`Minutes is not of a valid value (${minutes})`);
    }
  }

  public toDateTime(date: Date): Date {
    const d = new Date(date);

    d.setHours(this.internalHours, this.internalMinutes, 0, 0);

    return d;
  }

  public static compare(a: TimeValue | null | undefined, b: TimeValue | null | undefined): CompareResult {
    if (!a || !b) {
      return CompareResult.NotEqual;
    }

    if (a.internalHours < b.internalHours) {
      return CompareResult.Smaller;
    }

    if (a.internalHours > b.internalHours) {
      return CompareResult.Bigger;
    }

    if (a.internalMinutes < b.internalMinutes) {
      return CompareResult.Smaller;
    }

    if (a.internalMinutes > b.internalMinutes) {
      return CompareResult.Bigger;
    }

    return CompareResult.Equal;
  }

  public static getDifferenceInMinutes(a: TimeValue, b: TimeValue): number {
    return (a.internalMinutes - b.internalMinutes) + ((a.internalHours - b.internalHours) * 60);
  }
}
