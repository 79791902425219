import { ElementsApi } from "../../apis/ElementApi";
import { ServiceActivator, ServiceErrorApi, ServiceOk } from "../../libs/activators";
import { CustomerContext } from "../customers/CustomerContext";
import { ElementTopic } from "./ElementTopic";

export class ElementTopicActivator extends ServiceActivator<ElementTopic>{
    public constructor(customer: CustomerContext, api: ElementsApi, topicId: string) {
        super(async() => {
            const apiResult = await api.getTopicAsync(topicId);
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }
            const elementService = await customer.elementServiceActivator.getAsync();
            const service = new ElementTopic(elementService, apiResult.data);
            return new ServiceOk(service);
        });
    }
}