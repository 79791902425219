import { FetchResultError } from "../apilib";

// tslint:disable: max-classes-per-file

export interface IServiceOk<T> {
    readonly isError: false;
    readonly service: T;
}

export interface IServiceError {
    readonly isError: true;
    readonly error: string;
    readonly canRetry: boolean;
}

export type ActivatorServiceResult<T> =
    | IServiceOk<T>
    | IServiceError;

export class ServiceOk<T> implements IServiceOk<T> {
    private readonly _service: T;

    constructor(service: T) {
        this._service = service;
    }

    public get isError(): false { return false; }
    public get service(): T { return this._service; }
}

export class ServiceError implements IServiceError {
    private readonly _error: string;
    private readonly _canRetry: boolean;

    constructor(error: string, canRetry = true) {
        this._error = error;
        this._canRetry = canRetry;
    }

    public get isError(): true { return true; }

    public get error(): string { return this._error; }

    public get canRetry(): boolean { return this._canRetry; }
}

export class ServiceErrorApi implements IServiceError {
    private readonly _error: string;
    private readonly _canRetry: boolean;

    constructor(apiResult: FetchResultError) {
        this._error = apiResult.userError;
        this._canRetry = true;
    }

    public get isError(): true { return true; }

    public get error(): string { return this._error; }

    public get canRetry(): boolean { return this._canRetry; }
}
