import React, { useMemo } from "react";
import { style } from "typestyle";
import { ActivatorComponent } from "../../../../../../components/datawaiting/ActivatorComponent";
import { withPbDialog, WithPbDialogProps } from "../../../../../../components/overlay";
import { PbButton, PbHeader2, PbTheme } from "../../../../../../components/pd_themed";
import { TextStrings } from "../../../../../../lang/TextStrings";
import { ObsDataComponent } from "../../../../../../libs/react";
import { DialogExecutor } from "../../../../../../libs/react/DialogExecutor";
import { ICustomerContext } from "../../../../../../services/customers/Interfaces";
import { IPresentationHeader } from "../../../../../../services/presentations/Interfaces";
import { TopicList } from "./TopicList";
import { SelectTopicPresentationDialog, SelectTopicPresentationDialogExecutor } from "./SelectTopicPresentationDialog";
import { IApiPresentationTopicHeader } from "../../../../../../apis/PresentationApi";
import { useCustomerContext } from "../../../UseCustomer";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content auto min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

const scrollContainer = style({
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    padding: "5px",
    gridColumnGap: "10px",
    gridRowGap: "10px",
});

type IInput = void;

interface IOutput {
    presentation: IPresentationHeader;
}

export class SelectPresentationTopicDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectPresentationTopicDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const selectTopicPresentationDialogExecutor = useMemo(() => new SelectTopicPresentationDialogExecutor(), []);
    const customerContext = useCustomerContext();

    return customerContext ? (
        <>
            <ActivatorComponent activator={customerContext.presentationTopicServiceActivator}
                content={(topicService) => (
                    <div className={dialogContainer}>
                        <PbHeader2>{TextStrings.textTopicSelectorTitle}</PbHeader2>
                        <div className={scrollContainer}>
                            <ObsDataComponent obsData={topicService.obsTopics} content={(topics) => (
                                <TopicList onTopicSelect={(topic) => topicSelectionChanged(topic)} topics={topics} />
                            )} />
                        </div>
                        <PbButton onClick={() => closeDialog()}>{TextStrings.textBackAction}</PbButton>
                    </div>
                )} />
            <SelectTopicPresentationDialog obsDialogModel={selectTopicPresentationDialogExecutor.obsDialogModel} />
        </>
    ) : null;

    function closeDialog(): void {
        props.dialogModel.close();
    }

    async function topicSelectionChanged(selectedTopic: IApiPresentationTopicHeader) {
        const presentation = await selectTopicPresentationDialogExecutor.executeAsync({
            topic: selectedTopic,
        });
        if (presentation.result) {
            props.dialogModel.resolve({
                presentation: presentation.output.presentation,
            });
        }
    }
}

export const SelectPresentationTopicDialog = withPbDialog<IInput, IOutput>(SelectPresentationTopicDialogForm);
