import React, { Component } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const inputClassName = style({
    fontFamily: PbTheme.textSizes.family,
    color: PbTheme.colors.textNormal,
    border: "1px solid " + PbTheme.colors.inputBorder,
    backgroundColor: PbTheme.colors.inputBackground,
    outline: "none",
    borderRadius: "30px",
    padding: "8px 15px",
    fontSize: PbTheme.textSizes.sizeNormal,
    boxShadow: "0px 0px 0px #222A",
    transition: PbTheme.transitions.all1x,
    $nest: {
        "&:focus": {
            border: "1px solid " + PbTheme.colors.inputBorderFocus,
            backgroundColor: PbTheme.colors.inputBackgroundFocus,
            boxShadow: "0px 3px 5px #222A",
        },
    }

});

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onDateChange?: (date: Date|undefined) => void;
    dateValue: Date | undefined;
}

export class PbDateTimeInput extends Component<IProps> {
    public render(): JSX.Element {
        const props = { ...this.props };
        let val = undefined;
        if(this.props.dateValue  && !isNaN(this.props.dateValue.valueOf())){
            val = this.props.dateValue.toISOString().substr(0, 16);
        }
        delete props.onDateChange;
        delete props.dateValue;
        return (
            <input type="datetime-local" {...props} value={val} className={classes(inputClassName, this.props.className)} onChange={(evt) => this.onChange(evt)}>
                {this.props.children}
            </input>
        );
    }

    private onChange(evt: React.ChangeEvent<HTMLInputElement>) {
        if (this.props.onDateChange) {
            let value = undefined;
            const date = Date.parse(evt.target.value)
            if(!isNaN(date)){
                value = new Date(date);
                if(isNaN(value.valueOf())){
                    value = undefined;
                }
            }
            this.props.onDateChange(value);
        }
    }
}
