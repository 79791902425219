import React from 'react';
import { PageRouter } from '../PageRouter';
import { PbTheme } from '../../components/pd_themed';
import { style } from 'typestyle';

const mainStyle = style({
    marginTop: PbTheme.header.height,

});

export function AppContent(): JSX.Element {
    return (
        <main className={mainStyle}>
            <PageRouter />
        </main>
    );
}
