import { FetchResultContent, FetchResultNoContent, FetchService } from '../libs/apilib';
import { IApiElement } from './ElementApi';
import { IConfigService } from '../services/config/Interfaces';
import { IAccessTokenProvider } from '../services/token/Interfaces';

export interface IApiPresentationTopicHeaders{
    topics: IApiPresentationTopicHeader[];
}

export interface IApiPresentationTopicHeader {
    topicId: string;
    name: string;
}

export interface IApiPresentations {
    presentations: IApiPresentationsPresentation[];
}

export interface IApiTopicPresentations {
    topicId:string;
    name:string;
    presentations: IApiPresentationsPresentation[];
}

export interface IApiTopicPresentations {
    topicId: string;
    name: string;
    presentations: IApiPresentationsPresentation[];
}

export interface IApiPresentationsPresentation {
    presentationId: string;
    name: string;
    thumbnailUrl: string;
    presentationExpiredDateTime?: string;
}

export interface IApiPresentation {
    presentationId: string;
    name: string;
    presentationExpiredDateTime?: string;
    defaultSlideDurationInSeconds: number;
    elements: IApiElement[];
    slides: IApiPresentationSlide[];
}

export interface IApiPresentationDeletionResult {
    presentationId: string;
    deleted: boolean;
}

export interface IApiPresentationSlide {
    slideId: string;
    elementId: string;
}

export interface IApiPresentationUpdate {
    presentationId: string;
    name?: string;
    expire?: {
        dateTime?: string| null
    };
    defaultSlideDurationInSeconds?: number;
    slides?: IApiPresentationUpdateSlide[];
    thumbnailElementId?: string;
}

export interface IApiPresentationUpdateSlide {
    slideId?: string;
    elementId?: string;
}

export interface IApiPresentationCreate {
    name: string;
    elementIds: string[];
}

export interface IApiPresentationCreateResult {
    presentationId: string;
    thumbnail: string;
}

export class PresentationApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(accessTokenProvider: IAccessTokenProvider, configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");

        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await accessTokenProvider.getTokenAsync();
            headers.set("recordcasetoken", token.token);
        });
    }

    public getPresentationsAsync(): Promise<FetchResultContent<IApiPresentations>> {
        return this._fetchService.fetchGetAsync<IApiPresentations>(this.getUri());
    }

    public getPresentationAsync(presentationId: string): Promise<FetchResultContent<IApiPresentation>> {
        return this._fetchService.fetchGetAsync<IApiPresentation>(`${this.getUri()}/presentation/${presentationId}`);
    }

    public updatePresentationAsync(data: IApiPresentationUpdate): Promise<FetchResultNoContent> {
        return this._fetchService.fetchPutAsync(`${this.getUri()}/presentation/${data.presentationId}`, data);
    }

    public createPresentationAsync(data: IApiPresentationCreate): Promise<FetchResultContent<IApiPresentationCreateResult>> {
        return this._fetchService.fetchPostWithResultAsync<IApiPresentationCreateResult>(`${this.getUri()}`, data);
    }

    public deletePresentationAsync(presentationId: string): Promise<FetchResultNoContent> {
        return this._fetchService.fetchDeleteAsync(`${this.getUri()}/presentation/${presentationId}`);
    }

    public getPresentationTopicsAsync(): Promise<FetchResultContent<IApiPresentationTopicHeaders>> {
        return this._fetchService.fetchGetAsync<IApiPresentationTopicHeaders>(`${this.getUri()}/topics`);
    }

    public getPresentationTopicPresentationsAsync(topicId:string): Promise<FetchResultContent<IApiTopicPresentations>> {
        return this._fetchService.fetchGetAsync<IApiTopicPresentations>(`${this.getUri()}/topic/${topicId}`);
    }

    private getUri(): string {
        return this._configService.config.presentationApiUrl;
    }
}

