import React, { Component } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const headerClassName = style({
    fontSize: PbTheme.textSizes.sizeHeader1,
    fontFamily: PbTheme.textSizes.family,
    color: PbTheme.colors.textNormal,
    margin: "0.1em 0"
});

interface IProps extends React.HTMLAttributes<HTMLHeadingElement> {
    className?: string;
}

export class PbHeader1 extends Component<IProps> {
    public render(): JSX.Element {
        return (
            <h1 {...this.props} className={classes(headerClassName, this.props.className)}>
                {this.props.children}
            </h1>
        );
    }
}
