import React, { Component } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const inputClassName = style({
    fontFamily: PbTheme.textSizes.family,
    color: PbTheme.colors.textNormal,
    border: "1px solid " + PbTheme.colors.inputBorder,
    backgroundColor: PbTheme.colors.inputBackground,
    outline: "none",
    borderRadius: "30px",
    padding: "8px 15px",
    fontSize: PbTheme.textSizes.sizeNormal,
    boxShadow: "0px 0px 0px #222A",
    transition: PbTheme.transitions.all1x,
    $nest: {
        "&:focus": {
            border: "1px solid " + PbTheme.colors.inputBorderFocus,
            backgroundColor: PbTheme.colors.inputBackgroundFocus,
            boxShadow: "0px 3px 5px #222A",
        },
    }

});

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    onTextChange?: (text: string) => void;
    autoFocus? : boolean;
}

export class PbTextInput extends Component<IProps> {
    private _value:string;

    constructor(props: IProps){
        super(props);
        this._value = String(props.value) || "";
    }

    public render(): JSX.Element {
        const props = { ...this.props };
        delete props.onTextChange;
        return (
            <input type="text" {...props} className={classes(inputClassName, this.props.className)} onChange={(evt) => this.onChange(evt)}>
                {this.props.children}
            </input>
        );
    }

    private onChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const newValue = evt.target.value;
        if (this.props.onTextChange && this._value !== newValue ) {
            this._value = newValue;
            this.props.onTextChange(newValue);
        }
    }
}
