import { DeviceApi } from '../../apis/DeviceApi';
import { DeviceVerificationHelper } from './DeviceVerificationHelper';
import {
    IAccessToken,
    IAccessTokenProvider,
    IDeviceToken,
    IDeviceTokenProvider,
    IGetDeviceTokenArgs
    } from './Interfaces';
import { IConfigService } from '../config/Interfaces';
import { IObservable, ObservableOpt } from '../../libs/observables';
import { PromiseAggregator } from '../../libs/utils';

export class DeviceTokenProvider implements IDeviceTokenProvider, IAccessTokenProvider {
    private readonly _deviceVerificationHelper: DeviceVerificationHelper = new DeviceVerificationHelper();
    private readonly _myPbSoundApi: DeviceApi;
    private _accessToken: IAccessToken | undefined;
    private _tokenArgs: IGetDeviceTokenArgs = {};
    private _deviceToken: IDeviceToken | undefined;
    private _promiseAggregator = new PromiseAggregator(false, () => this.getTokenInternalAsync());
    private _obsToken: ObservableOpt<IAccessToken> = new ObservableOpt();
    private _obsDeviceNr: ObservableOpt<number> = new ObservableOpt<number>();

    constructor(configService: IConfigService) {
        this._myPbSoundApi = new DeviceApi(configService);
    }

    public get obsToken(): IObservable<IAccessToken> { return this._obsToken; }
    public get obsDeviceNr(): IObservable<number> { return this._obsDeviceNr; }

    public setTokenArgs(args: IGetDeviceTokenArgs): void {
        if (args.customerId) {
            this._tokenArgs = {
                customerId: args.customerId
            };
        } else {
            this._tokenArgs = args;
        }

        this.invalidateToken();
    }

    public async getTokenAsync(): Promise<IAccessToken> {
        if (this._accessToken) {
            return this._accessToken;
        }
        return this._promiseAggregator.executeAsync();
    }

    public invalidateToken(): void {
        this._accessToken = undefined;
    }

    public getAccessToken(customerId?: string): Promise<IAccessToken> {
        return this.getTokenInternal2Async(customerId);
    }

    private async getTokenInternalAsync(): Promise<IAccessToken> {
        const token = await this.getTokenInternal2Async(this._tokenArgs.customerId);

        this._accessToken = token;
        this._obsToken.emit(token);

        return token;
    }

    private async getTokenInternal2Async(customerId?: string): Promise<IAccessToken> {
        const deviceToken = await this.verifyDeviceAsync();
        const accessTokenResult = await this._myPbSoundApi.getAccessTokenAsync(deviceToken.deviceToken, { customerId });

        if (accessTokenResult.resultType === "error") {
            throw new Error("Failed to get accesstoken");
        }

        const token = {
            ...accessTokenResult.data,
            deviceNumber: deviceToken.deviceNumber,
            isCustomer: !!accessTokenResult.data.customerId,
            isUser: !!accessTokenResult.data.userId,
        }
        return token;
    }

    private async verifyDeviceAsync(): Promise<IDeviceToken> {
        if (this._deviceToken) {
            return this._deviceToken;
        }
        const signature = await this.getSignatureAsync();
        if (!signature || signature.result === "NoKeyPair") {
            const err =  new Error("Failed to get secret");
            console.error(err);
            throw err;
        }
        const tokenDeviceResult = await this._myPbSoundApi.getDeviceTokenAsync({
            publicKey: signature.publicKey,
            signature: signature.signature,
        });
        if (tokenDeviceResult.resultType === "error") {
            throw new Error("Failed to get devicetoken");
        }
        this._deviceToken = tokenDeviceResult.data;
        this._obsDeviceNr.emit(tokenDeviceResult.data.deviceNumber);
        return this._deviceToken;
    }

    private async getSignatureAsync() {
        const publicKey = await this._deviceVerificationHelper.getPublicKeyAsync();
        const deviceSecret = await this._myPbSoundApi.getSecretAsync(publicKey);
        if (deviceSecret.resultType === "error") {
            return;
        }
        return await this._deviceVerificationHelper.createSignedStringAsync(deviceSecret.data.secret);
    }
}
