import { DialogExecutor } from '../../libs/react/DialogExecutor';
import { EditPresentationExpireDateTimeDialogExecutor } from '../../pages/editors/presentations/EditPresentationExpireDateTimeDialog';
import { EditPresentationNameDialogExecutor } from '../../pages/editors/presentations/EditPresentationNameDialog';
import { EditPresentationSlideDurationDialogExecutor } from '../../pages/editors/presentations/EditPresentationSlideDurationDialog';
import { SelectElementsDialogExecutor } from '../../pages/editors/presentations/SelectElementsDialog';
import { SlideSettingsDialogExecutor } from '../../pages/editors/presentations/SlideSettingsDialog';
import { IDialogContext } from './Interfaces';


export class DialogContext implements IDialogContext {
    private readonly _editPresentationNameDialogExecutor = new EditPresentationNameDialogExecutor();
    private readonly _selectElementsDialogExecutor = new SelectElementsDialogExecutor();
    private readonly _confirmDeletionDialogExecutor = new DialogExecutor<string, void>();
    private readonly _editPresentationExpireDateTimeDialogExecutor = new EditPresentationExpireDateTimeDialogExecutor();
    private readonly _editPresentationSlideDurationDialogExecutor = new EditPresentationSlideDurationDialogExecutor();
    private readonly _slideSettingsDialogExecutor = new SlideSettingsDialogExecutor();


    public get editPresentationNameDialogExecutor(): EditPresentationNameDialogExecutor { return this._editPresentationNameDialogExecutor; }

    public get selectElementsDialogExecutor(): SelectElementsDialogExecutor { return this._selectElementsDialogExecutor; }

    public get confirmDeletionDialogExecutor(): DialogExecutor<string, void> { return this._confirmDeletionDialogExecutor; }

    public get editPresentationExpireDateTimeDialogExecutor(): EditPresentationExpireDateTimeDialogExecutor { return this._editPresentationExpireDateTimeDialogExecutor; }

    public get editPresentationSlideDurationDialogExecutor(): EditPresentationSlideDurationDialogExecutor { return this._editPresentationSlideDurationDialogExecutor; }

    public get slideSettingsDialogExecutor(): SlideSettingsDialogExecutor { return this._slideSettingsDialogExecutor; }
}