import { ILifetimeContainer, ILifetime } from "../lifetime";
import { ObservableBase } from "./ObservableBase";

export class Subscription<T> implements ILifetime {
    private readonly _observable: ObservableBase<T>;
    private readonly _callback: (value: T) => (void);
    private _isDisposed: boolean;
    private _value: T | undefined;

    constructor(callback: (value: T) => (void), lifetimeContainer: ILifetimeContainer, observable: ObservableBase<T>) {
        this._callback = callback;
        this._observable = observable;
        this._isDisposed = false;

        lifetimeContainer.addLifetime(this);
        observable.addSubscription(this);
    }

    public emitValue(value: T): void {
        this._value = value;
        this.emitInternal();
    }

    public dispose(): void {
        this._isDisposed = true;
        this._observable.deleteSubscription(this);
    }

    private emitInternal(): void {
        try {
            setTimeout((callback, value) => {
                if (!this._isDisposed) {
                    callback(value);
                }
            }, 0, this._callback, this._value);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Event failed to emit", this, this._value, err);
        }
    }
}
