import React from 'react';
import { PbBlockContainer, PbButton, PbButtonText, PbHeader2, PbParagraph } from '../../../../../components/pd_themed';
import { style } from 'typestyle';
import { TextStrings } from '../../../../../lang/TextStrings';
import { useLicenseContext } from '../narrow_casting/UseLicense';
import { ModelStateComponent } from '../../../../../components/datawaiting';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

export function SpotifyBlock(): JSX.Element | null {
    const licenseContext = useLicenseContext()

    return licenseContext ? (
        <PbBlockContainer>
            <div className={containerStyle} >
                <PbHeader2>{TextStrings.textSpotifyBlockTitle}</PbHeader2>
                <ModelStateComponent obsModelState={licenseContext.spotifyContext.obsModelState} content={(model) => (
                    <>
                        <PbParagraph>
                            {TextStrings.textSpotifyDescription}
                        </PbParagraph>

                        <PbParagraph>{model.status}</PbParagraph>

                        {model.canConnect &&
                            <PbButton autoFocus onClick={() => model.connect()}>
                                {TextStrings.textSpotifyDialogConnectAction}
                            </PbButton>
                        }

                        {model.canDisconnect &&
                            <PbButtonText onClick={() => model.disconnect()}>
                                {TextStrings.textSpotifyDialogDisconnectAction}
                            </PbButtonText>
                        }
                    </>
                )} />
            </div>
        </PbBlockContainer>
    ) : null;
}
