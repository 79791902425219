import React, { PureComponent } from "react";
import { style } from "typestyle";
import { PbHeader4, PbTheme } from "../../../../../../components/pd_themed";
import { IPresentationTopicHeader } from "../../../../../../services/presentation_topics/Interfaces";

const listStyle = style({
    display: "grid",
    gridColumnGap: "10px",
    gridRowGap: "10px",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    background: PbTheme.colors.backgroundBlockLv3,
});

const listItemStyle = style({
    display: "grid",
    background: PbTheme.colors.backgroundBlockLv4,
    padding: "10px",
    borderRadius: "3px",
    userSelect: "none"
});

interface IProps {
    topics: IPresentationTopicHeader[];
    onTopicSelect: (selection: IPresentationTopicHeader) => void;
}

export class TopicList extends PureComponent<IProps>{
    constructor(props: IProps) {
        super(props);
        this.state = {
        };
    }

    public render(): JSX.Element {
        return (
            <div className={listStyle}>
                {
                    this.props.topics.map((topic) => {
                        return (
                            <div key={topic.topicId} className={listItemStyle} onClick={() => this.onTopicSelected(topic)} >
                                <PbHeader4>{topic.name}</PbHeader4>
                            </div>
                        );
                    })
                }
            </div>
        );
    }

    private onTopicSelected(topic: IPresentationTopicHeader): void {
        this.props.onTopicSelect(topic);
    }
}