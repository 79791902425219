import { ApplicationService } from './ApplicationService';
import { ConfigServiceActivator } from '../config/ConfigServiceActivator';
import { DeviceTokenProvider } from '../token/DeviceTokenProvider';
import { ServiceActivator, ServiceError, ServiceOk } from '../../libs/activators';

export class ApplicationServiceActivator extends ServiceActivator<ApplicationService> {
    private deviceTokenProvider: DeviceTokenProvider | undefined;
    private configServiceActivator = new ConfigServiceActivator();

    constructor() {
        super(async() => {
            const configServiceResult = await this.configServiceActivator.getResultAsync();
            if (configServiceResult.isError === true) {
                return configServiceResult;
            }

            const configService = configServiceResult.service;

            this.deviceTokenProvider = this.deviceTokenProvider || new DeviceTokenProvider(configService);
            const devicetoken = await this.deviceTokenProvider.getTokenAsync();
            if(!devicetoken){
                return new ServiceError("Device token could not be received");
            }
            const applicationService = new ApplicationService(configService, this.deviceTokenProvider);
            return new ServiceOk(applicationService);
        });
    }
}

const applicationServiceActivator = new ApplicationServiceActivator();
export default applicationServiceActivator;
