import { CustomerContext } from '../customers/CustomerContext';
import { IApiLicensesLicense } from '../../apis/LicensesApi';
import { ILicensesModel } from './Interfaces';
import { ObservableOpt } from '../../libs/observables';
import { LicenseContext } from './LicenseContext';
import { StoreServiceBase } from '../../libs/activators';

export class LicensesService extends StoreServiceBase<ILicensesModel> {
    private readonly _customerContext: CustomerContext;
    private readonly _obsSelectedLicense = new ObservableOpt<LicenseContext>();
    private readonly _obsSingleLicense = new ObservableOpt<LicenseContext>();
    private readonly _licenseMap = new Map<string, LicenseContext>();

    constructor(customerContext: CustomerContext, licensesApiData: IApiLicensesLicense[]) {
        super();
        this._customerContext = customerContext;

        const licenses = licensesApiData.map((apiLicense) => ({
            licenseId: apiLicense.licenseIdentity,
            description: apiLicense.licenseName
        }));

        const singleLicense = licenses.length === 1
            ? licenses[0]
            : undefined;

        this._customerContext.licenses.updateLicenses(licenses);

        this.store.initModel({
            licenses,
            singleLicense,
            hasSingleLicense: !!singleLicense,
            hasNoLicense: licenses.length === 0,
        });
    }
}
