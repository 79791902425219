import { ActivatorExecuteResult } from '../../libs/activators';
import { ConnectCodeApi2 } from '../../apis/ConnectCodeApi2';
import { ConnectCodeServiceActivator } from './ConnectCodeServiceActivator';
import { ConnectCodeStatus, IConnectCodeService } from './Interfaces';
import { IObservable, ObservableDef } from '../../libs/observables';

export class ConnectCodeService implements IConnectCodeService {
    private readonly _obsStatus: ObservableDef<ConnectCodeStatus>;

    private readonly _activator: ConnectCodeServiceActivator;
    private readonly _api: ConnectCodeApi2;
    private readonly _connectCodeCreatedCallback: () => void;

    constructor(activator: ConnectCodeServiceActivator, api: ConnectCodeApi2, connectCodeCreatedCallback: () => void) {
        this._activator = activator;
        this._api = api;
        this._connectCodeCreatedCallback = connectCodeCreatedCallback;

        this._obsStatus = new ObservableDef<ConnectCodeStatus>({
            state: "AskForCode",
        });
    }

    public get obsStatus(): IObservable<ConnectCodeStatus> { return this._obsStatus; }

    public async validateConnectCode(connectCode: string): Promise<void> {
        this._activator.executeAsync(
            () => this.validateConnectCodeInternal(connectCode),
            `Valideren van code: ${connectCode}`);
    }

    private async validateConnectCodeInternal(connectCode: string): Promise<ActivatorExecuteResult> {
        const result = await this._api.connectAsync({ connectCode });

        if (result.resultType === "error") {
            if(result.errorType === "NotFound"){
                this._obsStatus.emit({state: "Invalid"});
                return {
                    isError: false,
                    refreshService: false
                }
            }
            return {
                isError: true,
                error: result.error,
                canRetry: true,
            };
        }
        this._obsStatus.emit({state: "Done"});
        this._connectCodeCreatedCallback();
        return {
            isError: false,
            refreshService: false
        }
    }

    public reset(): void{
        this._obsStatus.emit({state: "AskForCode"});
    }
}
