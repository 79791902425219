import { IActivator } from "./IActivator";
import { ModelState } from "./ModelState";
import { IObservableDef, ObservableDef } from "../observables";
import { PromiseAggregator } from "../utils";
import { ActivatorExecuteResult } from "./ActivatorExecuteResult";
import { ActivatorServiceResult } from "./ActivatorServiceResult";

type Factory<TService> = () => Promise<ActivatorServiceResult<TService>>;

export class ServiceActivator<TService> implements IActivator<TService> {
    private readonly _aggregator: PromiseAggregator<ActivatorServiceResult<TService>>;
    private readonly _obsService = new ObservableDef<ModelState<TService>>({ type: "init", message: "" });
    private readonly _factory: Factory<TService>;
    private _loaded: boolean;
    private _isBusy: boolean;
    private _service: TService | undefined;

    constructor(factory: Factory<TService>) {
        this._factory = factory;
        this._loaded = false;
        this._isBusy = false;
        this._aggregator = new PromiseAggregator<ActivatorServiceResult<TService>>(false, () => this.loadAsync());
    }

    public get obsService(): IObservableDef<ModelState<TService>> {
        if (!this._loaded) {
            this.loadAsync();
        }

        return this._obsService;
    }

    public get service(): TService | undefined { return this._service; }

    public get isBusy(): boolean { return this._isBusy; }

    public getResultAsync(): Promise<ActivatorServiceResult<TService>> {
        return this._aggregator.executeAsync();
    }

    public async getAsync(): Promise<TService> {
        const result = await this.getResultAsync();
        if (result.isError === true) {
            throw new Error(result.error);
        }

        return result.service;
    }

    public recreateService(): void {
        if (this._isBusy) {
            throw new Error("Cannot refresh during execute. Return 'new ServiceRefresh()' instead");
        }

        if (this._loaded) {
            this.setInit("Verversen");
            this._loaded = false;
            this._service = undefined;
            this.getResultAsync();
        }
    }

    public clearService(): void {
        if (this._isBusy) {
            return;
        }

        if (this._loaded) {
            this._loaded = false;
            this._service = undefined;
            this._obsService.emit({
                type: "initerror",
                error: "Stopped",
                onRetry: () => this.recreateService(),
            });
        }
    }

    public tryResetError(): boolean {
        if (this._obsService.value.type === "initerror" || this._obsService.value.type === "updateerror") {
            if (this._service) {
                this._obsService.emit({
                    type: "data",
                    data: this._service,
                });
            } else {
                this.getResultAsync();
            }
        }
        return false;
    }

    public async executeAsync(callback: () => Promise<ActivatorExecuteResult>, busyMessage?: string): Promise<boolean> {
        if (this._isBusy) {
            return false;
        }

        this.setUpdate(busyMessage);
        try {
            const result = await callback();

            this.setResult(result);
            if (!result.isError && result.refreshService) {
                this.recreateService();
            }
            return true;
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (exception: any) {
            this.setUpdateError(exception.toString(), false);
            return false;
        }
    }

    private async loadAsync(): Promise<ActivatorServiceResult<TService>> {
        try {
            this._loaded = true;
            const factoryResult = await this._factory();
            if (factoryResult.isError === true) {
                this.setInitError(factoryResult.error, factoryResult.canRetry);
                return {
                    isError: true,
                    canRetry: factoryResult.canRetry,
                    error: factoryResult.error,
                };
            } else {
                this._service = factoryResult.service;
                this.setReady();
                return {
                    isError: false,
                    service: factoryResult.service,
                };
            }
        } catch (e) {
            const error = "Er is een fout opgetreden tijdens het laden van de gegegevens";
            const canRetry = false;

            this.setInitError(error, canRetry);
            return {
                isError: true,
                canRetry,
                error,
            };
        }
    }

    private setReady() {
        this._isBusy = false;
        if (this._service) {
            this._obsService.emit({
                type: "data",
                data: this._service,
            });
        } else {
            this._obsService.emit({
                type: "initerror",
                onRetry: () => this.recreateService(),
                error: "Er is een fout opgetreden tijdens het laden van de gegegevens",
            });
        }
    }

    private setInit(initMessage: string | undefined) {
        this._isBusy = true;
        this._obsService.emit({
            type: "init",
            message: initMessage || "",
        });
    }

    private setUpdate(updateMessage: string | undefined) {
        this._isBusy = true;
        if (this._service) {
            this._obsService.emit({
                type: "update",
                message: updateMessage || "",
                data: this._service,
            });
        } else {
            this.setInit(updateMessage);
        }
    }

    private setInitError(error: string, canRetry: boolean) {
        this._isBusy = false;
        this._obsService.emit({
            type: "initerror",
            onRetry: canRetry ? () => this.recreateService() : undefined,
            error,
        });
    }

    private setUpdateError(error: string, canRetry: boolean) {
        this._isBusy = false;
        if (this._service) {
            this._obsService.emit({
                type: "updateerror",
                onRetry: canRetry ? () => this.recreateService() : undefined,
                error,
                data: this._service,
            });
        } else {
            this.setInitError(error, canRetry);
        }
    }

    private setResult(result: ActivatorExecuteResult) {
        this._isBusy = false;
        if (result.isError) {
            if (this._service) {
                this.setUpdateError(result.error, result.canRetry);
            } else {
                this.setInitError(result.error, result.canRetry);
            }
        } else {
            this.setReady();
        }
    }
}
