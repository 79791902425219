import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import { ICustomerContext } from '../../../../services/customers/Interfaces';
import { IDeviceContext } from '../../../../services/device/Interfaces';
import { IPresentationHeader, IPresentationService } from '../../../../services/presentations/Interfaces';
import { ObsDataComponent, PureComponentWithLifetime } from '../../../../libs/react';
import { PbButton, PbPresentationList } from '../../../../components/pd_themed';
import { PresentationOverlay, PresentationOverlayExecuter } from './PresentationOverlay';
import { style } from 'typestyle';
import { TextStrings } from '../../../../lang/TextStrings';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

const buttonStyle = style({
    display: "grid",
    gridTemplateColumns: "auto min-content",
    gridTemplateRows: "1fr",
    alignItems: "center"
});

interface IProps {
    deviceContext: IDeviceContext;
    customer: ICustomerContext;
    presentationService: IPresentationService;
}

export class PresentationHeaderList extends PureComponentWithLifetime<IProps>{
    private readonly _presentationOverview = new PresentationOverlayExecuter();

    public render(): JSX.Element {
        return (
            <div className={containerStyle}>
                <PbButton className={buttonStyle} onClick={() => this.createNewPresentation()}>{TextStrings.textMyPresentationsAddAction}<AddIcon /></PbButton>
                <ObsDataComponent obsData={this.props.presentationService.obsPresentationHeaders}
                    content={(headers) => (
                        <PbPresentationList presentations={headers} onClick={(p) => this.presentationClick(p)} />
                    )} />
                <PresentationOverlay obsDialogModel={this._presentationOverview.obsDialogModel} />
            </div>
        );
    }

    private presentationClick(presentation: IPresentationHeader) {
        this.openPresentationAsync(presentation.presentationId);
    }

    private async openPresentationAsync(presentationId: string): Promise<void> {
        await this._presentationOverview.executeAsync({
            customer: this.props.customer,
            presentationService: this.props.presentationService,
            presentationId,
        });
    }

    private async createNewPresentation(): Promise<void> {
        const dialogContext = this.props.deviceContext.dialogContext;
        const nameResult = await dialogContext.editPresentationNameDialogExecutor.executeAsync({ type: "new" });
        if (nameResult.result) {
            const name = nameResult.output.name;
            const elementsResult = await dialogContext.selectElementsDialogExecutor.executeAsync({ customerContext: this.props.customer });
            if (elementsResult.result) {
                const newId = await this.props.presentationService.createNewPresentationAsync(name, elementsResult.output.elements);
                if (newId) {
                    this.props.customer.deviceContext.alertAsync(TextStrings.formatPresentationCreated(name));
                    await this.openPresentationAsync(newId);
                }
            }
        }
    }
}