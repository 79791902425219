import React from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import { PbBlockContainer, PbButton, PbHeader2, PbParagraph, PbTheme } from '../../../components/pd_themed';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

const buttonContenStyle = style({
    display: "grid",
    gridTemplateColumns: "min-content 1fr",
    alignItems: "center"
});

export function PbBlockController(): JSX.Element | null {
    return (
        <PbBlockContainer>
            <div className={containerStyle} >
                <PbHeader2>{TextStrings.textMyController}</PbHeader2>
                <PbParagraph>{TextStrings.textMyControllerDescription}</PbParagraph>
                <PbButton onClick={() => handleControllerStartClick()} >
                    <div className={buttonContenStyle}>
                        <LanguageIcon htmlColor={PbTheme.colors.textNormal} />
                        <span>{TextStrings.textMyControllerStartAction}</span>
                    </div>
                </PbButton>
            </div>
        </PbBlockContainer>
    );

    function handleControllerStartClick(): void {
        window.open("https://controller.pbsound.nl", "pbcontroller");
    }
}