import { useContext, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { useDeviceContext } from './main/AppLayout';
import { useObservable } from '../libs/react';

let started = false;

export function useSearchParamMapping(): void {
    const deviceContext = useDeviceContext();
    const [params, setParams] = useSearchParams();
    const selectedCustomerContext = useObservable(deviceContext?._customers.obsSelectedCustomerContext);
    const selectedLicenseContext = useObservable(selectedCustomerContext?.licenses.obsSelectedLicenseContext);
    const customerId = params.get("customer");
    const licenseId = params.get("license");

    if (!started) {
        // First time after start
        started = true;

        if (deviceContext && !deviceContext._customers.selectedCustomerId) {
            if (customerId) {
                const customerContext = deviceContext._customers.select(customerId);

                if (licenseId && !customerContext.licenses.selectedLicenseId) {
                    customerContext.licenses.select(licenseId);
                }
            }
        }
    }

    useEffect(() => {
        const searchParams = createSearchParams(params);

        if (selectedCustomerContext) {
            searchParams.set("customer", selectedCustomerContext.customerId);
        }
        else {
            searchParams.delete("customer");
        }

        if (selectedLicenseContext) {
            searchParams.set("license", selectedLicenseContext.licenseId);
        }
        else {
            searchParams.delete("license");
        }

        setParams(searchParams.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCustomerContext, selectedLicenseContext, customerId, licenseId]);
}
