import React, { PureComponent } from 'react';
import { classes, style } from 'typestyle';
import { PbTheme } from './PbTheme';

const buttonStyle = style({
    color: PbTheme.colors.textNormal,
    backgroundColor: PbTheme.colors.buttonPrimary,
    transition: PbTheme.transitions.all05x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    display: "grid",
    gridTemplateRows: "min-content",
    gridTemplateColumns: "min-content",
    borderRadius: "100%",
    border: "none",
    outline: "0",
    width: "55px",
    height: "55px",
    justifyContent: "center",
    alignContent: "center",
    boxShadow: "0px 4px 4px #181818",
    margin: "4px 0 4px 0",
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: PbTheme.colors.buttonPrimaryHover,
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "6px 0 2px 0",
            backgroundColor: PbTheme.colors.buttonPrimaryActive,
        }
    }
});

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
}

interface IState {
    className?: string;
}

export class PbButtonFab extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        if (props.className) {
            this.state = {
                className: classes(props.className, buttonStyle)
            };
        } else {
            this.state = {
                className: classes(buttonStyle)
            };
        }
    }

    public render(): JSX.Element {
        return (
            <button {...this.props} type="button" className={this.state.className} >
                {this.props.children}
            </button>
        );
    }
}
