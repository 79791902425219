import React, { PureComponent } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const buttonBaseStyle = style({
    color: PbTheme.colors.textNormal,
    transition: PbTheme.transitions.all05x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    padding: "0.6em 1em 0.6em 1em",
    borderRadius: "100px",
    outline: "0",
    alignContent: "center",
    margin: "0px 0 4px 0",
});

const primaryButtonColors = style({
    backgroundColor: PbTheme.colors.buttonPrimary,
    border: "none",
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: PbTheme.colors.buttonPrimaryHover,
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "2px 0 2px 0",
            backgroundColor: PbTheme.colors.buttonPrimaryActive,
        },
        "&:disabled": {
            backgroundColor: PbTheme.colors.buttonPrimaryDisabled,
        }
    }
});

const secondaryButtonColors = style({
    backgroundColor: PbTheme.colors.buttonSecondary,
    border: `solid 1px ${PbTheme.colors.buttonSecondaryBorder}`,
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: PbTheme.colors.buttonSecondaryHover,
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "2px 0 2px 0",
            backgroundColor: PbTheme.colors.buttonSecondaryActive,
        },
        "&:disabled": {
            backgroundColor: PbTheme.colors.buttonSecondaryDisabled,
        }
    }
});

const primaryButtonStyle = classes(primaryButtonColors, buttonBaseStyle);
const secondaryButtonStyle = classes(secondaryButtonColors, buttonBaseStyle);

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    className?: string;
    disabled?: boolean;
    isSecondary?: boolean;
}

interface IState {
    className?: string;
}

export class PbButton extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        const buttonStyle = this.props.isSecondary ? secondaryButtonStyle : primaryButtonStyle
        if (props.className) {
            this.state = {
                className: classes(props.className, buttonStyle)
            };
        } else {
            this.state = {
                className: classes(buttonStyle)
            };
        }
    }

    public render(): JSX.Element {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const props = {...this.props} as any;
        if(props.isSecondary !== undefined){
            delete props.isSecondary
        }
        return (
            <button {...props} type="button" className={this.state.className} />
        );
    }
}
