import { CustomerContext } from '../customers/CustomerContext';
import { CustomersApi } from '../../apis/CustomersApi';
import { DeviceContext } from '../device/DeviceContext';
import {
    ExecuteErrorApi,
    ServiceOk,
    StoreService,
    StoreServiceActivator
    } from '../../libs/activators';
import { ICustomerModel } from './Interfaces';
//import { CustomerStoreService } from './CustomerStoreService';

export class CustomerActivator extends StoreServiceActivator<ICustomerModel> {
    constructor(deviceContext: DeviceContext, customerContext: CustomerContext) {
        const api = new CustomersApi(customerContext.tokenProvider, deviceContext._configService);
        super(async() => {
            const apiResult = await api.getCustomerAsync(customerContext.customerId);
            if (apiResult.resultType === "error") {
                return new ExecuteErrorApi(apiResult);
            }

            if (apiResult.data === null) {
                return new ExecuteErrorApi({
                    resultType: "error",
                    error: "Customer could not be found",
                    errorType: "ServerError",
                    userError: "De gebruiker kan niet worden gevonden"
                });
            }

            //const service = new CustomerStoreService(customerContext, apiResult.data);
            const apiData = apiResult.data;
            const service = new StoreService<ICustomerModel>({
                ...apiData,
                customerId: apiData.customerGuid,
            });

            return new ServiceOk(service);
        });
    }
}
