import { ISlide, ISlideData } from "./Interfaces";
import { IObservableDef, ObservableDef } from "../../libs/observables";
import { IElement } from "../elements/Interfaces";

export class Slide implements ISlide {
    private readonly _slideId: string;
    private readonly _obsData: ObservableDef<ISlideData>;
    private readonly _element: IElement;

    constructor(slideId: string, element: IElement) {
        this._slideId = slideId;
        this._element = element;

        this._obsData = new ObservableDef<ISlideData>({
            slideId,
        });
    }

    public get slideId(): string { return this._slideId; }

    public get obsData(): IObservableDef<ISlideData> { return this._obsData; }

    public get element(): IElement { return this._element; }
}
