import React, { useContext } from 'react';
import { ActivatorComponent } from '../../../../../../components/datawaiting/ActivatorComponent';
import { AutomatPresentationTable } from './AutomatPresentationTable';
import { PbBlockContainer, PbHeader2 } from '../../../../../../components/pd_themed';
import { ObsDataComponent } from '../../../../../../libs/react';
import { style } from 'typestyle';
import { TextStrings } from '../../../../../../lang/TextStrings';
import { useDeviceContext } from '../../../../../main/AppLayout';
import { useCustomerContext } from '../../../UseCustomer';
import { useLicenseContext } from '../UseLicense';


const blockStyle = style({
    gridColumn: " 1/ span 2"
});

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content min-content min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

export function PlayerSettingsBlock(): JSX.Element | null {
    const deviceContext = useDeviceContext();
    const customer = useCustomerContext();
    const license = useLicenseContext();

    return deviceContext && customer && license ? (
        <>
            <PbBlockContainer className={blockStyle} >
                <div className={containerStyle} key={license.licenseId}>

                    <ObsDataComponent obsData={license.obsData} content={(licenseData) => <>
                        <PbHeader2>{TextStrings.formatMyPlayerName(licenseData.description)}</PbHeader2>
                    </>
                    } />
                    <ActivatorComponent activator={license.licenseAdvertisementActivator}
                        content={(licenseAdvertisement) => (
                            <AutomatPresentationTable licenseAdvertisement={licenseAdvertisement} customer={customer} />
                        )} />
                </div>

            </PbBlockContainer>
        </>
    ) : null;
}