import { LicenseContext } from '../licenses/LicenseContext';
import { IApiSpotifyLicenseResponse, SpotifyAccountApi } from '../../apis/SpotifyAccountApi';
import { ExecuteOk, ServiceErrorApi, ServiceOk, StoreServiceActivator } from '../../libs/activators';
import { LicenseSpotify } from './LicenseSpotify';
import { ILicenseSpotifyModel, ISpotifyContext } from './Interfaces';
import { FetchResultContent } from '../../libs/apilib';


export class LicenseSpotifyActivator extends StoreServiceActivator<ILicenseSpotifyModel, LicenseSpotify> implements ISpotifyContext {
    private readonly _api: SpotifyAccountApi;

    constructor(private readonly _licenseContext: LicenseContext) {
        super(async() => {
            const apiDataResult = await this.getDataAsync();
            if (apiDataResult.resultType === "error") {
                return new ServiceErrorApi(apiDataResult);
            }

            return new ServiceOk(new LicenseSpotify(this, apiDataResult.data));
        });

        this._api = new SpotifyAccountApi(this._licenseContext.tokenProvider, this._licenseContext.customer.deviceContext._configService);
    }

    public async saveLoginCode(code: string): Promise<void> {
        await this.executeAsync(async() => {
            const apiResult = await this._api.saveSpotifyTokenAsync({ token: code, licenseIdentity: this._licenseContext.licenseId });
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const apiDataResult = await this.getDataAsync();
            if (apiDataResult.resultType === "error") {
                return new ServiceErrorApi(apiDataResult);
            }

            if (this.service) {
                this.service.updateData(apiDataResult.data);
            }

            return new ExecuteOk();
        });
    }

    public disconnectSpotify(): void {
        this.executeAsync(async() => {
            const apiResult = await this._api.clearSpotifyTokenAsync({ licenseIdentity: this._licenseContext.licenseId });
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const apiDataResult = await this.getDataAsync();
            if (apiDataResult.resultType === "error") {
                return new ServiceErrorApi(apiDataResult);
            }

            if (this.service) {
                this.service.updateData(apiDataResult.data);
            }

            return new ExecuteOk();
        });
    }

    private getDataAsync(): Promise<FetchResultContent<IApiSpotifyLicenseResponse>> {
        const redirectUrl = window.location.origin + "/spotify/redirect";
        return this._api.getSpotifyAccountAsync({ redirectUrl: redirectUrl, licenseIdentity: this._licenseContext.licenseId });
    }
}
