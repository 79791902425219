import { ILifetimeContainer } from "../lifetime";
import { ObservableBase } from "./ObservableBase";
import { IObservableOpt } from "./IObservableOpt";

export class ObservableOpt<T> extends ObservableBase<T> implements IObservableOpt<T> {
    private _value: T | undefined;
    private _hasValue = false;

    public get optValue(): T | undefined {
        return this._value;
    }

    public whenValue(callback: (value: T) => void): void {
        if (this._hasValue) {
            callback(this._value as T);
        }
    }

    public emit(value: T): void {
        this._value = value;
        this._hasValue = true;
        this.emitInternal(value);
    }

    public emitOnChanged(value: T): void {
        if (this._hasValue && (this._value === value)) {
            return;
        }
        this._value = value;
        this._hasValue = true;
        this.emitInternal(value);
    }

    public silentSet(value: T): void {
        this._value = value;
        this._hasValue = true;
    }

    public subscribeInitial(lifetimeContainer: ILifetimeContainer, callback: (value: T) => void): void {
        const subscription = super.subscribe(lifetimeContainer, callback);
        if (this._hasValue) {
            subscription.emitValue(this._value as T);
        }
    }
}
