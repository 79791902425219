import { IElement, IElementListEditService, IElementService } from './Interfaces';
import { IObservableDef, ObservableDef } from '../../libs/observables';


export class ElementListEditService implements IElementListEditService {
    private readonly _parent: IElementService;
    private readonly _obsElements = new ObservableDef<IElement[]>([]);
    private readonly _obsEditModeActive = new ObservableDef<boolean>(false);
    private _removedElements: IElement[] = [];

    constructor(parent: IElementService, ) {
        this._parent = parent;
        this.reset();
    }


    public startEdit(): void {
        this._obsEditModeActive.emit(true);
    }

    public cancelEdit(): void {
        this.reset();
    }

    public async commitEditAsync(): Promise<void> {
        await this._parent.removeMediaAsync(this._removedElements);
    }

    public removeFromList(element: IElement): void {
        this._removedElements.push(element);
        const elements = [...this._obsElements.value];
        const index = elements.findIndex((elm)=>elm.elementId === element.elementId);
        elements.splice(index, 1);
        this._obsElements.emit(elements);
    }

    public get obsEditModeActive(): IObservableDef<boolean>{ return this._obsEditModeActive; }
    public get obsElements(): IObservableDef<IElement[]> { return this._obsElements; }

    public refresh(): void {
        this._parent.refresh();
    }

    private reset(){
        this._obsEditModeActive.emit(false);
        this._removedElements = [];
        this._obsElements.emit(this._parent.obsElements.value);
    }
}
