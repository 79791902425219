import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PbBlockContainer, PbHeader2, PbParagraph } from '../../../../../components/pd_themed';
import { useDeviceContext } from '../../../../main/AppLayout';

interface IData {
    customer?: string;
    license?: string;
}

export function SpotifyRedirectRoute(): JSX.Element | null {
    const [params] = useSearchParams();
    const code = params.get("code");
    const state = params.get("state");
    const deviceContext = useDeviceContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (state && deviceContext && code) {
            const json = atob(state);
            const data: IData = JSON.parse(json);
            if (data && data.customer && data.license) {
                const customerContext = deviceContext._customers.select(data.customer);
                if (customerContext) {
                    const licenseContext = customerContext.licenses.select(data.license);
                    if (licenseContext) {
                        const timer = setTimeout(() => {
                            licenseContext.spotifyContext.saveLoginCode(code);
                            navigate("/spotify");
                        }, 0);

                        return () => {
                            clearTimeout(timer);
                        }
                    }
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code]);

    return (
        <PbBlockContainer>
            <PbHeader2>Verwerken inlog gegevens</PbHeader2>
            <PbParagraph>
                Het verwerken van de inlog gegevens is niet gelukt!
            </PbParagraph>
        </PbBlockContainer>
    );
}