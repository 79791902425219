import React from 'react';
import { DialogExecutor } from '../../../libs/react/DialogExecutor';
import { IPresentation, ISlide } from '../../../services/presentations/Interfaces';
import { ObsDataComponent } from '../../../libs/react';
import { PbButton, PbButtonText, PbHeader2, PbParagraph, PbTheme } from '../../../components/pd_themed';
import { withPbDialog, WithPbDialogProps } from '../../../components/overlay';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

const imageWrapperStyle = style({
    display: "grid",
    gridTemplateRows: "minmax(0,1fr)",
    gridTemplateColumns: "minmax(0,1fr)",
    maxHeight: "50vh",
    overflow: "hidden",
    justifyItems: "center",
    alignItems: "center",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
});

const imageStyle = style({
    maxWidth: "100%",
    maxHeight: "100%",
    gridRow: "1",
    gridColumn: "1",
});

interface IInput {
    presentation: IPresentation;
    slide: ISlide;
}

export class SlideSettingsDialogExecutor extends DialogExecutor<IInput, void> { }

function SlideSettingsDialogForm(props: WithPbDialogProps<IInput, void>): JSX.Element | null {
    const slide = props.dialogModel.input.slide;

    return slide ? (
        <div className={dialogContainer}>
            <PbHeader2>{TextStrings.textPresentationEditSlideSettings}</PbHeader2>
            <ObsDataComponent obsData={slide.element.obsData} content={(elmData) => (
                <>
                    <div className={imageWrapperStyle}>
                        <img alt={`slide ${elmData.name}`} className={imageStyle} src={elmData.url} />
                    </div>
                    <div>
                        <PbParagraph>{TextStrings.formatSlideElementName(elmData.name)}</PbParagraph>
                        {elmData.topicName && <PbParagraph>{TextStrings.formatSlideElementOrigin(elmData.topicName)}</PbParagraph>}
                    </div>
                </>
            )} />
            <PbButton onClick={() => setAsThumbnail()}>{TextStrings.textPresentationEditSetAsThumbnail}</PbButton>
            <PbParagraph style={{ color: PbTheme.colors.textGray }}>{TextStrings.textPresentationEditSetAsThumbnailDesc}</PbParagraph>
            <PbButtonText onClick={() => closeDialog()}>{TextStrings.textCloseAction}</PbButtonText>
        </div>
    ) : null;

    function setAsThumbnail(): void {
        if (slide) {
            props.dialogModel.input.presentation.setslideAsThumbnail(slide.element);
            closeDialog();
        }
    }

    function closeDialog(): void {
        props.dialogModel.close();
    }
}

export const SlideSettingsDialog = withPbDialog<IInput, void>(SlideSettingsDialogForm);
