import { FetchResultContent, FetchService } from '../libs/apilib';
import { IAccessTokenProvider } from '../services/token/Interfaces';
import { IConfigService } from '../services/config/Interfaces';

export class CustomersApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(accessTokenProvider: IAccessTokenProvider, configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");

        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await accessTokenProvider.getTokenAsync();
            headers.set("recordcasetoken", token.token);
        });
    }

    public getCustomerAsync(id: string): Promise<FetchResultContent<IApiCustomersCustomer | null>> {
        const customerIdParam = encodeURIComponent(id);
        return this._fetchService.fetchGetAsync<IApiCustomersCustomer | null>(this.getUri() + `/${customerIdParam}`);
    }

    public getCustomersAsync(query: string | void): Promise<FetchResultContent<IApiCustomers>> {
        const uri = query ? `/search/${window.encodeURIComponent(query)}/` : `/search/`;
        return this._fetchService.fetchGetAsync<IApiCustomers>(this.getUri() + uri);
    }

    private getUri(): string {
        return this._configService.config.customerApiUrl;
    }
}

export interface IApiCustomers {
    customers: IApiCustomersCustomer[];
    isMoreAvailable: boolean;
    isSearchNeeded: boolean;
}

export interface IApiCustomersCustomer {
    readonly customerGuid: string;
    readonly company: string;
    readonly city: string;
    readonly role: string;
    readonly enableMenuPresenter: boolean;
    readonly enableMenuScheduler: boolean;
    readonly enableMenuSpotify: boolean;
}
