import { useEffect, useState } from "react";
import { LifetimeContainer } from "../lifetime";
import { IObservable } from "../observables";

export function useObservable<TData>(obsData: IObservable<TData | undefined> | undefined): TData | undefined {
    const [state, setState] = useState<TData | null>();

    const observableData = obsData ?? null;

    useEffect(() => {
        if (observableData) {
            const lifetimeContainer = new LifetimeContainer();

            observableData.subscribeInitial(lifetimeContainer, (data) => {
                setState(data);
            });

            return () => {
                setState(null);
                lifetimeContainer.dispose();
            }
        }
        else {
            setState(null);
            return undefined;
        }
    }, [observableData]);

    return state ?? undefined;
}
