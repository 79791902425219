import { FetchResultError } from "../apilib";

// tslint:disable: max-classes-per-file

export interface IActivatorExecuteResultOk {
    isError: false;
    refreshService: boolean;
}

export interface IActivatorExecuteResultError {
    isError: true;
    error: string;
    canRetry: boolean;
}

export type ActivatorExecuteResult =
    | IActivatorExecuteResultOk
    | IActivatorExecuteResultError;


export class ExecuteOk implements IActivatorExecuteResultOk {
    private readonly _refreshService: boolean;

    constructor(refreshService?: boolean) {
        this._refreshService = refreshService || false;
    }

    public get isError(): false { return false; }
    public get refreshService(): boolean { return this._refreshService; }
}

export class ExecuteError implements IActivatorExecuteResultError {
    private readonly _error: string;
    private readonly _canRetry: boolean;

    constructor(error: string, canRetry = true) {
        this._error = error;
        this._canRetry = canRetry;
    }

    public get isError(): true { return true; }
    public get error(): string { return this._error; }
    public get canRetry(): boolean { return this._canRetry; }
}

export class ExecuteErrorApi implements IActivatorExecuteResultError {
    private readonly _error: string;
    private readonly _canRetry: boolean;

    constructor(apiResult: FetchResultError) {
        this._error = apiResult.userError;
        this._canRetry = true;
    }

    public get isError(): true { return true; }
    public get error(): string { return this._error; }
    public get canRetry(): boolean { return this._canRetry; }
}
