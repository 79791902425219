class IdGenerator {
    private _globalId = 1;

    public getTempId(): string {
        const id = this._globalId = this._globalId + 1;
        return `[${id}]`;
    }

    public isTempId(id: string): boolean {
        return id.startsWith("[");
    }

    public makeTempIdUndefined(id: string): string | undefined {
        if (id.startsWith("[")) {
            return undefined;
        }

        return id;
    }
}

const idGenerator = new IdGenerator();
export default idGenerator;
