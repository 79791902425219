import React, { Component } from 'react';
import { PbTheme } from './PbTheme';
import { style } from 'typestyle';

const sliderWrapper = style({
    display: "grid",
    height: "50px",
    justifyItems: "center",
    alignItems: "center",
    gridTemplateRows: "50px",
    gridTemplateColumns: "1fr",
});

const sliderBody = style({
    display: "grid",
    height: "10px",
    justifyItems: "center",
    alignItems: "center",
    gridTemplateRows: "10px",
    gridTemplateColumns: "0fr 15px 1fr",
    backgroundColor: "gray",
    width: "100%",
    borderRadius: "5px",
});

const sliderLeftThumb = style({
    backgroundColor: PbTheme.colors.secondary,
    height: "10px",
    borderRadius: "5px 0 0 5px",
    width: "100%",
});

const sliderThumb = style({
    backgroundColor: PbTheme.colors.primary,
    width: "20px",
    height: "20px",
    borderRadius: "10px",
});

interface IProps  {
    onChange?: (value: number) => void
    min: number;
    max: number;
    expCurve: number;
    initValue: number;
}

export class PBSlider extends Component<IProps> {
    private _scrollBar: React.RefObject<HTMLDivElement>;
    private _updateTimerHandle = -1;
    private _internalValue = 0;
    private _publishedValue = 0;

    constructor(props: IProps){
        super(props);
        this._scrollBar = React.createRef<HTMLDivElement>()
    }

    public componentDidMount(): void{
        setTimeout(()=>this.setSliderInital(), 1);
    }

    public render(): JSX.Element {
        return (
            <div className={sliderWrapper}>
                <div className={sliderBody}
                ref={this._scrollBar}
                onMouseDown={()=>this.setMouseHandlers()}
                onTouchStart={()=>this.setMouseHandlers()}
                >
                    <div className={sliderLeftThumb}></div>
                    <div className={sliderThumb}></div>
                </div>
            </div>
        );
    }


    private setMouseHandlers() {
        window.addEventListener("mousemove", this.handleMouseMoveEvent);
        window.addEventListener("mouseup", this.removeMouseHandlers);
        window.addEventListener("touchmove", this.handleTouchMoveEvent);
        window.addEventListener("touchend", this.removeMouseHandlers);
    }

    private removeMouseHandlers = ()=> {
        window.removeEventListener("mousemove", this.handleMouseMoveEvent);
        window.removeEventListener("mouseup", this.removeMouseHandlers);
        window.removeEventListener("touchmove", this.handleTouchMoveEvent);
        window.removeEventListener("touchend", this.removeMouseHandlers);
    };

    private handleTouchMoveEvent = (evt: TouchEvent): void => {
        if(evt.touches[0]){
            this.setSliderFromPointerPosition(evt.touches[0].pageX );
            evt.stopPropagation();
            evt.preventDefault();
        }
    }

    private handleMouseMoveEvent = (evt: MouseEvent): void => {
        if((evt.type === "click" && evt.button === 0) || evt.buttons === 1){
            this.setSliderFromPointerPosition(evt.pageX);
            evt.stopPropagation();
            evt.preventDefault();
        }
    }

    private setSliderFromPointerPosition(pointerX: number){
        const scrollbar = this._scrollBar.current;
        if(scrollbar){
            const barLeft = scrollbar.getBoundingClientRect().left;
            let val = (pointerX - barLeft) / scrollbar.clientWidth;
            val = Math.min(1,Math.max(0, val));
            this.setSlider(val);
            this.startUpdateValue(val);
        }
    }

    private setSliderInital(){
        const range = this.props.max - this.props.min;
        const normalValue = (this.props.initValue - this.props.min) / range;
        let expValue = normalValue ** (1 / this.props.expCurve);
        expValue = Math.min(1,Math.max(0, expValue));
        this._internalValue = normalValue;
        this.setSlider(expValue);
    }

    private setSlider(value: number){
        if(this._scrollBar.current !== null){
            this._scrollBar.current.style.gridTemplateColumns = `${value}fr 15px ${1 - value}fr`;
        }
    }


    private startUpdateValue(value: number){
        this._internalValue = value;
        if(this._updateTimerHandle === -1){
            window.setTimeout(this.updateValue, 100);
            this.updateValue();
        }
    }

    private updateValue = ()=> {
        this._updateTimerHandle = -1;
        if(this._internalValue !== this._publishedValue){
            this._publishedValue = this._internalValue;
            this.onChange();
        }
    }

    private onChange() {
        if (this.props.onChange) {
            const expValue = this._publishedValue ** this.props.expCurve;
            const range = this.props.max - this.props.min;

            const value = this.props.min + (range * expValue);
            this.props.onChange(value);
        }
    }
}
