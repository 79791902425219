import React from "react";
import { IActivator } from "../../libs/activators";
import { useObservable } from "../../libs/react";
import { ModelStateView } from "./ModelStateView";

interface IProps<TData> {
    activator: IActivator<TData>;
    content: (data: TData) => JSX.Element;
    loadingContent?: JSX.Element;
}

export function ActivatorComponent<TData>(props: IProps<TData>): JSX.Element | null {
    const service = useObservable(props.activator?.obsService);

    if (!props.activator)
    {
        return (
            <p>[ Activator is undefined ]</p>
        );
    }

    return service ? (
        <ModelStateView
            modelState={service}
            content={props.content}
            loadingContent={props.loadingContent}
            onTryResetError={props.activator.tryResetError} />
    ) : null;
}
