import React, { useState } from 'react';
import { useObservable } from '../../libs/react';
import { IConnectCodeService } from '../../services/connectcode/Interfaces';
import { PbButton, PbButtonText, PbHeader2, PbHeader3, PbParagraph, PbTextInput } from '../../components/pd_themed';
import { style } from 'typestyle';
import { TextStrings } from '../../lang/TextStrings';
import { useDeviceContext } from '../../pages/main/AppLayout';
import { withPbDialog, WithPbDialogProps } from '../../components/overlay';
import { ActivatorComponent } from '../../components/datawaiting';

const formInputs = style({
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridTemplateColumns: "1fr",
    gridRowGap: "10px",
    maxWidth: "450px"
});

interface IConnectSystemFormProps {
    onClose: () => void;
    connectCodeService: IConnectCodeService;
}

function ConnectSystemForm(props: IConnectSystemFormProps): JSX.Element {
    const status = useObservable(props.connectCodeService.obsStatus)
        ?? { state: "AskForCode" };

    const [connectCode, setConnectCode] = useState<string>("");

    return (
        <h1>
            {renderStatus()}
        </h1>);

    function renderStatus() {
        switch (status.state) {
            case "AskForCode": return renderCodeEntry();
            case "Invalid": return renderInvalidCode();
            case "Done": return renderDone();
        }
    }

    function renderCodeEntry() {
        return (
            <div className={formInputs}>
                <PbParagraph>
                    {TextStrings.textConnectDialogCodeDesc}
                </PbParagraph>
                <PbTextInput
                    placeholder="Koppel code"
                    value={connectCode}
                    onTextChange={(text) => handleConnectCodeChange(text)}
                    autoFocus
                />
                <PbButton autoFocus onClick={() => onSubmitCode()}>
                    {TextStrings.textConnectDialogAction}
                </PbButton>
                <PbButtonText onClick={() => handleClose()} >
                    {TextStrings.textCancelAction}
                </PbButtonText>
            </div>
        );
    }

    function renderInvalidCode() {
        return (
            <div className={formInputs}>
                <PbHeader3>
                    {TextStrings.textConnectDialogTitle}
                </PbHeader3>
                <PbParagraph>
                    {TextStrings.textConnectDialogWrongCode}
                </PbParagraph>
                <PbButton onClick={() => handleClose()} >
                    {TextStrings.textCloseAction}
                </PbButton>
            </div>
        );
    }

    function renderDone() {
        return (
            <div className={formInputs}>
                <PbParagraph>
                    {TextStrings.textConnectDialogCodeDone}
                </PbParagraph>
                <PbButton onClick={() => handleDone()} >
                    {TextStrings.textCloseAction}
                </PbButton>
            </div>
        );
    }


    function handleClose(): void {
        props.onClose();
    }

    function handleDone(): void {
        props.onClose();
    }

    function onSubmitCode(): void {
        props.connectCodeService.validateConnectCode(connectCode);
    }

    function handleConnectCodeChange(text: string): void {
        setConnectCode(text.toLowerCase());
    }
}

function ConnectSystemDialogInternal(props: WithPbDialogProps<void, void>): JSX.Element | null {
    const deviceContext = useDeviceContext();
    return deviceContext ? (
        <>
            <PbHeader2>{TextStrings.textConnectDialogTitle}</PbHeader2>
            <ActivatorComponent activator={deviceContext.getConnectCodeServiceActivator()} content={(connectCodeService) => (
                <ConnectSystemForm connectCodeService={connectCodeService} onClose={props.dialogModel.close} />
            )} />
        </>
    ) : null;
}

export const ConnectSystemDialog = withPbDialog(ConnectSystemDialogInternal);

