// interface IThemeColors{
//     primary:string;
//     secondary: string;
//     backgroundTopBar: string;
//     backgroundContent: string;
//     backgroundBlockLv1: string;
//     backgroundBlockLv2: string;
//     backgroundBlockLv3: string;
//     backgroundBlockLv4: string;
//     textNormal: string;
// }

// interface ITransitions{
//     all05x: string;
//     all1x: string;
//     all2x: string;
// }

// interface IFonts{
//     sizeNormal: string;
//     sizeHeader1: string,
//     sizeHeader2: string,
//     sizeHeader3: string,
//     sizeHeader4: string,
//     family: string;
// }

export class PbTheme {
    static colors = {
        primary: "#ff9400",
        secondary: "#c27000",
        backgroundTopBar: "#333333",
        backgroundContent: "#1a1a1a",
        backgroundBlockLv1: "#333333",
        backgroundBlockLv2: "#434343",
        backgroundBlockLv3: "#535353",
        backgroundBlockLv4: "#636363",
        textNormal: "#fdfdfd",
        textGray: "#999",
        textNormalOnGray: "#727272",
        inputBackground: "#252525",
        inputBackgroundFocus: "#353535",
        inputBorder: "#555555",
        inputBorderFocus: "#818181",

        buttonPrimary: "rgb(255,148,0)",
        buttonPrimaryHover: "rgb(255, 165, 40)",
        buttonPrimaryActive: "rgb(255, 180, 60)",
        buttonPrimaryDisabled: "rgb(128, 60, 15)",
        buttonSecondary: "transparent",
        buttonSecondaryHover: "rgba(255,255,255,0.1)",
        buttonSecondaryActive: "rgba(255, 255, 255, 0.2)",
        buttonSecondaryDisabled: "rgba(80, 80, 80, 0.5)",
        buttonSecondaryBorder: "rgb(200, 200, 200)",
    }

    static transitions = {
        all05x: "all 0.15s ease",
        all1x: "all 0.3s ease",
        all2x: "all 0.6s ease",
    };

    static textSizes = {
        sizeNormal: "12pt",
        sizeHeader1: "2rem",
        sizeHeader2: "1.83rem",
        sizeHeader3: "1.5rem",
        sizeHeader4: "1.33rem",
        family: "Arial, 'Segoe UI', sans-serif"
    };

    static header = {
        height: "70px",
        zIndex: 100
    };

    static content = {
        zIndex: 1
    }

    static layoutPortraitMediaQuery = '@media only screen and (max-width: 1100px)';
    static layoutLandscapeMediaQuery = '@media only screen and (min-width: 1100px)';
}