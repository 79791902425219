import { IStoreWriter } from "./IStoreWriter";
/* eslint-disable @typescript-eslint/ban-types */

export abstract class StoreBase<TModel extends object> implements IStoreWriter<TModel> {
  private _onChange?: (model: TModel) => void;
  private _model?: TModel;

  constructor(model?: TModel) {
    this._model = model;
  }

  public get optModel(): Readonly<TModel> | undefined { return this._model; }

  public get model(): Readonly<TModel> {
    if (!this._model) {
      throw new Error("Be sure to call store.init(..) before use");
    }

    return this._model;
  }

  protected setOnChangeHandler(onChange: (model: Readonly<TModel>) => void): void {
    this._onChange = onChange;
  }

  public initModel(model: TModel) : void {
    this._model = model;
    this.change();
  }

  public updateModel(change: Partial<Readonly<TModel>> | null | undefined): void {
    if (change) {
      this._model = {
        ...this.model,
        ...change,
      };
      this.change();
    }
  }

  private change(): void {
    if (this._onChange) {
      this._onChange(this.model);
    }
  }
}
