import React, { ReactNode, useState } from "react";
import { Guid } from "guid-typescript";
import { style } from "typestyle";
import scrollService from "../../services/scroll/ScrollService";
import { useEffect } from "react";

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
});


const hiddenContainerStyle = style({
    display: "none",
});

export interface IOverlayData {
    id: Guid;
    content: () => React.ReactNode;
    isDialog: boolean;
}

interface IOverlayDataWithScroll extends IOverlayData {
    previousScrollPosition: number;
}

export interface IOverlayContainer {
    showOverlay(overlay: IOverlayData): void;
    closeOverlay(id: Guid): void;
}

export const OverlayContext = React.createContext<IOverlayContainer | undefined>(undefined);

interface IProps {
    children: ReactNode
}

export function OverlayContainerProvider(props: IProps): JSX.Element {
    const [overlays, setOverlays] = useState<IOverlayDataWithScroll[]>([]);
    const [closeId, setCloseId] = useState<Guid>();
    const [context] = useState<IOverlayContainer>({
        showOverlay: (o) => showOverlay(o),
        closeOverlay: (o) => setCloseId(o)
    });

    useEffect(() => {
        if (closeId) {
            closeOverlay(closeId);
            setCloseId(undefined);
        }

        function closeOverlay(id: Guid) {
            const overlayIndex = overlays.findIndex((o) => id === o.id);
            if (overlayIndex !== -1) {
                const tempOverlays = overlays;
                if (document.scrollingElement && !tempOverlays[overlayIndex].isDialog) {
                    scrollService.scheduleScrollToPosition(tempOverlays[overlayIndex].previousScrollPosition, false);
                }
                tempOverlays.splice(overlayIndex, 1);
                setOverlays([...tempOverlays]);
            }
        }
    }, [closeId, overlays]);


    const childStyle = overlays.findIndex((o) => !o.isDialog) !== -1 ? hiddenContainerStyle : containerStyle;
    return (
        <OverlayContext.Provider value={context}>
            <div className={containerStyle}>
                <div className={childStyle}>{props.children}</div>
                {getOverlayContent()}
                {getDialogContent()}
            </div>

        </OverlayContext.Provider>
    );


    function getOverlayContent() {
        for (let i = overlays.length - 1; i >= 0; i--) {
            if (!overlays[i].isDialog) {
                return overlays[i].content();
            }
        }
        return null;
    }

    function getDialogContent() {
        for (let i = overlays.length - 1; i >= 0; i--) {
            if (overlays[i].isDialog) {
                return overlays[i].content();
            }
        }
        return null;
    }

    function showOverlay(overlay: IOverlayData) {
        const index = overlays.findIndex((o) => overlay.id === o.id);
        if (index === -1) {
            const scrollPosition = document.scrollingElement ? document.scrollingElement.scrollTop : 0
            const overlayData = {
                ...overlay,
                previousScrollPosition: scrollPosition
            };
            setOverlays([...overlays, overlayData]);
        } else {
            const tempOverlays = [...overlays];
            tempOverlays[index].content = overlay.content;
            setOverlays(tempOverlays);
        }
    }
}