import React from "react";
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { style } from "typestyle";
import { PbButtonIcon, PbHeader3, PbParagraph, PbTheme } from "../../../components/pd_themed";
import { TextStrings } from "../../../lang/TextStrings";
import { ObsDataComponent, PureComponentWithLifetime } from "../../../libs/react";
import { ICustomerContext } from "../../../services/customers/Interfaces";
import { IPresentation } from "../../../services/presentations/Interfaces";
import { SlidesEditor } from "./SlidesEditor";
import WarningIcon from '@material-ui/icons/Warning';


const editorContainer = style({
    display: "grid",
    gridTemplateRows: "min-content min-content 1fr",
    gridTemplateColumns: "1fr",
    margin: "5px",
    padding: "5px",
    borderRadius: "10px",
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    rowGap: "10px",
});

const titleContainer = style({
    display: "grid",
    gridTemplateRows: "min-content",
    gridTemplateColumns: "min-content 1fr min-content",
    alignItems: "center",
    padding: "10px 0px",
});

const detailsTable = style({
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridTemplateColumns: "1fr min-content",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    padding: "15px",
    borderRadius: "10px",
});

const expiredWarningSpan = style({
    color: "rgb(255,148,0)",
    alignItems: "center",
    display: "inline-grid",
    gridTemplateColumns: "auto auto",

});


interface IProps {
    presentation: IPresentation;
    customer: ICustomerContext;
    onClose?: () => void;
}

// tslint:disable-next-line: max-classes-per-file
export class PresentationEditor extends PureComponentWithLifetime<IProps> {
    public render(): JSX.Element {
        return (
            <>
                <div className={editorContainer}>
                    <ObsDataComponent obsData={this.props.presentation.obsData} content={
                        (presentationData) => (
                            <>
                                <div className={titleContainer}>
                                    <PbButtonIcon onClick={() => this.closeEditor()}><CloseIcon /></PbButtonIcon>

                                    <PbHeader3>Presentatie: {presentationData.name}</PbHeader3>

                                    <PbButtonIcon onClick={() => this.deletePresentation()}><DeleteIcon /></PbButtonIcon>
                                </div>

                                <div className={detailsTable}>
                                    <PbParagraph>Naam: {presentationData.name}</PbParagraph>
                                    <PbButtonIcon onClick={() => this.editName()}><EditIcon /></PbButtonIcon>

                                    <PbParagraph>
                                        {
                                            presentationData.expireDateTime && presentationData.expireDateTime < new Date() ?
                                                <span className={expiredWarningSpan} >
                                                    {TextStrings.formatPresentationExpire(presentationData.expireDateTime)}
                                                    <WarningIcon htmlColor="rgb(255,148,0)" />
                                                </span>
                                                :
                                                TextStrings.formatPresentationExpiresOn(presentationData.expireDateTime)
                                        }
                                    </PbParagraph>
                                    <PbButtonIcon onClick={() => this.editExpireDate()}><EditIcon /></PbButtonIcon>

                                    <PbParagraph>Tijd per dia: {this.formatSlideDuration(presentationData.defaultSlideDurationInSeconds)}</PbParagraph>
                                    <PbButtonIcon onClick={() => this.editDefaultSlideDurationInSeconds()}><EditIcon /></PbButtonIcon>
                                </div>
                            </>
                        )
                    } />
                    <SlidesEditor customer={this.props.customer} presentation={this.props.presentation} />
                </div>
            </>
        );
    }

    private formatSlideDuration(duration: number) {
        const val = Math.round(duration * 10) / 10;
        return `${val}sec`;
    }

    private closeEditor() {
        if (this.props.onClose) {
            this.props.onClose();
        }
    }

    private async deletePresentation() {
        const confirmedDialog = await this.props.customer.deviceContext.dialogContext.confirmDeletionDialogExecutor.executeAsync(this.props.presentation.obsData.value.name);
        if (confirmedDialog.result) {
            await this.props.presentation.deletePresentationAsync();
            this.closeEditor();
        }
    }

    private async editName() {
        const editNameResult = await this.props.customer.deviceContext.dialogContext.editPresentationNameDialogExecutor.executeAsync({
            type: "edit",
            name: this.props.presentation.obsData.value.name
        });
        if (editNameResult.result) {
            this.props.presentation.updateNameAsync(editNameResult.output.name);
            this.props.customer.deviceContext.alertAsync(TextStrings.textPresentationEditNameSuccess);
        }
    }

    private async editExpireDate() {
        const newExpireDateResult = await this.props.customer.deviceContext.dialogContext.editPresentationExpireDateTimeDialogExecutor.executeAsync({
            date: this.props.presentation.obsData.value.expireDateTime
        });
        if (newExpireDateResult.result) {
            this.props.presentation.updateExpireDateTimeAsync(newExpireDateResult.output.date);
            this.props.customer.deviceContext.alertAsync(TextStrings.textPresentationEditExpireDateSuccess);
        }
    }

    private async editDefaultSlideDurationInSeconds() {
        const slideDurationResult = await this.props.customer.deviceContext.dialogContext.editPresentationSlideDurationDialogExecutor.executeAsync({
            duration: this.props.presentation.obsData.value.defaultSlideDurationInSeconds
        });
        if (slideDurationResult.result) {
            this.props.presentation.updateSlideDurationAsync(slideDurationResult.output.duration);
            this.props.customer.deviceContext.alertAsync(TextStrings.textPresentationEditSlideDurationSuccess);
        }
    }
}
