import { IAccessToken, IAccessTokenProvider } from './Interfaces';
import { DeviceTokenProvider } from './DeviceTokenProvider';
import { PromiseAggregator } from '../../libs/utils';

export class AccessTokenProvider implements IAccessTokenProvider {
    private readonly _deviceTokenProvider: DeviceTokenProvider;
    private readonly _customerId: string;
    private readonly _getAccessTokenPromiseAggregator: PromiseAggregator<IAccessToken>;
    private _token: IAccessToken | undefined;
    private _tokenTicks = 0;

    constructor(deviceTokenProvider: DeviceTokenProvider, customerId: string) {
        this._deviceTokenProvider = deviceTokenProvider;
        this._customerId = customerId;
        this._token = undefined;

        this._getAccessTokenPromiseAggregator = new PromiseAggregator(false, () => this.getTokenInternalAsync());
    }

    public async getTokenAsync(): Promise<IAccessToken> {
        return await this._getAccessTokenPromiseAggregator.executeAsync();
    }

    private async getTokenInternalAsync(): Promise<IAccessToken> {
        const ticks = (new Date()).valueOf();

        if (this._token && ticks > this._tokenTicks) {
            this._token = undefined;
        }

        if (!this._token) {
            this._token = await this._deviceTokenProvider.getAccessToken(this._customerId);
            this._tokenTicks = ticks + ((this._token.minutesValid - 5) * 60000);
        }

        return this._token;
    }
}
