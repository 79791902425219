import React, { useState } from "react";
import { DialogExecutor, ObservableDialogModel } from "../../../libs/react/DialogExecutor";
import { PbHeader2, PbButton, PbTextInput } from "../../../components/pd_themed"
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay"
import { style } from "typestyle";
import { TextStrings } from "../../../lang/TextStrings";

const dialogContentStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridRowGap: "10px",
    margin: "0 10px"
});

const inputContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
});

const buttonsContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    gridColumnGap: "6px",
});

interface IInputEdit {
    type: "edit";
    name: string;
}

interface IInputNew {
    type: "new";
}

export type EditPresentationNameDialogInput = IInputEdit | IInputNew;

export type EditPresentationNameDialogOutput = {
    name: string;
}

export class EditPresentationNameDialogExecutor extends DialogExecutor<EditPresentationNameDialogInput, EditPresentationNameDialogOutput> { }

export type EditPresentationNameDialogModel = ObservableDialogModel<EditPresentationNameDialogInput, EditPresentationNameDialogOutput>;

function EditPresentationNameDialogForm(props: WithPbDialogProps<EditPresentationNameDialogInput, EditPresentationNameDialogOutput>): JSX.Element | null {
    const oldName = props.dialogModel.input.type === "edit"
        ? props.dialogModel.input.name
        : "";

    const [name, setName] = useState<string>(oldName);
    const [isValid, setIsValid] = useState<boolean>(false);

    return (
        <div className={dialogContentStyle}>
            <PbHeader2>
                {oldName === "" ?
                    TextStrings.textPresentationEditNameNew :
                    TextStrings.textPresentationEditName
                }
            </PbHeader2>
            <div className={inputContainerStyle}>
                <PbTextInput
                    value={name}
                    onTextChange={(e) => changeName(e)}
                    autoFocus
                />
            </div>
            <div className={buttonsContainerStyle}>
                <PbButton onClick={() => closeDialog()} >Annuleren</PbButton>
                <PbButton onClick={() => confirmDialog()} disabled={!isValid}>Opslaan</PbButton>
            </div>
        </div>
    );

    function changeName(value: string): void {
        setName(value);
        setIsValid(value.length > 0 && value !== oldName);
    }

    function closeDialog(): void {
        props.dialogModel.close();
    }

    function confirmDialog(): void {
        props.dialogModel.resolve({
            name: name
        });
    }
}

export const EditPresentationNameDialog = withPbDialog<EditPresentationNameDialogInput, EditPresentationNameDialogOutput>(EditPresentationNameDialogForm);
