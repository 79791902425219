import React from "react";
import { BreadCrumbsContext } from "./BreadCrumbsProvider";
import { RouterContainerButton } from "../RouterContainerButton";
import { style } from "typestyle";
import { PbParagraph } from "./PbParagraph";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { PbTheme } from "./PbTheme";
import { useContext } from "react";

const breadCrumbListStyle = style({
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "min-content",
    gridTemplateRows: "min-content",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
});

const breadCrumbContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "min-content min-content",
    alignItems: "center",
    cursor: "pointer"
});

const breadCrumbTextStyle = style({
    $nest: {
        [PbTheme.layoutPortraitMediaQuery]: {
            fontSize: "11pt",

        }
    }
});

export const BreadCrumbs = (): JSX.Element => {
    const context = useContext(BreadCrumbsContext);

    return (
        <div className={breadCrumbListStyle}>
            {context && context.path.map(
                (pathPart, index) => (
                    <RouterContainerButton className={breadCrumbContainerStyle} key={pathPart.title} to={pathPart.location}>
                        <PbParagraph className={breadCrumbTextStyle}>{pathPart.title}</PbParagraph>
                        {index !== context.path.length - 1 && <ChevronRightIcon />}
                    </RouterContainerButton>
                )
            )}
        </div>);
}
