import React, { FormEvent, useState } from "react";
import { withPbDialog, WithPbDialogProps } from "../../components/overlay";
import { PbHeader2, PbParagraph, PbTextInput, PbButtonText, PbButtonSubmit } from "../../components/pd_themed";
import { style } from "typestyle";
import { TextStrings } from "../../lang/TextStrings";

export interface IChangePasswordInput {
    email: string;
}

export interface IChangePasswordOutput {
    newPassword: string;
}

const formInputs = style({
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridTemplateColumns: "1fr",
    gridRowGap: "10px"
});

export const ChangePasswordDialog = withPbDialog(
    (props: WithPbDialogProps<IChangePasswordInput, IChangePasswordOutput>): (JSX.Element | null) => {
        const [email] = useState<string>(props.dialogModel.input.email);
        const [password, setPassword] = useState<string>("");
        const [showPassword, setShowPassword] = useState<boolean>(false);

        return (
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <PbHeader2 id="form-dialog-title">{TextStrings.textChangePasswordText}</PbHeader2>
                <div className={formInputs}>
                    <PbParagraph>
                        {TextStrings.formatChangePasswordText(email)}.
                    </PbParagraph>


                    <PbTextInput
                        placeholder="Password"
                        // className={classes.textField}
                        type={showPassword ? "text" : "password"}
                        autoComplete="current-password"
                        onTextChange={(t) => handlePasswordChange(t)}
                        autoFocus
                    />


                    <PbButtonSubmit type="submit" autoFocus text={TextStrings.textLoginLoginAction} />
                    <PbButtonText onClick={handleClose} >
                        {TextStrings.textCloseAction}
                    </PbButtonText>
                </div>
            </form>
        );

        function handleClose(): void {
            props.dialogModel.close();
        }

        function onSubmit(event: FormEvent<HTMLFormElement>): void {
            event.preventDefault();
            props.dialogModel.resolve({
                newPassword: password,
            });
        }

        function handlePasswordChange(text: string): void {
            setPassword(text);
        }

        function handleClickShowPassword(): void {
            setShowPassword(!showPassword);
        }
    }
);
