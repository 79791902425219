import { IConfigService, IConfig } from "./Interfaces";
import { IApiConfig } from "../../apis/ConfigApi";

export class ConfigService implements IConfigService {
    private _config: IConfig;

    constructor(data: IApiConfig) {
        this._config = {
            connectCodesApiUrl: data.connectCodesApiUrl,
            customerApiUrl: data.customerApiUrl,
            licenseApiUrl: data.licenseApiUrl,
            elementApiUrl: data.pictureApiUrl,
            presentationApiUrl: data.presentationApiUrl,
            securityApiUrl: data.securityApiUrl,
            advertisementLicenseApiUrl: data.advertisementLicenseApiUrl,
            myPbSoundApiUrl: data.myPbSoundApiUrl,
            spotifyaccountApiUrl: data.spotifyaccountApiUrl,
        };
    }

    public get config(): IConfig {
        return this._config;
    }
}

