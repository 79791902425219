export default class DayOfWeek {
  public readonly id: DayOfWeekId;
  public readonly index: number;
  public readonly javascriptIndex: number;
  public readonly fullName: string;
  public readonly shortName: string;

  private constructor(args:{
    id: DayOfWeekId,
    index: number,
    javascriptIndex: number,
    fullName: string,
    shortName: string }) {
    this.id = args.id;
    this.index = args.index;
    this.javascriptIndex = args.javascriptIndex;
    this.fullName = args.fullName;
    this.shortName = args.shortName;
  }

  public static createFromDayOfWeekId(id: DayOfWeekId): DayOfWeek {
    const dayOfWeek = this.all.find((d) => d.id === id);

    if (!dayOfWeek) {
      throw Error(`Unknown DayOfWeekId: ${id}`);
    }

    return dayOfWeek;
  }

  public static createFromIndex(index: number): DayOfWeek {
    const dayOfWeek = this.all.find((d) => d.index === index);

    if (!dayOfWeek) {
      throw Error(`Unknown DayOfWeek index: ${index}`);
    }

    return dayOfWeek;
  }

  public static createFromJavascriptIndex(javascriptIndex: number): DayOfWeek {
    const dayOfWeek = this.all.find((d) => d.javascriptIndex === javascriptIndex);

    if (!dayOfWeek) {
      throw Error(`Unknown DayOfWeek javascriptIndex: ${javascriptIndex}`);
    }

    return dayOfWeek;
  }

  public static createFromDate(date: Date): DayOfWeek {
    const dayOfWeek = this.all.find((d) => d.javascriptIndex === date.getDay());

    if (!dayOfWeek) {
      throw Error(`Unknown DayOfWeek from date: ${date.getDay()}`);
    }

    return dayOfWeek;
  }

  public static readonly monday = new DayOfWeek({
    id: "Ma",
    index: 0,
    javascriptIndex: 1,
    fullName: "Maandag",
    shortName: "Ma",
  });

  public static readonly tuesday = new DayOfWeek({
    id: "Di",
    index: 1,
    javascriptIndex: 2,
    fullName: "Dinsdag",
    shortName: "Di",
  });

  public static readonly wednesday = new DayOfWeek({
    id: "Wo",
    index: 2,
    javascriptIndex: 3,
    fullName: "Woensdag",
    shortName: "Wo",
  });

  public static readonly thursday = new DayOfWeek({
    id: "Do",
    index: 3,
    javascriptIndex: 4,
    fullName: "Donderdag",
    shortName: "Do",
  });

  public static readonly friday = new DayOfWeek({
    id: "Vr",
    index: 4,
    javascriptIndex: 5,
    fullName: "Vrijdag",
    shortName: "Vr",
  });

  public static readonly saturday = new DayOfWeek({
    id: "Za",
    index: 5,
    javascriptIndex: 6,
    fullName: "Zaterdag",
    shortName: "Za",
  });

  public static readonly sunday = new DayOfWeek({
    id: "Zo",
    index: 6,
    javascriptIndex: 0,
    fullName: "Zondag",
    shortName: "Zo",
  });

  public static all: ReadonlyArray<DayOfWeek> = [
    DayOfWeek.monday,
    DayOfWeek.tuesday,
    DayOfWeek.wednesday,
    DayOfWeek.thursday,
    DayOfWeek.friday,
    DayOfWeek.saturday,
    DayOfWeek.sunday,
  ];

  public static containsAll(days: DayOfWeek[]): boolean {
    return !DayOfWeek.all.some((a) => !days.some((d) => d === a));
  }
}

type DayOfWeekId =
  "Ma" |
  "Di" |
  "Wo" |
  "Do" |
  "Vr" |
  "Za" |
  "Zo";
