import React, { useState } from "react";
import { style } from "typestyle";
import { ActivatorComponent } from "../../../components/datawaiting/ActivatorComponent";
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay";
import { PbButton, PbHeader2, PbTheme, PbParagraph } from "../../../components/pd_themed";
import { TextStrings } from "../../../lang/TextStrings";
import { ObsDataComponent } from "../../../libs/react";
import { DialogExecutor } from "../../../libs/react/DialogExecutor";
import { ICustomerContext } from "../../../services/customers/Interfaces";
import { IElement } from "../../../services/elements/Interfaces";
import { IElementTopicHeader } from "../../../services/element_topics/Interfaces";
import { MediaList } from "./MediaList";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content auto min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

const scrollContainer = style({
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    padding: "5px",
    gridColumnGap: "10px",
    gridRowGap: "10px",
});


const mediaListStyle = style({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    padding: "5px",
    gridColumnGap: "10px",
    gridRowGap: "10px",
    height: "70vh",
});

interface IInput {
    customerContext: ICustomerContext;
    topic: IElementTopicHeader;
}

interface IOutput {
    elements: IElement[];
}

export class SelectMediaFromTopicDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectMediaFromTopicDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const [selectedElements, setSelectedElements] = useState<IElement[]>([]);
    const topic = props.dialogModel.input.topic;
    const customerContext = props.dialogModel.input.customerContext;

    const topicId = topic ? topic.topicId : undefined;
    const topicName = topic ? topic.name : "";
    return (
        <div className={dialogContainer}>
            <PbHeader2>{TextStrings.formatElementSelectorTopicName(topicName)}</PbHeader2>
            <ActivatorComponent activator={customerContext.elementTopicServiceActivator} content={(topicService) => (
                <div key={topicId} className={scrollContainer}>
                    {topicId && (
                        <ActivatorComponent activator={topicService.getTopicElementsActivator(topicId)} content={(elements) => (
                            <ObsDataComponent obsData={elements.obsData} content={(elementsData) => (
                                <div className={mediaListStyle}>
                                    {elementsData.elements.length > 0 && <MediaList elements={elementsData.elements} onElementClicked={(element) => elementClicked(element)} selectedElements={selectedElements} />}
                                    {elementsData.elements.length === 0 && <PbParagraph>{TextStrings.textElementSelectorTopicEmpty}</PbParagraph>}
                                </div>
                            )} />
                        )} />
                    )}
                </div>
            )} />
            <PbButton onClick={() => closeDialog()}>{selectedElements.length > 0 ? TextStrings.textSelectAction : TextStrings.textBackAction}</PbButton>
        </div>
    );

    function closeDialog(): void {
        if (selectedElements.length > 0) {
            props.dialogModel.resolve({ elements: selectedElements });
        } else {
            props.dialogModel.close();
        }
    }

    function elementClicked(element: IElement) {
        const index = selectedElements.indexOf(element);
        if (index === -1) {
            setSelectedElements([...selectedElements, element]);
        } else {
            const selection = [...selectedElements];
            selection.splice(index, 1)
            setSelectedElements(selection);
        }
    }
}

export const SelectMediaFromTopicDialog = withPbDialog<IInput, IOutput>(SelectMediaFromTopicDialogForm);
