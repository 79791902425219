import { IObservable, ObservableDef } from "../observables";

export interface IRegistrationParent {
    registrationDisposed(): void;
}

export type DialogModelUntyped = {
    close: () => void;
}

export type DialogModel<TInput, TOutput> = DialogModelUntyped & {
    input: TInput;
    resolve: (result: TOutput) => void;
}

export type ObservableDialogModel<TInput, TOutput> = IObservable<DialogModel<TInput, TOutput> | undefined>;

export type DialogObservableProps<TInput, TOutput> = {
    obsDialogModel: ObservableDialogModel<TInput, TOutput>;
}

export interface IDialogExecutor<TInput, TOutput> {
    executeAsync(input: TInput): Promise<DialogResult<TOutput>>;
}

export type DialogResolvedResult<TOutput> = {
    result: true;
    output: TOutput;
}
export type DialogClosedResult = {
    result: false;
}
export type DialogResult<TOutput> = DialogResolvedResult<TOutput> | DialogClosedResult

export class DialogExecutor<TInput, TOutput> implements IDialogExecutor<TInput, TOutput> {
    private _resolve: ((value: DialogResult<TOutput>) => void) | undefined;
    private _obsDialogModel = new ObservableDef<DialogModel<TInput, TOutput> | undefined>(undefined);

    public get obsDialogModel(): ObservableDialogModel<TInput, TOutput> {
        return this._obsDialogModel;
    }

    public executeAsync(input: TInput): Promise<DialogResult<TOutput>> {
        if (!this._obsDialogModel.hasSubscribers) {
            throw new Error("No dialog registered");
        }

        this._obsDialogModel.emit({
            input,
            close: () => this.resolve({ result: false }),
            resolve: (value) => this.resolve({ result: true, output: value }),
        })

        return new Promise<DialogResult<TOutput>>((resolve, reject) => {
            this._resolve = resolve;
        });
    }

    private resolve(result: DialogResult<TOutput>): void {
        this._obsDialogModel.emit(undefined);
        if (this._resolve) {
            this._resolve(result);
            this._resolve = undefined;
        }
    }
}