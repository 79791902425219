import { FetchResultContent, FetchResultNoContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';
import { IAccessTokenProvider } from '../services/token/Interfaces';

export class ElementsApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(accessTokenProvider: IAccessTokenProvider, configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");
        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await accessTokenProvider.getTokenAsync();
            headers.set("recordcasetoken", token.token);
        });
    }

    public getElementsAsync(): Promise<FetchResultContent<IApiElements>> {
        return this._fetchService.fetchGetAsync<IApiElements>(this.getUri());
    }

    public removeMediaAsync(ids: string[]): Promise<FetchResultNoContent> {
        return this._fetchService.fetchPostAsync(`${this.getUri()}/delete`, ids);
    }

    public getTopicsAsync(): Promise<FetchResultContent<IApiTopicHeaders>> {
        return this._fetchService.fetchGetAsync<IApiTopicHeaders>(`${this.getUri()}/topics`);
    }

    public getTopicAsync(topicId: string): Promise<FetchResultContent<IApiTopic>> {
        return this._fetchService.fetchGetAsync<IApiTopic>(`${this.getUri()}/topics/${topicId}`);
    }

    private getUri(): string {
        return this._configService.config.elementApiUrl;
    }
}

export interface IApiElements {
    elements: IApiElement[];
}

export type ElementType = "Topic" | "Customer";

export interface IApiElement {
    elementId: string;
    name: string;
    url: string;
    thumbnailUrl: string;
    elementType: ElementType,
    topicName: string | undefined
}

export interface IApiTopicHeaders {
    topics: IApiTopicHeader[];
}

export interface IApiTopicHeader {
    topicId: string;
    name: string;
}

export interface IApiTopic {
    topicId: string;
    name: string;
    elements: IApiElement[];
}