import React from 'react';
import { ModelStateComponent } from '../../../../components/datawaiting';
import { PbBlockContainer, PbHeader2, RouterListButton } from '../../../../components/pd_themed';
import { style } from 'typestyle';
import { TextStrings } from '../../../../lang/TextStrings';
import { useCustomerContext } from '../UseCustomer';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

const listStyle = style({
    overflow: "auto",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "min-content"
});

export function PbBlockMenu(): JSX.Element | null {
    const customerContext = useCustomerContext();

    return customerContext ? (
        <PbBlockContainer>
            <ModelStateComponent
                key={customerContext?.customerId}
                obsModelState={customerContext?.obsCustomerModelState}
                content={(customer) => (

                    <div className={containerStyle} >
                        <PbHeader2>
                            <span>{customer.company ?? ""}</span>
                        </PbHeader2>
                        <div className={listStyle}>
                            {customer.enableMenuPresenter && <RouterListButton to="narrow_casting" text={TextStrings.textNarrowCastingBlockTitle} />}
                            {customer.enableMenuSpotify && <RouterListButton to="spotify" text={TextStrings.textSpotifyBlockTitle} />}
                        </div>
                    </div>

                )} />
        </PbBlockContainer>
    ) : null;
}
