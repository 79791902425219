import { FetchResultNoContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';
import { IDeviceContext } from '../services/device/Interfaces';
import { IDeviceTokenProvider } from '../services/token/Interfaces';

// Connect
interface IApiGetConnectRequest {
    connectCode:string;
}

export class ConnectCodeApi2 {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;
    private readonly _tokenProvider: IDeviceTokenProvider;

    constructor(deviceContext: IDeviceContext){
        this._configService = deviceContext._configService;
        this._tokenProvider = deviceContext.tokenProvider;
        this._fetchService = new FetchService("same-origin");
        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await deviceContext.tokenProvider.getTokenAsync();
            if (token) {
                headers.set("recordcasetoken", token.token);
            }
        });
    }

    public connectAsync(request: IApiGetConnectRequest): Promise<FetchResultNoContent> {
        const uri = this._configService.config.myPbSoundApiUrl + "/connect";
        return this._fetchService.fetchPostAsync(uri, request);
    }
}

