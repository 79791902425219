import { IApiElement } from "../../apis/ElementApi";
import { IObservableDef, ObservableDef } from "../../libs/observables";
import { IElement, IElementListEditService, IElementSelectionService, IElementService } from "./Interfaces";
import { Element } from "./Element";
import { ElementServiceActivator } from "./ElementServiceActivator";
import { ElementSelectionService } from "./ElementSelectionService";
import { ElementListEditService } from "./ElementListEditService";

export class ElementService implements IElementService {
    private readonly _elementServiceActivator: ElementServiceActivator;
    private readonly _obsElements = new ObservableDef<Element[]>([]);
    private readonly _elementMap = new Map<string, Element>();

    constructor(activator: ElementServiceActivator, elementsApiData: IApiElement[]) {
        this._elementServiceActivator = activator;
        this.updateElement(elementsApiData);
    }

    public get obsElements(): IObservableDef<IElement[]> {
        return this._obsElements;
    }

    public getElement(elementId: string): Element {
        let element = this._elementMap.get(elementId);
        if (!element) {
            element = new Element(this, elementId);
            this._elementMap.set(elementId, element);
        }
        return element;
    }

    public updateElementsFromSlides(apiElements: IApiElement[]): void {
        for (const apiElement of apiElements) {
            const element = this.getElement(apiElement.elementId);
            element.update(apiElement);
        }
    }

    public refresh(): void {
        this._elementServiceActivator.recreateService();
    }

    public removeMediaAsync(removedElements: IElement[]): Promise<boolean> {
        return this._elementServiceActivator.removeMediaAsync(removedElements);
    }

    private _selectionService: ElementSelectionService | undefined;
    public get selectionService(): IElementSelectionService {
        return this._selectionService || (this._selectionService = new ElementSelectionService(this));
    }

    private _listEditService: ElementListEditService | undefined;
    public get listEditService(): IElementListEditService{
        return this._listEditService || (this._listEditService = new ElementListEditService(this));
    }

    private updateElement(apiElement: IApiElement[]): void {
        const elements = apiElement.map((apiElement) => {
            const elements = this.getElement(apiElement.elementId);
            elements.update(apiElement);
            return elements;
        });

        const newSet = new Set(elements);
        for (const l of this._obsElements.value) {
            if (!newSet.has(l)) {
                l.clear();
            }
        }

        this._obsElements.emit(elements);
    }
}
