import React from "react";
import { CircularProgress } from "@material-ui/core";
import { style } from "typestyle";
import { ActivatorComponent } from "../../../../components/datawaiting/ActivatorComponent";
import { PbHeader3 } from "../../../../components/pd_themed";
import { DialogExecutor, IDialogExecutor } from "../../../../libs/react/DialogExecutor";
import { ICustomerContext } from "../../../../services/customers/Interfaces";
import { IPresentationService } from "../../../../services/presentations/Interfaces";
import { PresentationEditor } from "../../../editors/presentations/PresentationEditor";
import { EditPresentationNameDialogInput, EditPresentationNameDialogOutput } from "../../../editors/presentations/EditPresentationNameDialog";
import { withPbDialog, WithPbDialogProps } from "../../../../components/overlay";

const overlayStyle = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
});
const overlayContent = style({
    gridRow: "1",
    gridColumn: "1"
});
const loaderContainer = style({
    gridRow: "1",
    gridColumn: "1",
    backgroundColor: "rgba(0,0,0,0.6)",
    minHeight: "300px",
    display: "grid",
    gridTemplateRows: "auto 1fr",
    gridTemplateColumns: "1fr",
    padding: "10px",
    zIndex: 1000
});

interface IInput {
    presentationService: IPresentationService;
    customer: ICustomerContext;
    presentationId: string;
}

interface IOutput {
    void: void;
}

export class PresentationOverlayExecuter extends DialogExecutor<IInput, IOutput> { }

export type EditPresentationNameDialogExecutor = IDialogExecutor<EditPresentationNameDialogInput, EditPresentationNameDialogOutput>;

function PresentationOverlayForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const customerContext = props.dialogModel.input.customer;
    return (
        <div className={overlayStyle}>
            <ActivatorComponent
                activator={props.dialogModel.input.presentationService.getPresentationActivator(props.dialogModel.input.presentationId)}
                content={(presentation) => (
                    <div className={overlayContent}>
                        <PresentationEditor
                            customer={customerContext}
                            presentation={presentation}
                            onClose={() => close()}
                        />
                    </div>

                )}
                loadingContent={
                    <div className={loaderContainer}>
                        <PbHeader3>Presentatie wordt geladen</PbHeader3>
                        <div style={{ margin: "auto" }}>
                            <CircularProgress />
                        </div>
                    </div>
                }
            />
        </div>
    );

    function close(): void {
        props.dialogModel.close();
    }
}

export const PresentationOverlay = withPbDialog<IInput, IOutput>(PresentationOverlayForm);
