import { CustomerContext } from '../customers/CustomerContext';
import { ElementsApi } from '../../apis/ElementApi';
import { ElementTopicService } from './ElementTopicService';
import { ServiceActivator, ServiceErrorApi, ServiceOk } from '../../libs/activators';

export class ElementTopicServiceActivator extends ServiceActivator<ElementTopicService>{
    public constructor(customer: CustomerContext){
        const api = new ElementsApi(customer.tokenProvider, customer.deviceContext._configService);

        super(async() => {
            const apiResult = await api.getTopicsAsync();
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const service = new ElementTopicService(customer, this, api, apiResult.data.topics);
            return new ServiceOk(service);
        });
    }
}