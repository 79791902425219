import { ServiceActivator, ServiceErrorApi, ServiceOk } from "../../libs/activators";
import { PresentationTopic } from "./PresentationTopic";
import { PresentationApi } from "../../apis/PresentationApi";

export class PresentationTopicActivator extends ServiceActivator<PresentationTopic>{
    public constructor(api: PresentationApi, topicId: string) {
        super(async() => {
            const apiResult = await api.getPresentationTopicPresentationsAsync(topicId);
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }
            const service = new PresentationTopic(apiResult.data);
            return new ServiceOk(service);
        });
    }
}