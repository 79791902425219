import React from "react";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { classes, style } from "typestyle";
import { ILicenseData } from "../../../../../services/licenses/Interfaces";
import { PbButtonIcon, PbTheme, PbHeader4 } from '../../../../../components/pd_themed';
import { useNavigate } from "react-router-dom";
import { useCustomerContext } from "../../UseCustomer";
import { useLicenseContext } from "./UseLicense";

const listItemStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr min-content ",
    gridTemplateRows: "min-content min-content",
    padding: "10px",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    transition: PbTheme.transitions.all1x,
    alignItems: "center",
    overflow: "hidden",
    borderRadius: "10px",
    $nest: {
        '&:hover': {
            backgroundColor: PbTheme.colors.backgroundBlockLv4,
        }
    }
});

const listItemStyleActive = style({
    backgroundColor: PbTheme.colors.backgroundBlockLv4,
});

const buttonsContainer = style({
    display: "grid",
    gridTemplateColumns: "min-content min-content",
    gridTemplateRows: "min-content",
    gridRow: "1 / span 2",
    gridColumn: "2"
});

interface RouterListButtonProps {
    license: ILicenseData;
    //menuTopic: string;
}

export function PlayerListItem(props: RouterListButtonProps): JSX.Element {
    const customerContext = useCustomerContext();
    const licenseContext = useLicenseContext();
    const navigate = useNavigate();
    //const settingsUrl = `license/${props.license.licenseId}/${props.menuTopic}`;

    let className = listItemStyle;
    let selected = false;
    if (props.license.licenseId === licenseContext?.licenseId) {
        className = classes(listItemStyle, listItemStyleActive);
        selected = true;
    }

    return (
        <div className={className}>
            <PbHeader4 style={{ gridRow: 1, gridColumn: 1 }}>{props.license.description}</PbHeader4>
            {/* <PbParagraph style={{gridRow: 2, gridColumn: 1}}>{this.props.license.presentationName || TextStrings.textMyPlayerNoSelectedPresentation}</PbParagraph> */}
            <div className={buttonsContainer}>
                {!selected &&
                    <PbButtonIcon onClick={onClick}>
                        <ArrowForwardIcon htmlColor={PbTheme.colors.textNormal} />
                    </PbButtonIcon>
                }
                {selected &&
                    <PbButtonIcon onClick={onClick}>
                        <CheckIcon htmlColor={PbTheme.colors.textNormal} />
                    </PbButtonIcon>
                }
            </div>
        </div>
    );

    function onClick(): void {
        if (customerContext) {
            customerContext.licenses.select(props.license.licenseId);
            navigate("license")
        }
    }
}