import { ObservableDef, IObservableDef } from "../../libs/observables";
import { IUploadFile, UploadStatus, IUploadedFile } from "./Interfaces";
import { UploadApi } from "../../apis/UploadApi";

export class UploadFile implements IUploadFile {
    private readonly _obsStatus = new ObservableDef<UploadStatus>("waiting");
    private readonly _file: File;

    constructor(file: File) {
        this._file = file;
    }

    public get file(): File { return this._file; }

    public get name(): string { return this._file.name; }

    public get obsStatus(): IObservableDef<UploadStatus> { return this._obsStatus; }

    public get isReadyForUpload(): boolean { return this._obsStatus.value === "waiting"; }


    public fileExist(file: File): boolean {
        if (this._file.name !== file.name) {
            return false;
        }

        if (this._file.size !== file.size) {
            return false;
        }

        if (this._file.lastModified !== file.lastModified) {
            return false;
        }

        return true;
    }

    public async uploadAsync(api: UploadApi): Promise<IUploadedFile | undefined> {
        this.setStatus("uploading");

        const uploadResult = await api.uploadFileAsync(this._file);

        this.setStatus(uploadResult.resultType === "data" ? "success" : "error");
        if (uploadResult.resultType === "data") {
            return {
                name: this.name,
                elementId: uploadResult.data.elementId
            };
        } else {
            return undefined;
        }
    }

    public setStatus(status: UploadStatus): void {
        this._obsStatus.emit(status);
    }
}
