import { IChangePasswordInput, IChangePasswordOutput } from './ChangePasswordDialog';
import { ILoginService } from '../../services/login/Interfaces';
import { IMessageDialogInput } from './MessageDialog';
import { IResetPasswordInput, IResetPasswordOutput } from './ResetPasswordDialog';
import { ISubscribeOutput } from './SubscribeDialog';
import { DialogExecutor, ObservableDialogModel } from '../../libs/react/DialogExecutor';
import { LoginDialogResult } from './LoginDialog';

export class LoginLogic {
    private readonly _loginService: ILoginService;
    private readonly _loginDialogExecutor = new DialogExecutor<void, LoginDialogResult>();
    private readonly _changePasswordDialogExecutor = new DialogExecutor<IChangePasswordInput, IChangePasswordOutput>();
    private readonly _resetPasswordDialogExecutor = new DialogExecutor<IResetPasswordInput, IResetPasswordOutput>();
    private readonly _subscribeDialogExecutor = new DialogExecutor<void, ISubscribeOutput>();
    private readonly _messageDialogExecutor = new DialogExecutor<IMessageDialogInput, void>();

    private readonly _connectSystemDialogExecutor = new DialogExecutor<void, void>();


    constructor(loginService: ILoginService) {
        this._loginService = loginService;
    }

    public get obsLoginDialogModel(): ObservableDialogModel<void, LoginDialogResult> { return this._loginDialogExecutor.obsDialogModel; }
    public get obsChangePasswordDialogModel(): ObservableDialogModel<IChangePasswordInput, IChangePasswordOutput> { return this._changePasswordDialogExecutor.obsDialogModel; }
    public get obsResetPasswordDialogModel(): ObservableDialogModel<IResetPasswordInput, IResetPasswordOutput> { return this._resetPasswordDialogExecutor.obsDialogModel; }
    public get obsSubscribeDialogModel(): ObservableDialogModel<void, ISubscribeOutput> { return this._subscribeDialogExecutor.obsDialogModel; }
    public get obsMessageDialogModel(): ObservableDialogModel<IMessageDialogInput, void> { return this._messageDialogExecutor.obsDialogModel; }
    public get obsConnectSystemDialogModel(): ObservableDialogModel<void, void> { return this._connectSystemDialogExecutor.obsDialogModel; }

    public async loginAsync(): Promise<void> {
        const dialogResult = await this._loginDialogExecutor.executeAsync();
        if (!dialogResult.result) return;

        switch (dialogResult.output.type) {
            case "cancel":
                break;
            case "reset":
                await this.resetPasswordAsync(dialogResult.output.email);
                break;
            case "login":
                {
                    const loginResult = await this._loginService.loginAsync(dialogResult.output.email, dialogResult.output.password);
                    switch (loginResult.type) {
                        case "loginError":
                            await this._messageDialogExecutor.executeAsync({
                                title: "Login fout",
                                message: "Email wachtwoord combinatie onjuist"
                            });
                            break;
                        case "serverError":
                            await this._messageDialogExecutor.executeAsync({
                                title: "Login fout",
                                message: "Er heeft zich een onbekende fout opgetreden",
                            });
                            break;
                        case "ok":
                            break;
                        case "loginAction":
                            await this.changePasswordAsync(dialogResult.output.email, dialogResult.output.password);
                            break;
                        default:
                    }
                }
                break;
            default:
        }
    }

    public async resetPasswordAsync(email: string): Promise<void> {
        const dialog = await this._resetPasswordDialogExecutor.executeAsync({ email });
        if (dialog.result) {
            const subscribeResult = await this._loginService.subscribeEmailAsync(dialog.output.email);
            switch (subscribeResult.type) {
                case "subscribed":
                    await this._messageDialogExecutor.executeAsync({
                        title: "Confirmation",
                        message: `An email with number ${subscribeResult.emailNumber} is send to ${dialog.output.email} around ${subscribeResult.emailDateTime}`,
                    });
                    break;
                case "subscribeError":
                    this.displayUnexpectedErrorAsync(2);
                    break;
                default:
            }
        }
    }

    public async changePasswordAsync(email: string, password: string): Promise<void> {
        // eslint-disable-next-line no-constant-condition
        while (true) {
            const dialogResult = await this._changePasswordDialogExecutor.executeAsync({ email });
            if (!dialogResult.result) {
                return;
            }

            const loginResult = await this._loginService.loginAsync(email, password, dialogResult.output.newPassword);
            switch (loginResult.type) {
                case "loginError":
                    await this._messageDialogExecutor.executeAsync({
                        title: "Error",
                        message: "Sorry, but an error has occurred",
                    });
                    return;
                case "ok":
                    return;
                case "loginAction":
                    if (loginResult.newPasswordToWeak) {
                        await this._messageDialogExecutor.executeAsync({
                            title: "Error",
                            message: "The new given password is to weak. Make the password longer or more complex and try again",
                        });
                    } else {
                        await this.displayUnexpectedErrorAsync(1);
                    }
                    break;
                default:
            }
        }
    }

    public async subscribeAsync(): Promise<void> {
        const dialogResult = await this._subscribeDialogExecutor.executeAsync();
        if (dialogResult.result) {
            const subscribeResult = await this._loginService.subscribeEmailAsync(dialogResult.output.email);
            switch (subscribeResult.type) {
                case "subscribed":
                    await this._messageDialogExecutor.executeAsync({
                        title: "Email verzonden",
                        message: `An email with number ${subscribeResult.emailNumber} is send to ${dialogResult.output.email} around ${subscribeResult.emailDateTime}`,
                    });
                    break;
                case "subscribeError":
                    this.displayUnexpectedErrorAsync(2);
                    break;
                default:
            }
        }
    }

    public async connectSystemDialogExecuteAsync(): Promise<void> {
        await this._connectSystemDialogExecutor.executeAsync();
    }

    private async displayUnexpectedErrorAsync(code: number): Promise<void> {
        await this._messageDialogExecutor.executeAsync({
            title: "Error",
            message: `An unexpected error has occured. (code: ${code})`,
        });
    }
}
