import { IObservable, ObservableDef } from "../../libs/observables";
import { IElementTopic, IElementTopicData } from "./Interfaces";
import { IApiTopic } from "../../apis/ElementApi";
import { IElementService } from "../elements/Interfaces";

export class ElementTopic implements IElementTopic {
    private readonly _topicId: string;

    private readonly _obsData: ObservableDef<IElementTopicData | undefined> = new ObservableDef<IElementTopicData | undefined>(undefined);
    private readonly _elementService: IElementService;

    public constructor(elementService: IElementService, apiTopic: IApiTopic) {
        this._elementService = elementService;
        this._topicId = apiTopic.topicId;
        this.update(apiTopic);
    }

    public get topicId(): string { return this._topicId; }
    public get obsData(): IObservable<IElementTopicData | undefined> { return this._obsData; }

    public update(data: IApiTopic): void {
        this._elementService.updateElementsFromSlides(data.elements);

        this._obsData.emit({
            id: data.topicId,
            name: data.name,
            elements: data.elements.map((ae) => {
                const elm = this._elementService.getElement(ae.elementId)
                return elm;
            })
        });
    }
}