import { FetchResultContent, FetchResultNoContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';

export class LoginApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(configService: IConfigService){
        this._configService = configService;
        this._fetchService = new FetchService("same-origin");
    }

    public async getAccessTokenAsync(deviceToken:string, data: IApiGetAccessTokenRequest): Promise<FetchResultContent<IApiGetAccessTokenResult>> {
        const uri = this._configService.config.myPbSoundApiUrl + "/getaccesstoken";
        return this._fetchService.fetchPostWithResultAsync<IApiGetAccessTokenResult>(uri, data, (headers)=>headers.set("recordcasetoken", deviceToken));
    }

    public connectComputerAsync(deviceToken:string, request: IApiGetConnectComputerRequest): Promise<FetchResultNoContent> {
        const uri = this._configService.config.myPbSoundApiUrl + "/connectcomputer";
        return this._fetchService.fetchPostAsync(uri, request, (headers)=>headers.set("recordcasetoken", deviceToken));
    }

    public getDisconnectComputerAsync(deviceToken:string, request: IApiGetConnectComputerRequest): Promise<FetchResultNoContent> {
        const uri = this._configService.config.myPbSoundApiUrl + "/disconnectcomputer";
        return this._fetchService.fetchPostAsync(uri, request, (headers)=>headers.set("recordcasetoken", deviceToken));
    }

    public loginAsync(deviceToken:string, request: IApiGetLoginRequest): Promise<FetchResultContent<ApiGetLoginResult>> {
        const uri = this._configService.config.myPbSoundApiUrl + "/login";
        return this._fetchService.fetchPostWithResultAsync<ApiGetLoginResult>(uri, request, (headers)=>headers.set("recordcasetoken", deviceToken));
    }

    public logoutAsync(deviceToken:string, ): Promise<FetchResultContent<ApiGetLoginResult>> {
        const uri = this._configService.config.myPbSoundApiUrl + "/logout";
        return this._fetchService.fetchPostWithResultAsync<ApiGetLoginResult>(uri, {}, (headers)=>headers.set("recordcasetoken", deviceToken));
    }
}

// GetAccessToken
interface IApiGetAccessTokenRequest {
    customerId?: string;
    licenseId?: string;
}

type TokenType = "User" | "Player" | "Service" | "Presenter" | "Machine" | "Computer" | "LocalUser" | "Device";
export interface IApiGetAccessTokenResult {
    canEnterConnectCode: boolean;
    computerId: string;
    computerSerialNumber: string;
    customerId: string;
    customerName: string;
    displayName: string;
    licenseId: string;
    licenseName: string;
    minutesValid: number;
    token: string;
    userId: string;
    userName: string;
    validUntilUtc: string;
    tokenType: TokenType;
    hasAccess: boolean;
}

// GetConnectComputer
interface IApiGetConnectComputerRequest {
    computerToken: string;
}

// GetLogin
interface IApiGetLoginRequest {
    email: string;
    password: string;
    newPassword?: string;
}

type ApiGetLoginResult = IApiGetLoginResultOk | IApiGetLoginResultError;

interface IApiGetLoginResultOk {
    type: "loginOk";
}

interface IApiGetLoginResultError {
    type: "loginError";
    needNewPassword: boolean;
    passwordIsToWeak: boolean;
    accountIsLockedOut: boolean;
}