import React, { PureComponent } from "react";
import { style, classes } from "typestyle";
import { PbTheme } from "./PbTheme"

const buttonStyle = style({
    color: PbTheme.colors.textNormal,
    backgroundColor: PbTheme.colors.buttonPrimary,
    transition: PbTheme.transitions.all05x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    padding: "0.6em 1em 0.6em 1em",
    borderRadius: "100px",
    border: "none",
    outline: "0",
    alignContent: "center",
    boxShadow: "0px 4px 4px #181818",
    margin: "0px 0 4px 0",
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: PbTheme.colors.buttonPrimaryHover,
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "2px 0 2px 0",
            backgroundColor: PbTheme.colors.buttonPrimaryActive,
        },
        "&:disabled": {
            backgroundColor: PbTheme.colors.buttonPrimaryDisabled,
            color: "#AAA"
        }
    }
});

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
    className?: string;
    text: string;
}

interface IState {
    className?: string;
}

export class PbButtonSubmit extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        if (props.className) {
            this.state = {
                className: classes(props.className, buttonStyle)
            };
        } else {
            this.state = {
                className: classes(buttonStyle)
            };
        }
    }

    public render(): JSX.Element {
        return (
            <input type="submit" {...this.props} value={this.props.text} className={this.state.className} />
        );
    }
}
