import { IObservable, ObservableDef } from "../observables";
import { generateKey, PromiseAggregator } from "../utils";
import { ActivatorExecuteResult } from "./ActivatorExecuteResult";
import { ActivatorServiceResult } from "./ActivatorServiceResult";
import { IStoreService } from "./IStoreService";
import { ModelState } from "./ModelState";

/* eslint-disable @typescript-eslint/ban-types */
export type StoreServiceActivatorFactory<TModel extends object, TService extends IStoreService<TModel>> = () => Promise<ActivatorServiceResult<TService>>;

export abstract class StoreServiceActivator<TModel extends object, TService extends IStoreService<TModel> = IStoreService<TModel>> {
  private readonly _aggregator: PromiseAggregator<ActivatorServiceResult<IStoreService<TModel>>>;
  private readonly _obsModelState = new ObservableDef<ModelState<TModel>>({ type: "init", message: "" });
  private readonly _factory: StoreServiceActivatorFactory<TModel, TService>;
  private _isLoaded: boolean;
  private _isBusy: boolean;
  private _canRetryInit: boolean;
  private _service: TService | undefined;

  public readonly key: string;

  constructor(factory: StoreServiceActivatorFactory<TModel, TService>) {
    this.key = generateKey("activator:");
    this._factory = factory;
    this._isLoaded = false;
    this._isBusy = false;
    this._canRetryInit = false;
    this._obsModelState.registerOnFirstSubscriber(() => this.onFirstSubscriber());
    this._aggregator = new PromiseAggregator<ActivatorServiceResult<IStoreService<TModel>>>(false, () => this.loadAsync(1));
  }

  public get obsModelState(): IObservable<ModelState<TModel>> {
    return this._obsModelState;
  }

  public get service(): TService | undefined { return this._service; }

  public get isBusy(): boolean { return this._isBusy; }

  public getResultAsync(): Promise<ActivatorServiceResult<IStoreService<TModel>>> {
    return this._aggregator.executeAsync();
  }

  public async getAsync(): Promise<IStoreService<TModel>> {
    const result = await this.getResultAsync();

    if (result.isError === true) {
      throw new Error(result.error);
    }

    return result.service;
  }

  public async getAsyncOld(): Promise<IStoreService<TModel>> {
    return this.getAsync();
  }

  public recreateService(): void {
    if (this._isBusy) {
      throw new Error("Cannot refresh during execute. Return 'new ServiceRefresh()' instead");
    }

    if (this._isLoaded) {
      this.setInit("Verversen");
      this._isLoaded = false;
      this._service = undefined;
      this.getResultAsync();
    }
  }

  public clearService(): void {
    if (this._isBusy) {
      return;
    }

    if (this._isLoaded) {
      this._isLoaded = false;
      this._service = undefined;

      this._obsModelState.emit({
        type: "initerror",
        onRetry: undefined,
        error: "De bewerking is afgebroken",
      });
    }
  }

  public resetError(): void {
    if (this._obsModelState.value.type === "initerror" || this._obsModelState.value.type === "updateerror") {
      if (this._service) {
        this._obsModelState.emit({
          type: "data",
          data: this._service.storeReader.model,
        });
      } else {
        this.getResultAsync();
      }
    }
  }

  public async executeAsync(callback: () => Promise<ActivatorExecuteResult>, busyMessage?: string): Promise<boolean> {
    return this.executeAsyncInternal(callback, true, busyMessage);
  }

  public async executeWithoutLoaderAsync(callback: () => Promise<ActivatorExecuteResult>): Promise<boolean> {
    return this.executeAsyncInternal(callback, false);
  }

  // protected createExecuteResult<TData>(apiResult: ApiResult<TData>, refreshService: boolean): ActivatorExecuteResult {
  //   if (apiResult.result !== "Data") {
  //     return new ExecuteErrorApi(apiResult);
  //   }

  //   return new ExecuteOk(refreshService);
  // }

  private onFirstSubscriber() {
    if (!this._isLoaded) {
      this.loadAsync(0);
    }
  }

  private async executeAsyncInternal(callback: () => Promise<ActivatorExecuteResult>, showLoader: boolean, busyMessage?: string): Promise<boolean> {
    if (!this.service) {
      await this.getAsync();
    }

    if (this._isBusy) {
      return false;
    }

    if (showLoader) {
      this.setUpdate(busyMessage);
    }

    try {
      const result = await callback();
      this.setResult(result);
      if (result.isError === false && result.refreshService) {
        this.recreateService();
      }

      return !result.isError;
    } catch (exception) {
      this.setUpdateError(String(exception));

      return false;
    }
  }

  private async loadAsync(x: number): Promise<ActivatorServiceResult<IStoreService<TModel>>> {
    if (this._service) {
      return {
        isError: false,
        service: this._service,
      };
    }

    try {
      this._isLoaded = true;
      const factoryResult = await this._factory();

      if (factoryResult.isError === true) {
        this.setInitError(factoryResult.error, factoryResult.canRetry);

        return {
          isError: true,
          canRetry: factoryResult.canRetry,
          error: factoryResult.error,
        };
      }

      this._service = factoryResult.service;
      this._service.storeReader.setOnChange((model) => this.onStoreChanged(model));
      this.setReady();

      return {
        isError: false,
        service: factoryResult.service,
      };
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("LoadAsync has an error:", e);
      const error = "Er is een fout opgetreden tijdens het laden van de gegevens";

      const canRetry = true;

      this.setInitError(error, canRetry);

      return {
        isError: true,
        canRetry,
        error,
      };
    }
  }

  private onStoreChanged(model: TModel): void {
    if (this._obsModelState.value.type === "data") {
      this._obsModelState.emit({
        type: "data",
        data: model,
      });
    }
  }

  private setReady() {
    this._isBusy = false;
    if (this._service) {
      this._obsModelState.emit({
        type: "data",
        data: this._service.storeReader.model,
      });
    } else {
      this._obsModelState.emit({
        type: "initerror",
        onRetry: undefined,
        error: "Er is een fout opgetreden tijdens het laden van de gegevens",
      });
    }
  }

  private setInit(initMessage: string | undefined) {
    this._isBusy = true;

    this._obsModelState.emit({
      type: "init",
      message: initMessage || "",
    });
  }

  private setUpdate(updateMessage: string | undefined) {
    this._isBusy = true;
    if (this._service) {
      this._obsModelState.emit({
        type: "update",
        message: updateMessage || "",
        data: this._service.storeReader.model,
      });
    } else {
      this.setInit(updateMessage);
    }
  }

  private setInitError(error: string, canRetry: boolean) {
    this._isBusy = false;
    this._canRetryInit = canRetry;

    this._obsModelState.emit({
      type: "initerror",
      onRetry: canRetry ? () => this.recreateService() : undefined,
      error,
    });
  }

  private setUpdateError(error: string) {
    this._isBusy = false;
    if (this._service) {
      this._obsModelState.emit({
        type: "updateerror",
        onClose: ()=> this.setReady(),
        error,
        data: this._service.storeReader.model,
      });
    } else {
      this.setInitError(error, this._canRetryInit);
    }
  }

  private setResult(result: ActivatorExecuteResult) {
    this._isBusy = false;
    if (result.isError) {
      if (this._service) {
        this.setUpdateError(result.error);
      } else {
        this.setInitError(result.error, this._canRetryInit);
      }
    } else {
      this.setReady();
    }
  }
}
