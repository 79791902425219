import React from 'react';
import { ActivatorComponent } from '../../../../../components/datawaiting/ActivatorComponent';
import { AutomatPresentationTable } from './license/AutomatPresentationTable';
import { ICustomerContext } from '../../../../../services/customers/Interfaces';
import { IDeviceContext } from '../../../../../services/device/Interfaces';
import { ILicenseData, ILicensesModel } from '../../../../../services/licenses/Interfaces';
import { PbHeader4, PbParagraph, PbTheme } from '../../../../../components/pd_themed';
import { PlayerListItem } from './PlayerListItem';
import { style } from 'typestyle';
import { TextStrings } from '../../../../../lang/TextStrings';
import { useLicenseContext } from './UseLicense';

const listStyle = style({
    overflow: "auto",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    rowGap: "4px",
    gridAutoRows: "min-content",
    borderRadius: "10px",
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    padding: "4px",
});

interface IProps {
    licensesModel: ILicensesModel;
    deviceContext: IDeviceContext;
    customerContext: ICustomerContext;
    //menuTopic: string;
}

export function PlayerList(props: IProps): JSX.Element | null {
    const licenseContext = useLicenseContext();
    return props.licensesModel.singleLicense
        ? renderSingle(props.licensesModel.singleLicense)
        : renderList(props.licensesModel.licenses)

    function renderSingle(license: ILicenseData): JSX.Element | null {
        return licenseContext ? (
            <ActivatorComponent activator={licenseContext.licenseAdvertisementActivator}
                content={(licenseAdvertisement) => (
                    <AutomatPresentationTable licenseAdvertisement={licenseAdvertisement} customer={props.customerContext} />
                )} />
        ) : null;
    }

    function renderList(licenses: ReadonlyArray<ILicenseData>): JSX.Element {
        return (
            <div className={listStyle}>
                <PbHeader4>Spelers</PbHeader4>
                {licenses.map((license) => <PlayerListItem key={license.licenseId} license={license} />)}
                {licenses.length === 0 && <PbParagraph style={{ fontWeight: "bold", margin: "auto" }} >{TextStrings.textMyContentNoPlayersMessage}</PbParagraph>}
            </div>
        );
    }
}