import React from "react";
import { ObsDataComponent } from "../../../../../../libs/react";
import { ICustomerContext } from "../../../../../../services/customers/Interfaces";
import { DialogExecutor } from "../../../../../../libs/react/DialogExecutor";
import { IPresentationHeader } from "../../../../../../services/presentations/Interfaces";
import { PbHeader2, PbPresentationList, PbButton, PbTheme } from "../../../../../../components/pd_themed";
import { ActivatorComponent } from "../../../../../../components/datawaiting/ActivatorComponent";
import { CircularProgress } from "@material-ui/core";
import { style } from "typestyle";
import { TextStrings } from "../../../../../../lang/TextStrings"
import { withPbDialog, WithPbDialogProps } from "../../../../../../components/overlay";
import { IApiPresentationTopicHeader } from "../../../../../../apis/PresentationApi";
import { useCustomerContext } from "../../../UseCustomer";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content 1fr min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden"
});

const scrollContainer = style({
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: PbTheme.colors.backgroundBlockLv3
});
const loaderStyle = style({
    display: "grid",
    minHeight: "300px",
    alignItems: "center",
    justifyItems: "center"
});

interface IInput {
    topic: IApiPresentationTopicHeader;
}

interface IOutput {
    presentation: IPresentationHeader;
}

export class SelectTopicPresentationDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectTopicPresentationDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const customerContext = useCustomerContext();
    const topicId = props.dialogModel.input.topic.topicId;
    const topicName = props.dialogModel.input.topic.name;

    return customerContext ? (
        <div className={dialogContainer}>
            <PbHeader2>{TextStrings.formatTopicSelectorTitle(topicName)}</PbHeader2>
            <ActivatorComponent activator={customerContext.presentationTopicServiceActivator} content={(presentationTopicService) => (
                <>
                    {topicId && (
                        <div className={scrollContainer}>
                            <ActivatorComponent key={topicId} activator={presentationTopicService.getTopicPresentationsActivator(topicId)}
                                content={(presentationTopic) => (
                                    <ObsDataComponent obsData={presentationTopic.obsData}
                                        content={(presentationTopicData) => (
                                            <PbPresentationList presentations={presentationTopicData.presentations} onClick={(presentation) => onPresentationSelected(presentation)} />
                                        )}
                                    />
                                )
                                }
                                loadingContent={
                                    <div className={loaderStyle}>
                                        <CircularProgress />
                                    </div>
                                }
                            />
                        </div>
                    )}
                </>
            )} />

            <PbButton onClick={() => closeDialog()}>{TextStrings.textBackAction}</PbButton>
        </div>
    ) : null;

    function closeDialog(): void {
        props.dialogModel.close();
    }

    function onPresentationSelected(presentation: IPresentationHeader): void {
        props.dialogModel.resolve({ presentation });
    }
}

export const SelectTopicPresentationDialog = withPbDialog<IInput, IOutput>(SelectTopicPresentationDialogForm);
