import React from "react";
import { To } from "react-router-dom";
import { classes } from "typestyle";
import { withRouter, WithRouterProps } from "../libs/router";

interface RouterContainerButtonProps extends React.HTMLAttributes<HTMLDivElement> {
    to: To;
    activeClassName?: string;
}

// tslint:disable-next-line: variable-name
export const RouterContainerButton = withRouter(
    class extends React.Component<WithRouterProps<RouterContainerButtonProps>> {
        public render(): JSX.Element {
            // why? otherwise I get a warning in the console.
            const props = { ...this.props };
            if (this.props.activeClassName && props.router.location.pathname.includes(this.props.to.toString())) {
                props.className = classes(props.className, this.props.activeClassName);
            }

            //delete props.staticContext;
            delete props.activeClassName;

            return (
                <div {...props} onClick={(e) => this.onClick(e)}>
                    {this.props.children}
                </div>
            );
        }

        private onClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
            const to = this.props.to.toString();
            this.props.router.navigate(to);
            if (this.props.onClick) {
                this.props.onClick(event);
            }
        }
    });
