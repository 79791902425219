import { PureComponentWithLifetime } from "./PureComponentWithLifetime"

interface IProps {
    state: unknown;
}

interface IState {
    switchState: boolean;
}

export class ConditionSwitch extends PureComponentWithLifetime<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            switchState: props.state === true,
        };
    }

    public render(): React.ReactNode | null {
        return this.props.state === true ? this.props.children : null;
    }
}