import React from 'react';
import * as serviceWorker from './serviceWorker';
import AppLayout from './pages/main/AppLayout';
import applicationServiceActivator from './services/application/ApplicationServiceActivator';
import ReactDOM from 'react-dom';
import { ActivatorComponent } from './components/datawaiting/ActivatorComponent';
import {
    blue,
    lightGreen,
    red,
    yellow
    } from '@material-ui/core/colors';
import { BreadCrumbsProvider } from './components/pd_themed';
import { createTheme } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { PbTheme } from './components/pd_themed/PbTheme';
import './index.css';
import 'react-virtualized/styles.css';
import 'typeface-roboto';
import 'font-awesome/css/font-awesome.css';
/* tslint:disable:file-name-casing */
 // only needs to be imported once


const theme = createTheme({
    // palette: {
    //     type: "light",
    //     primary: {
    //         main: "#3D3B3C",
    //     },
    //     secondary:
    //     {
    //         main: "#CF6795",
    //         // main: "#ef952b",
    //     },
    // },
    // typography: {
    // },
    palette: {
        type: "dark",
        primary: {
            main: PbTheme.colors.primary,
        },
        secondary: {
            main: PbTheme.colors.secondary
        },
        error: red,
        warning: yellow,
        info: blue,
        success: lightGreen,
        text: {
            primary: "#eeeeee",
            secondary: "#e0e0e0"
        },
        background: {
            default: "#1a1a1a",
            paper: "#444444"
        }
    },

});

ReactDOM.render(

    <MuiThemeProvider theme={theme}>
        <BreadCrumbsProvider>
            <ActivatorComponent activator={applicationServiceActivator} content={(applicationService) => (
                <AppLayout applicationService={applicationService} />
            )} />
        </BreadCrumbsProvider>
    </MuiThemeProvider>


    , document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
serviceWorker.register();
