export class Utils {
    public static delayAsync(ms: number): Promise<void> {
        return new Promise<void>((resolve) => {
            setTimeout(resolve, ms);
        });
    }


    public static generateID(): string {
        return (this.globalIdCounter++).toString();
    }

    private static globalIdCounter = 0;

    public static deepCompair(a: unknown, b: unknown): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }
}

let keyCounter = 0;

export function generateKey(prefix?: string | undefined, length = 9): string {
    let res = keyCounter.toString();

    for (let n = 0; n < length; n += 1) {
        res = `0${res}`;
    }

    res = (prefix || "gen_key:") + res;
    keyCounter += 1;

    return res;
}
