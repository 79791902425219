import { ILifetimeContainer } from "../lifetime";
import { ObservableBase } from "./ObservableBase";
import { IObservableDef } from "./IObservableDef";
import { Subscription } from "./Subscription";

export class ObservableDef<T> extends ObservableBase<T> implements IObservableDef<T> {
    private _value: T;

    constructor(initvalue: T) {
        super();
        this._value = initvalue;
    }

    public get value(): T {
        return this._value;
    }

    public emit(value: T): void {
        this._value = value;
        this.emitInternal(value);
    }

    public emitOnChanged(value: T): void {
        if (this._value === value) {
            return;
        }
        this._value = value;
        this.emitInternal(value);
    }

    public silentSet(value: T): void {
        this._value = value;
    }

    public subscribeInitial(lifetimeContainer: ILifetimeContainer, callback: (value: T) => void): void {
        const subscription = super.subscribe(lifetimeContainer, callback);
        subscription.emitValue(this._value);
    }

    protected emitSubscription(subscription: Subscription<T>): void {
        subscription.emitValue(this._value);
    }
}
