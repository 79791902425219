export class CredentialManagement {
    public static async storeAsync(email: string, password: string): Promise<void> {
        try {
            const options = {
                password: {
                    id: email,
                    password,
                },
            };

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).PasswordCredential || (window as any).FederatedCredential) {
                const credentialsContainer = navigator.credentials;
                if (credentialsContainer) {
                    const credentials = await credentialsContainer.create(options as CredentialCreationOptions);
                    if (credentials) {
                        await credentialsContainer.store(credentials);
                    }
                } else {
                    // eslint-disable-next-line no-console
                    console.error("credential manager NOT found!!!");
                }
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Error using CredentialManagement.storeAsync()", err);
        }
    }

    public static async logoutAsync(): Promise<void> {
        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if ((window as any).PasswordCredential || (window as any).FederatedCredential) {
                const credentialsContainer = navigator.credentials;
                if (credentialsContainer) {
                    await credentialsContainer.preventSilentAccess();
                } else {
                    // eslint-disable-next-line no-console
                    console.error("credential manager NOT found!!!");
                }
            }
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error("Error using CredentialManagement.logoutAsync()", err);
        }
    }
}
