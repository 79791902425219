import { CustomersApi, IApiCustomers, IApiCustomersCustomer } from '../../apis/CustomersApi';
import { DeviceContext } from '../device/DeviceContext';
import { ICustomerSearchData, ICustomerSearchModel } from './Interfaces';
import { CustomerSearchServiceActivator } from './CustomerSearchServiceActivator';
import { StoreServiceBase } from '../../libs/activators';


export class CustomerSearchService extends StoreServiceBase<ICustomerSearchModel> {
    private readonly _deviceContext: DeviceContext;
    private readonly _customerMap = new Map<string, ICustomerSearchData>();
    private readonly _customersApi: CustomersApi;
    private readonly _customerListServiceActivator: CustomerSearchServiceActivator;

    constructor(userContext: DeviceContext, customerListServiceActivator: CustomerSearchServiceActivator, customersApi: CustomersApi, customersApiData: IApiCustomers) {
        super();
        const customers = this.createCustomersFromApiData(customersApiData.customers);

        this._deviceContext = userContext;
        this._customerListServiceActivator = customerListServiceActivator;
        this._customersApi = customersApi;
        customers.forEach((c) => this._customerMap.set(c.customerId, c));

        const singleCustomer = (customers.length === 1 && !customersApiData.isSearchNeeded) ? customers[0] : undefined;

        if (singleCustomer) {
            this._deviceContext._customers.select(singleCustomer.customerId);
        }

        this.store.initModel({
            customers,
            hasNone: customersApiData.customers.length === 0 && !customersApiData.isSearchNeeded,
            hasOnlyOne: !!singleCustomer,
            searchText: undefined,
            isMoreAvailable: customersApiData.isMoreAvailable,
            isSearchNeeded: customersApiData.isSearchNeeded,
            searchCustomerAsync: (search) => this.searchCustomerAsync(search),
        });
    }

    public get deviceContext(): DeviceContext { return this._deviceContext; }

    public findCustomer(id: string): ICustomerSearchData | undefined {
        return this._customerMap.get(id);
    }

    private createCustomersFromApiData(apiDataCustomers: IApiCustomersCustomer[]): ICustomerSearchData[] {
        return apiDataCustomers.map((data) => ({
            customerId: data.customerGuid,
            city: data.city,
            company: data.company,
            role: data.role
        }));
    }

    public async searchCustomerAsync(query: string): Promise<void> {
        await this._customerListServiceActivator.executeAsync(async() => {
            const apitResult = await this._customersApi.getCustomersAsync(query);
            if (apitResult.resultType === "error") {
                return {
                    isError: true,
                    canRetry: true,
                    error: apitResult.error,
                };
            }

            const data = apitResult.data;
            const customers = this.createCustomersFromApiData(data.customers);

            this.store.updateModel({
                customers,
                isMoreAvailable: data.isMoreAvailable,
                isSearchNeeded: data.isSearchNeeded,
                hasNone: data.customers.length === 0 && !data.isSearchNeeded,
                hasOnlyOne: false,
                searchText: query,
            });

            return {
                isError: false,
                refreshService: false
            };
        });
    }
}
