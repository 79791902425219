import { ILifetimeContainer } from "../lifetime";
import { Subscription } from "./Subscription";

export abstract class ObservableBase<T> {
  private _subscriptionSet: Set<Subscription<T>> = new Set<Subscription<T>>();
  private _onFirstSubscriber?: ()=>void;
  private _onFirstSubscriberCalled = false;
  public readonly isObservable: true = true;
  public get hasSubscribers(): boolean { return this._subscriptionSet.size > 0; }
  public get subscriberCount(): number { return this._subscriptionSet.size; }

  public subscribe(lifetimeContainer: ILifetimeContainer, callback: (value: T) => (void)): Subscription<T> {
    if (!lifetimeContainer) {
      throw new Error("Can not subscribe with no LifetimeContainer given");
    }

    const subscription = new Subscription<T>(callback, lifetimeContainer, this);

    return subscription;
  }

  public addSubscription(subscription: Subscription<T>): void {
    if (!this._onFirstSubscriberCalled && this._onFirstSubscriber){
      this._onFirstSubscriber();
    }

    this._onFirstSubscriberCalled = true;
    this._subscriptionSet.add(subscription);
  }

  public registerOnFirstSubscriber(callback: ()=>void): void{
    this._onFirstSubscriber = callback;
  }

  public deleteSubscription(subscription: Subscription<T>): void {
    this._subscriptionSet.delete(subscription);
  }

  protected emitInternal(value: T): void {
    for (const sub of this._subscriptionSet) {
      sub.emitValue(value);
    }
  }
}
