import React, { useState } from "react";
import { DialogExecutor } from "../../../libs/react/DialogExecutor";
import { PbHeader2, PbButton, PbDateTimeInput, PbButtonText } from "../../../components/pd_themed"
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay"
import { style } from "typestyle";
import { TextStrings } from "../../../lang/TextStrings";

const dialogContentStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridRowGap: "10px",
    margin: "0 10px"
});

const inputContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "auto",
});

const buttonsContainerStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridTemplateRows: "auto",
    gridColumnGap: "6px",
});

interface IInput {
    date: Date | undefined;
}

interface IOutput {
    date: Date | undefined;
}

export class EditPresentationExpireDateTimeDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function EditPresentationExpireDateTimeDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const oldDate = props.dialogModel.input.date;
    const [date, setDate] = useState<Date | undefined>(oldDate);
    const [isValid, setIsValid] = useState<boolean>(false);

    return (
        <div className={dialogContentStyle}>
            <PbHeader2>
                {oldDate === undefined ?
                    TextStrings.textPresentationEditNameNew :
                    TextStrings.textPresentationEditName
                }

            </PbHeader2>
            <div className={inputContainerStyle}>
                <PbDateTimeInput
                    dateValue={date}
                    onDateChange={(e) => changeDate(e)}
                />
                <PbButtonText onClick={() => removeExpireDate()}>{TextStrings.textMyPresentationsExpireDateRemove}</PbButtonText>
            </div>
            <div className={buttonsContainerStyle}>
                <PbButton onClick={() => closeDialog()} >{TextStrings.textCancelAction}</PbButton>
                <PbButton onClick={() => confirmDialog()} disabled={!isValid}>{TextStrings.textSaveAction}</PbButton>
            </div>
        </div>
    );

    function changeDate(value: undefined | Date): void {
        setIsValid(value !== undefined && value !== oldDate);
        setDate(value);
    }

    function closeDialog(): void {
        props.dialogModel.close();
    }

    function removeExpireDate() {
        props.dialogModel.resolve({ date: undefined });
    }

    function confirmDialog(): void {
        if (date) {
            props.dialogModel.resolve({ date: date });
        }
    }
}

export const EditPresentationExpireDateTimeDialog = withPbDialog<IInput, IOutput>(EditPresentationExpireDateTimeDialogForm);
