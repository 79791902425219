import { IPresentationTopicHeader } from "./Interfaces";
import { PresentationTopicService } from "./PresentationTopicService";

export class PresentationTopicHeader implements IPresentationTopicHeader{
    private readonly _topicService: PresentationTopicService;
    private readonly _topicId: string;
    private readonly _name: string;

    public constructor(parent: PresentationTopicService, topicId: string, name:string) {
        this._topicService = parent;
        this._topicId = topicId;
        this._name = name;
    }

    public get topicId(): string { return this._topicId; }
    public get name(): string { return this._name; }
}