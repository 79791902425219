import React, { PureComponent } from "react";
import { style } from "typestyle";
import { PbTheme } from "./PbTheme"
import classNames from "classnames";
import { Guid } from "guid-typescript";
import scrollService from "../../services/scroll/ScrollService";

const containerClassName = style({
    backgroundColor: PbTheme.colors.backgroundBlockLv1,
    borderRadius: "3px",
    border: "solid 1px #444",
    padding: "12px",
    //maxHeight: "250px",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    boxShadow: "2px 4px 4px #333",
    $nest: {
        [PbTheme.layoutPortraitMediaQuery]: {
            maxHeight: "unset",
        }
    }
});

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
    span?: number;
}

interface IState {
    containerId: string;
}

export class PbBlockContainer extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            containerId: Guid.create().toString()
        };
    }

    public componentDidMount(): void {
        scrollService.scheduleScrollTo(this.state.containerId);
    }

    public componentDidUpdate(): void {
        scrollService.scheduleScrollTo(this.state.containerId);
    }

    public render(): JSX.Element {
        const span = this.props.span || 1;
        let className = containerClassName;
        if (span > 1) {
            const spanClass = style({
                $nest: {
                    [PbTheme.layoutLandscapeMediaQuery]: {
                        gridColumn: `auto / span ${span}`
                    }
                }


            });
            className = classNames(className, spanClass);
        }
        return (
            <div {...this.props} className={className} id={this.state.containerId} >
            </div>
        );
    }
}
