import { ServiceActivator, ServiceOk } from "../../libs/activators";
import { UploadElementService } from "./UploadElementService";
import { CustomerContext } from "../customers/CustomerContext";

export class UploadElementServiceActivator extends ServiceActivator<UploadElementService> {
    constructor(customer: CustomerContext, readyCallback: (() => void)) {
        super(async() => {
            const service = new UploadElementService(customer, readyCallback);
            return new ServiceOk(service);
        });
    }
}
