import React from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { ActivatorComponent } from '../../../components/datawaiting/ActivatorComponent';
import { ICustomerContext } from '../../../services/customers/Interfaces';
import { IElement, IElementSelectionService } from '../../../services/elements/Interfaces';
import { IUploadElementService } from '../../../services/upload/Interfaces';
import { MediaList } from './MediaList';
import { ObsDataComponent, PureComponentWithLifetime } from '../../../libs/react';
import { PbParagraph, PbTheme, PbUploadButton } from '../../../components/pd_themed';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';

const scrollContainer = style({
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    padding: "5px",
    gridColumnGap: "10px",
    gridRowGap: "10px",
    height: "70vh",
});

const buttonStyle = style({
    display: "grid",
    gridTemplateColumns: "auto min-content",
    gridTemplateRows: "1fr",
    alignItems: "center"
});

interface IProps {
    customer: ICustomerContext;
    elementService: IElementSelectionService;
    onSelectionChanged: (elements: IElement[]) => void
}

interface IState {
    selectedElements: IElement[];
}

// tslint:disable-next-line: max-classes-per-file
export class SelectMediaDialogContent extends PureComponentWithLifetime<IProps, IState> {
    private static readonly _elementIdsStagedForSelection: string[] = [];
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectedElements: []
        };

        this.props.elementService.obsSelectedElements.subscribeInitial(this, (selectedElements) => {
            this.setState({ selectedElements });
            this.props.onSelectionChanged(selectedElements);
        });
        this.props.elementService.obsElements.subscribeInitial(this, () => {
            this.checkStagedElementIds();
        });
    }

    public render(): JSX.Element {
        return (
            <>
                <ActivatorComponent activator={this.props.customer.uploadElementServiceActivator} content={(uploadElementService) => (
                    // <PbUploadButton className={buttonStyle} onFilesSelected={(files) => this.addMedia(files, uploadElementService)} accept="image/*,video/*" multiple={true} >
                    <PbUploadButton className={buttonStyle} onFilesSelected={(files) => this.addMedia(files, uploadElementService)} accept="image/*" multiple={true} >
                        {TextStrings.mediaSelectorAddAction}
                        <PublishIcon />
                    </PbUploadButton>
                )} />
                <div className={scrollContainer}>
                    <ObsDataComponent obsData={this.props.elementService.obsElements} content={(elements) => (
                        <>
                            {
                                elements.length > 0 ?
                                    (
                                        <MediaList selectedElements={this.state.selectedElements} elements={elements} onElementClicked={(elm) => this.onElementClicked(elm)} />
                                    ) :
                                    (
                                        <PbParagraph>{TextStrings.textMyMediaNoItems}</PbParagraph>
                                    )
                            }
                        </>
                    )
                    } />
                </div>
            </>
        );
    }

    private onElementClicked(element: IElement) {
        const isSelected = this.state.selectedElements.findIndex((p) => p.elementId === element.elementId) !== -1;
        if (isSelected) {
            this.props.elementService.removeFromSelection(element);
        } else {
            this.props.elementService.addToSelection(element);
        }
    }

    private async addMedia(files: FileList, uploadElementService: IUploadElementService) {
        const result = await uploadElementService.addFilesAsync(files);
        for (const uploadedFile of result) {
            this.stageElementIdForSelection(uploadedFile.elementId);
        }
    }

    private checkStagedElementIds() {
        for (let i = 0; i < SelectMediaDialogContent._elementIdsStagedForSelection.length; i++) {
            const stagedId = SelectMediaDialogContent._elementIdsStagedForSelection[i];
            if (this.state.selectedElements.find((e) => e.elementId === stagedId) !== undefined) {
                SelectMediaDialogContent._elementIdsStagedForSelection.splice(i, 1);
                i--;
            } else {
                const stagedElement = this.props.elementService.obsElements.value.find((e) => e.elementId === stagedId);
                if (stagedElement !== undefined) {
                    this.props.elementService.addToSelection(stagedElement);
                    SelectMediaDialogContent._elementIdsStagedForSelection.splice(i, 1);
                    i--;
                }
            }
        }
    }

    public stageElementIdForSelection(elementId: string): void {
        if (this.state.selectedElements.find((e) => e.elementId === elementId) !== undefined) {
            return;
        }
        SelectMediaDialogContent._elementIdsStagedForSelection.push(elementId);
        this.checkStagedElementIds();
    }
}
