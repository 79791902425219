import React, { useContext } from 'react';
import { AppContent } from './AppContent';
import { AppFooter } from './AppFooter';
import { AppHeader } from './AppHeader';
import { BrowserRouter } from 'react-router-dom';
import { ComponentWithLifetime } from '../../libs/react';
import { IApplicationService } from '../../services/application/Interfaces';
import { IDeviceContext } from '../../services/device/Interfaces';
import { layoutStyles } from './LayoutStyles';
import { style } from 'typestyle';
import { withStyles, WithStyles } from '@material-ui/core/styles';

const rootStyle = style({
    width: "100%",
    height: "100vh",
    display: "grid",
    background: "#0002",
    gridTemplateRows: "auto 1fr auto",
    gridTemplateAreas: "header main footer",
    gridTemplateColumns: "1fr"
});

interface IAppProps extends WithStyles<typeof layoutStyles> {
    applicationService: IApplicationService;
}

interface IAppState {
    deviceContext: IDeviceContext;
    menuOpen: boolean;
    keepMenuOpen: boolean;
}

const ReactDeviceContext = React.createContext<IDeviceContext | undefined>(undefined);

// tslint:disable-next-line: variable-name
const AppLayout = withStyles(layoutStyles)(
    class extends ComponentWithLifetime<IAppProps, IAppState> {
        constructor(props: IAppProps) {
            super(props);
            this.state = {
                menuOpen: false,
                keepMenuOpen: false,
                deviceContext: props.applicationService.deviceContext,
            };
        }

        public render() {
            return (
                <BrowserRouter>
                    <ReactDeviceContext.Provider value={this.state.deviceContext}>
                        {this.renderUserContext()}
                    </ReactDeviceContext.Provider>
                </BrowserRouter>
            );
        }

        private renderUserContext() {
            return (
                <div className={rootStyle}>
                    <AppHeader />
                    <AppContent />
                    <AppFooter />
                </div>
            );
        }
    },

);

export default AppLayout;

export function useDeviceContext(): IDeviceContext | undefined {
    return useContext(ReactDeviceContext);
}
