import { DataContainer, IDataContainer } from '../../libs/datacontainers';
import { IActivator } from '../../libs/activators';
import { ILicenseContext, ILicenseAdvertisement, ILicenseData } from './Interfaces';
import { IObservableOpt, ObservableOpt } from '../../libs/observables';
import { LicenseAdvertisementActivator } from './LicenseAdvertisementActivator';
import { CustomerContext } from '../customers/CustomerContext';
import { AccessTokenProvider } from '../token/AccessTokenProvider';
import { LicenseSpotifyActivator } from '../spotify/LicenseSpotifyActivator';
import { ISpotifyContext } from '../spotify/Interfaces';

export class LicenseContext implements ILicenseContext {
    private readonly _customer: CustomerContext;
    private readonly _licenseId: string;
    private readonly _obsData = new ObservableOpt<ILicenseData>();
    private readonly _licenseDataContainer = new DataContainer<ILicenseData>();
    private readonly _licenseAdvertisementActivator: LicenseAdvertisementActivator;
    private readonly _licenseSpotifyActivator: LicenseSpotifyActivator;

    constructor(customer: CustomerContext, licenseId: string) {
        this._customer = customer;
        this._licenseId = licenseId;
        this._licenseAdvertisementActivator = new LicenseAdvertisementActivator(this);
        this._licenseSpotifyActivator = new LicenseSpotifyActivator(this);
    }

    public get customer(): CustomerContext { return this._customer; }

    public get tokenProvider(): AccessTokenProvider { return this._customer.tokenProvider; }

    public get customerId(): string { return this._customer.customerId; }

    public get licenseId(): string { return this._licenseId; }

    public get obsData(): IObservableOpt<ILicenseData> { return this._obsData; }

    public get licenseDataContainer(): IDataContainer<ILicenseData> { return this._licenseDataContainer; }

    public get licenseAdvertisementActivator(): IActivator<ILicenseAdvertisement> { return this._licenseAdvertisementActivator; }

    public get spotifyContext() : ISpotifyContext { return this._licenseSpotifyActivator; }

    public updateLicenseData(license: ILicenseData): void {
        this._obsData.emit(license);
        this._licenseDataContainer.setData(license);
    }
}
