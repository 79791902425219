import React from "react";
import { style } from "typestyle";
import { PbTheme } from "./PbTheme"
import { To, useNavigate } from "react-router-dom";

const buttonTextClassName = style({
    transition: PbTheme.transitions.all05x,
    fontSize: PbTheme.textSizes.sizeNormal,
    fontFamily: PbTheme.textSizes.family,
    color: "#F0F0F0",
    //backgroundColor: "rgba(200,200,200,0)",
    background: "none",
    borderRadius: "100px",
    border: "0",
    outline: "0",
    padding: 0,
    width: "30px",
    height: "30px",
    display: "inline-grid",
    alignItems: "center",
    justifyItems: "center",
    boxShadow: "0px 0px 0px #181818",
    margin: "4px 4px 4px 4px",
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: "rgba(200,200,200,0.1)",
            cursor: "pointer",
        },
        "&:active": {
            boxShadow: "0px 0px 0px #181818",
            margin: "6px 4px 2px 4px",
            backgroundColor: "rgba(200,200,200,0.3)",

        }
    }
});

type PbButtonIconProps = {
    onClick?: () => void;
    to?: To;
    disabled?: boolean;
    accentColor?: string;
}

export const PbButtonIcon = (props: React.PropsWithChildren<PbButtonIconProps>): JSX.Element | null => {
    const style = {
        backgroundColor: props.accentColor || "rgba(200,200,200,0)",
    };

    const navigate = useNavigate();

    function onClick(): void {
        if (props.onClick) {
            props.onClick();
        }

        if (props.to) {
            navigate(props.to);
        }
    }

    return (
        <button className={buttonTextClassName} style={style} type="button" disabled={props.disabled} onClick={() => onClick()}>
            {props.children}
        </button>
    );
}
