import React from 'react';
import WarningIcon from '@material-ui/icons/Warning';
import { IPresentationHeader } from '../../services/presentations/Interfaces';
import { PbHeader4, PbParagraph } from '.';
import { PbTheme } from './PbTheme';
import { PureComponentWithLifetime } from '../../libs/react';
import { style } from 'typestyle';
import { TextStrings } from '../../lang/TextStrings';

const listStyle = style({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(235px, 1fr))"
});

const presentationHeaderStyle = style({
    margin: "5px",
    overflow: "hidden",
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    cursor: "pointer"
});

const fixedAspectRatioDiv = style({
    zIndex: PbTheme.content.zIndex,
    position: "relative",
    paddingTop: "56.25%",
    width: "100%"
});

const backgroundStyle = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    alignItems: "center"
});

const detailOverlayStyle = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    display: "grid",

    gridTemplateRows: "0.7fr 0.3fr",
});

const iconsOverlayStyle = style({
    position: "absolute",
    top: "0",
    right: "0",
    padding: "5px",
    display: "grid",
    gridTemplateRows: "min-content",
    gridAutoFlow: "column",
    gridAutoColumns: "min-content"
});

const presentationTitleOverlayStyle = style({
    gridRow: "2",
    gridColumn: "1",
    display: "grid",
    gridTemplateRows: "1fr",
    backgroundColor: "rgba(60,60,60,0.6)",
    alignItems: "center",
    padding: "0 5px"
});

const thumpnailStyle = style({
    gridRow: "1 / span 2",
    maxWidth: "100%",
    maxHeight: "100%",
});

interface IProps {
    presentations: IPresentationHeader[];
    onClick?: (presentation: IPresentationHeader) => void
}

export class PbPresentationList extends PureComponentWithLifetime<IProps>{
    public render(): JSX.Element {
        return (
            <div className={listStyle}>
                {this.props.presentations.map((header) => <div className={presentationHeaderStyle} key={header.presentationId} onClick={() => { this.itemClicked(header) }}>
                        <div className={fixedAspectRatioDiv}>
                            <div className={backgroundStyle}>
                                <img alt={`thumbnail of ${header.name}`} className={thumpnailStyle} src={header.thumbnail} />
                            </div>
                            <div className={detailOverlayStyle}>
                                <div className={presentationTitleOverlayStyle}>
                                    <PbHeader4>{header.name}</PbHeader4>
                                </div>
                            </div>
                            <div className={iconsOverlayStyle}>
                                {
                                    header.expireDate && header.expireDate < new Date() &&
                                    <div title={TextStrings.textMyPresentationsExpireWarning}>
                                        <WarningIcon htmlColor="rgb(255,148,0)" />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )}
                {this.props.presentations.length === 0 && <PbParagraph>{TextStrings.textMyPresentationsNoItems}</PbParagraph>}
            </div>
        );
    }

    private itemClicked(presentation: IPresentationHeader) {
        if (this.props.onClick) {
            this.props.onClick(presentation);
        }
    }
}