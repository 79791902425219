import React from "react";
import { style, classes } from "typestyle";
import { PbButtonFab } from ".";
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { useObservable } from "../../libs/react";
import scrollService from "../../services/scroll/ScrollService";

const buttonStyle = style({
    display: "grid",
    position: "fixed",
    zIndex: 110,
    right: "5px",
    bottom: "5px",
    transition: "right 0.3s",
    transitionTimingFunction: "cubic-bezier(0.68, 0.76, 0.29, 1.42)"
});

const buttonHiddenStyle = style({
    transitionTimingFunction: "cubic-bezier(0.71,-0.6, 0.39, 0.54)",
    right: "-60px",
});

type IProps = React.ButtonHTMLAttributes<HTMLButtonElement>

export function PbScrollToTopButton(props: IProps): JSX.Element {
    const visible = useObservable(scrollService.obsCanScrolUp)

    return (
        <div className={visible ? buttonStyle : classes(buttonStyle, buttonHiddenStyle)}>
            <PbButtonFab onClick={() => scrollService.scheduleScrollToPosition(0, true, true)} >
                <ArrowUpwardIcon />
            </PbButtonFab>
        </div>
    );
}
