import { AdvertisementLicensesApi } from '../../apis/AdvertisementLicensesApi';
import { ExecuteErrorApi, ServiceActivator, ServiceOk } from '../../libs/activators';
import { IAutomatPresentationHeader, ILicenseAdvertisementData } from './Interfaces';
import { LicenseContext } from './LicenseContext';
import { LicenseAdvertisement } from './LicenseAdvertisement';


export class LicenseAdvertisementActivator extends ServiceActivator<LicenseAdvertisement> {
    constructor(license: LicenseContext) {
        const api = new AdvertisementLicensesApi(license.tokenProvider, license.customer.deviceContext._configService);
        super(async() => {
            const apiResult = await api.getLicenseAsync(license.licenseId);
            if (apiResult.resultType === "error") {
                return new ExecuteErrorApi(apiResult);
            }
            const presentations: IAutomatPresentationHeader[] = apiResult.data.presentations.map((p)=>({
                presentationId: p.presentationIdentity,
                name: p.name,
                thumbnail: p.thumbnail,
                expireDate: undefined,
                displayFactor: p.displayFactor
            }));

            const data: ILicenseAdvertisementData = {
                automatPresentations: presentations,
            };

            return new ServiceOk(new LicenseAdvertisement(this, data, api, license));
        });
    }
}
