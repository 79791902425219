import React from 'react';
import { BreadCrumbItem, PbScrollToTopButton, PbTheme } from '../../components/pd_themed';
import { PbBlockController } from './blocks/BlockController';
import { PbBlockCustomers } from './customer/PbBlockCustomers';
import { style } from 'typestyle';
import { Outlet } from 'react-router-dom';
import { HomePageDialogsRegistrations } from './HomePageDialogsRegistrations';
import { PbBlockMenu } from './customer/menu/PbBlockMenu';
import { PbBlockLogo } from './blocks/BlockLogo';
import { OverlayContainerProvider } from '../../components/overlay';

const homeContainerStyle = style({
    overflow: "auto",
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "min-content",
    gridTemplateColumns: "minmax(auto, 500px ) minmax(auto, 500px )",
    justifyContent: "center",
    gridGap: "10px",
    padding: "10px 20px 0 20px",
    $nest: {
        [PbTheme.layoutPortraitMediaQuery]: {
            gridTemplateColumns: "1fr",
        }
    }
});

export function HomePageRoute(): JSX.Element {
    return (
        <OverlayContainerProvider>
            <div className={homeContainerStyle}>
                <BreadCrumbItem title="Home" />
                <PbBlockLogo />
                <PbBlockController />
                <PbBlockCustomers />
                <BreadCrumbItem title={"Menu"} />
                <PbBlockMenu />
                <Outlet />
                <PbScrollToTopButton />
                <HomePageDialogsRegistrations />
            </div>
        </OverlayContainerProvider>
    );
}
