import { FetchResultContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';

export class DeviceApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(configService: IConfigService){
        this._configService = configService;
        this._fetchService = new FetchService("same-origin");
    }

    public getSecretAsync(publicKey: string): Promise<FetchResultContent<IApiGetDeviceSecretResult>> {
        const uri = this._configService.config.myPbSoundApiUrl + "/getsecret";
        return this._fetchService.fetchPostWithResultAsync<IApiGetDeviceSecretResult>(uri, {publicKey});
    }

    public getDeviceTokenAsync(request: IApiGetDeviceTokenRequest): Promise<FetchResultContent<IApiGetDeviceTokenResult>> {
        const uri = this._configService.config.myPbSoundApiUrl + "/getdevicetoken";
        return this._fetchService.fetchPostWithResultAsync<IApiGetDeviceTokenResult>(uri, request);
    }

    public async getAccessTokenAsync(deviceToken:string, data: IApiGetAccessTokenRequest): Promise<FetchResultContent<IApiGetAccessTokenResult>> {
        const uri = this._configService.config.myPbSoundApiUrl + "/getaccesstoken2";
        return this._fetchService.fetchPostWithResultAsync<IApiGetAccessTokenResult>(uri, data, (headers)=>headers.set("recordcasetoken", deviceToken));
    }
}

// GetSecret
interface IApiGetDeviceSecretResult {
    secret: string;
    deviceNumber: number;
    validUntil: string;
}


// GetToken
interface IApiGetDeviceTokenRequest {
    publicKey: string;
    signature: string;
}

interface IApiGetDeviceTokenResult {
    deviceNumber: number;
    deviceToken: string;
    tokenExpiringDateTime: string;
}

// GetAccessToken
interface IApiGetAccessTokenRequest {
    customerId?: string;
}

type TokenType = "User" | "Computer" | "LocalUser" | "Device";
export interface IApiGetAccessTokenResult {
    canEnterConnectCode: boolean;
    computerId: string;
    computerSerialNumber: string;
    customerId: string;
    customerName: string;
    displayName: string;
    minutesValid: number;
    token: string;
    userId: string;
    userName: string;
    validUntilUtc: string;
    tokenType: TokenType;
    hasAccess: boolean;
}