import { DeviceContext } from '../device/DeviceContext';
import { ICustomerContext, ICustomersContextService } from './Interfaces';
import { IObservable, ObservableDef } from '../../libs/observables';
import { CustomerContext } from './CustomerContext';

export class CustomerContextService implements ICustomersContextService {
    private readonly _obsSelectedCustomerContext = new ObservableDef<ICustomerContext | undefined>(undefined);
    private readonly _deviceContext: DeviceContext;
    private readonly _contextMap = new Map<string, CustomerContext>();

    constructor(deviceContext: DeviceContext) {
        this._deviceContext = deviceContext;
    }

    public get obsSelectedCustomerContext(): IObservable<ICustomerContext | undefined> { return this._obsSelectedCustomerContext }

    public get selectedCustomerId(): string | undefined { return this._obsSelectedCustomerContext.value?.customerId }

    public get deviceContext(): DeviceContext { return this._deviceContext }

    public clear(): void {
        this._obsSelectedCustomerContext.emit(undefined);
    }

    public select(customerId: string): ICustomerContext {
        const customerContext = this.getCustomerContext(customerId)

        this._obsSelectedCustomerContext.emit(customerContext);
        return customerContext;
    }

    private getCustomerContext(customerId: string): CustomerContext {
        let context = this._contextMap.get(customerId);
        if (!context) {
            context = new CustomerContext(this._deviceContext, customerId);
            this._contextMap.set(customerId, context);
        }

        return context;
    }
}
