import React, { useContext } from 'react';
import { PbBlockContainer, PbHeader2, PbParagraph, RouterListButton } from '../../../../../components/pd_themed';
import { PlayerList } from './PlayerList';
import { style } from 'typestyle';
import { TextStrings } from '../../../../../lang/TextStrings';
import { useDeviceContext } from '../../../../main/AppLayout';
import { useCustomerContext } from '../../UseCustomer';
import { ModelStateComponent } from '../../../../../components/datawaiting';

const containerStyle = style({
    display: "grid",
    gridTemplateRows: "min-content 1fr",
    gridRowGap: "10px",
    overflow: "hidden"
});

const listStyle = style({
    overflow: "auto",
    display: "grid",
    gridTemplateColumns: "1fr",
    gridAutoFlow: "row",
    gridAutoRows: "min-content"
});

export function NarrowCastingBlock(): JSX.Element | null {
    const deviceContext = useDeviceContext();
    const customerContext = useCustomerContext();

    return deviceContext && customerContext ? (
        <>
            <PbBlockContainer>
                <div className={containerStyle} >
                    <PbHeader2>{TextStrings.textNarrowCastingBlockTitle}</PbHeader2>
                    <PbParagraph>{TextStrings.textMyContentDescription}</PbParagraph>
                    <ModelStateComponent obsModelState={customerContext.obsLicensesModelState} content={(licensesModel) => (
                        <PlayerList customerContext={customerContext} deviceContext={deviceContext} licensesModel={licensesModel} /> //menuTopic="automat" />
                    )} />
                    <div className={listStyle}>
                        <RouterListButton to="media" text={TextStrings.textMyMedia} />
                        <RouterListButton to="presentations" text={TextStrings.textMyPresentations} />
                    </div>
                </div>
            </PbBlockContainer>
        </>
    ) : null;
}
