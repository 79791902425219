import idGenerator from "../../libs/utils/IdGenerator";
import { IPresentation, IPresentationData, ISlide, PresentationState } from "./Interfaces";
import { IObservableDef, ObservableDef } from "../../libs/observables";
import { PresentationApi, IApiPresentation } from "../../apis/PresentationApi";
import { Slide } from "./Slide";
import { PresentationService } from "./PresentationService";
import { IElement, IElementService } from "../elements/Interfaces";
import { PresentationActivator } from "./PresentationActivator";
import { ExecuteError, ExecuteOk, ExecuteErrorApi } from "../../libs/activators";
import { DateTimeConversions } from "../../libs/utils/DateTimeConversions";

export class Presentation implements IPresentation {
    private readonly _activator: PresentationActivator;
    private _presentationService: PresentationService;
    private _presentationId: string;
    private _obsData: ObservableDef<IPresentationData>;
    private _obsState = new ObservableDef<PresentationState>({ state: "data" });
    private _obsSlides: ObservableDef<Slide[]>;
    private _presentationApi: PresentationApi;
    private _newThumbnail: IElement | null;
    private _isNew: boolean;

    constructor(
        activator: PresentationActivator,
        presentationsContext: PresentationService,
        presentationApi: PresentationApi,
        presentationId: string,
        elementService: IElementService,
        presentationApiData: IApiPresentation) {
        this._activator = activator;
        this._presentationService = presentationsContext;
        this._presentationApi = presentationApi;
        this._presentationId = presentationId;
        this._obsSlides = new ObservableDef<Slide[]>([]);
        this._newThumbnail = null;

        this._isNew = idGenerator.isTempId(presentationId);
        if (this._isNew) {
            this._obsState.emit({ state: "edit", changed: true });
        }

        this._obsData = new ObservableDef<IPresentationData>({
            presentationId: presentationApiData.presentationId,
            name: presentationApiData.name,
            expireDateTime: DateTimeConversions.parseDateFromString(presentationApiData.presentationExpiredDateTime),
            defaultSlideDurationInSeconds: presentationApiData.defaultSlideDurationInSeconds
        });

        elementService.updateElementsFromSlides(presentationApiData.elements);

        this._obsSlides.emit(presentationApiData.slides.map((s) => {
            const element = elementService.getElement(s.elementId);
            return new Slide(s.slideId.toString(), element);
        }));
    }

    public get presentationId(): string { return this._presentationId; }
    public get obsData(): IObservableDef<IPresentationData> { return this._obsData; }
    public get obsState(): IObservableDef<PresentationState> { return this._obsState; }
    public get obsSlides(): IObservableDef<ISlide[]> { return this._obsSlides; }

    public refresh(): void {
        this._activator.recreateService();
    }

    public async setEditModeAsync(): Promise<void> {
        this._activator.recreateService();
        const service = await this._activator.getAsync();
        if (service) {
            service._obsState.emit({ state: "edit", changed: false });
        }
    }

    public async cancelEditModeAsync(): Promise<void> {
        this._activator.recreateService();
    }

    public async saveSlidesAsync(): Promise<void> {
        await this._activator.executeAsync(async() => {
            const state = this._obsState.value;
            if (state.state === "edit") {
                const apiResult = await this._presentationApi.updatePresentationAsync({
                    presentationId: this._presentationId,
                    thumbnailElementId: this._newThumbnail ? this._newThumbnail.elementId : undefined,
                    slides: this._obsSlides.value.map((s) => (
                        idGenerator.isTempId(s.slideId)
                            ? {
                                elementId: s.element.elementId,
                            }
                            : {
                                slideId: s.slideId,
                            })),
                });

                if (apiResult.resultType === "error") {
                    return new ExecuteErrorApi(apiResult);
                }

                if (this._newThumbnail && this._newThumbnail.obsData.value) {
                    const pictureUrl = this._newThumbnail.obsData.value.thumbnailUrl;
                    this._presentationService.updateHeaderThumbnail(this._presentationId, pictureUrl);
                }

                return new ExecuteOk(true);
            }

            return new ExecuteError("Data was not changed");
        });
    }


    public async updateNameAsync(name: string): Promise<void> {
        await this._activator.executeAsync(async() => {
            const result = await this._presentationApi.updatePresentationAsync({
                presentationId: this._presentationId,
                name: name,
            });

            if (result.resultType === "ok") {
                this._obsData.emit({ ...this._obsData.value, name: name });
                this._presentationService.updateHeader(this._presentationId, this._obsData.value.name, this._obsData.value.expireDateTime);
            }

            return new ExecuteOk();
        });
    }

    public async updateExpireDateTimeAsync(date: Date | undefined): Promise<void> {
        await this._activator.executeAsync(async() => {
            const result = await this._presentationApi.updatePresentationAsync({
                presentationId: this._presentationId,
                expire: { dateTime: date ? date.toISOString() : null }
            });

            if (result.resultType === "ok") {
                this._obsData.emit({ ...this._obsData.value, expireDateTime: date });
                this._presentationService.updateHeader(this._presentationId, this._obsData.value.name, this._obsData.value.expireDateTime);
            }

            return new ExecuteOk();
        });
    }

    public async updateSlideDurationAsync(duration:number): Promise<void> {
        await this._activator.executeAsync(async() => {
            const result = await this._presentationApi.updatePresentationAsync({
                presentationId: this._presentationId,
                defaultSlideDurationInSeconds: duration
            });

            if (result.resultType === "ok") {
                this._obsData.emit({ ...this._obsData.value, defaultSlideDurationInSeconds: duration });
                this._presentationService.updateHeader(this._presentationId, this._obsData.value.name, this._obsData.value.expireDateTime);
            }

            return new ExecuteOk();
        });
    }

    public async deletePresentationAsync(): Promise<void> {
        await this._activator.executeAsync(async() => {
            const result = await this._presentationApi.deletePresentationAsync(this._presentationId);

            if (result.resultType === "ok") {
                this._presentationService.deleteHeader(this._presentationId);
            }

            return new ExecuteOk();
        });
    }

    public insertElementsAtIndex(elements: IElement[], index: number): void {
        if (this._obsState.value.state === "edit") {
            const newSlides = elements.map((p) => new Slide(idGenerator.getTempId(), p));
            const oldSlides = this._obsSlides.value;
            const firstSlides = oldSlides.slice(0, index);
            const lastSlides = oldSlides.slice(index);
            const slides = [...firstSlides, ...newSlides, ...lastSlides];
            this._obsSlides.emit(slides);
            this.setChanged();
        }
    }

    public deleteSlideAtIndex(index: number): void {
        if (this._obsState.value.state === "edit") {
            const slides = this._obsSlides.value.filter((s, i) => i !== index);
            this._obsSlides.emit(slides);
            this.setChanged();
        }
    }

    public setslideAsThumbnail(thumbnail: IElement): void {
        this._newThumbnail = thumbnail;
    }

    private setChanged() {
        if (this._obsState.value.state === "edit") {
            this._obsState.emit({ state: "edit", changed: true });
        }
    }
}
