import { CustomerContext } from '../customers/CustomerContext';
import { PresentationApi } from '../../apis/PresentationApi';
import { PresentationTopicService } from './PresentationTopicService';
import { ServiceActivator, ServiceErrorApi, ServiceOk } from '../../libs/activators';

export class PresentationTopicServiceActivator extends ServiceActivator<PresentationTopicService>{
    public constructor(customer: CustomerContext) {
        const api = new PresentationApi(customer.tokenProvider, customer.deviceContext._configService);

        super(async() => {
            const apiResult = await api.getPresentationTopicsAsync();
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const service = new PresentationTopicService(customer, this, api, apiResult.data);
            return new ServiceOk(service);
        });
    }
}