import React from "react";
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import { style } from "typestyle";
import { PbButtonFab, PbButtonIcon, PbHeader3, PbTheme } from "../../../components/pd_themed";
import { ObsDataComponent, PureComponentWithLifetime } from "../../../libs/react";
import { ICustomerContext } from "../../../services/customers/Interfaces";
import { IPresentation, ISlide } from "../../../services/presentations/Interfaces";

const listSwitchMediaTag = "@media only screen and (max-width: 460px)";


const slidesContainer = style({
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    padding: "15px",
    borderRadius: "10px",
});

const slidesEditButtons = style({
    display: "grid",
    gridAutoFlow: "column",
    gridAutoColumns: "min-content",
    gridTemplateRows: "auto",
    alignItems: "center"
});

const listStyle = style({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
    gridColumnGap: "10px",
    gridRowGap: "10px",
    height: "calc(100vh - 400px)",
    width: "calc(100vw - 120px)",
    overflowY: "auto",
    $nest: {
        [listSwitchMediaTag]: {
            gridTemplateColumns: "1fr",
        }
    }
});

const listItemStyle = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
});

const listItemEditModeStyle = style({
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "min-content 1fr",
    gridColumnGap: "10px",
    gridRowGap: "10px",
    $nest: {
        [listSwitchMediaTag]: {
            gridTemplateRows: "min-content 1fr",
            gridTemplateColumns: "1fr",
        }
    }

});

const addButtonContainer = style({
    display: "grid",
    alignItems: "center",
    justifyItems: "center",
    border: "1px solid gray",
    backgroundColor: "#666",
    width: "40px",
    boxShadow: "0px 4px 4px #181818",
    margin: "2px 0 2px 0",
    transition: PbTheme.transitions.all05x,
    $nest: {
        "&:hover": {
            boxShadow: "0px 4px 4px #181818",
            backgroundColor: "#696969",
        },
        "&:active": {
            boxShadow: "0px 0px 0px #282828",
            margin: "4px 0 0px 0",
            backgroundColor: "#777",
        },
        [listSwitchMediaTag]: {
            width: "100%"
        }
    }
});

const fixedAspectRatioDiv = style({
    backgroundColor: PbTheme.colors.backgroundBlockLv4,
    position: "relative",
    zIndex: PbTheme.content.zIndex,
    paddingTop: "56.25%",
    width: "100%"
});

const imageBox = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "1fr",
    justifyItems: "center",
    alignItems: "center",
    height: "100%"
});

const imageButtonsBox = style({
    position: "absolute",
    top: "0",
    right: "0",
    bottom: "0",
    left: "0",
    overflow: "hidden",
    display: "grid",
    gridTemplateRows: "1fr",
    gridTemplateColumns: "auto auto",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%"
});

const thumpnailStyle = style({
    gridRow: "1 / span 2",
    maxWidth: "100%",
    maxHeight: "100%",
});

const deleteButtonStyle = style({
    transition: PbTheme.transitions.all05x,
    backgroundColor: "rgba(255,30,30,0.3)",
    color: "red",
    $nest: {
        "&:hover": {
            backgroundColor: "rgba(255,30,30,0.6)",
            color: "white",
        },
        "&:active": {
            color: "white",
            backgroundColor: "rgba(255,50,50,0.6)",
        },
    }
});
const infoButtonStyle = style({
    transition: PbTheme.transitions.all05x,
    backgroundColor: "rgba(255,148,0,0.3)",
    color: "white",
    $nest: {
        "&:hover": {
            backgroundColor: "rgba(255,148,0,0.6)",
            color: "white",
        },
        "&:active": {
            color: "white",
            backgroundColor: "rgba(255,198,50,0.6)",
        },
    }
});

interface IProps {
    presentation: IPresentation;
    customer: ICustomerContext;
}

interface IState {
    slides: ISlide[];
    editSlides: boolean;
}

export class SlidesEditor extends PureComponentWithLifetime<IProps, IState>{
    constructor(props: IProps) {
        super(props);
        this.state = {
            slides: [],
            editSlides: false,
        };

        this.props.presentation.obsSlides.subscribeInitial(this, (slides) => {
            this.setState({
                slides: slides
            });
        });
        this.props.presentation.obsState.subscribeInitial(this, (state) => {
            this.setState({
                editSlides: state.state === "edit"
            });
        })
    }

    public render(): JSX.Element {
        return (
            <>
                <div className={slidesContainer}>
                    <div className={slidesEditButtons}>
                        <PbHeader3>Slides</PbHeader3>
                        {!this.state.editSlides ? <PbButtonIcon onClick={() => this.editSlides()}><EditIcon /></PbButtonIcon> : null}
                        {this.state.editSlides ? <PbButtonIcon onClick={() => this.cancelEdit()}><CloseIcon /></PbButtonIcon> : null}
                        {this.state.editSlides ? <PbButtonIcon onClick={() => this.saveEdit()} accentColor={PbTheme.colors.primary}><DoneIcon /></PbButtonIcon> : null}

                    </div>
                    <div className={listStyle}>
                        {this.state.slides.map((slide, index) => (
                            <div key={slide.slideId} className={this.state.editSlides ? listItemEditModeStyle : listItemStyle}>
                                {this.state.editSlides ?
                                    <div className={addButtonContainer} onClick={() => this.addSlideOnIndex(index)}>
                                        <AddIcon />
                                    </div>
                                    : null
                                }
                                <div key={slide.slideId} className={fixedAspectRatioDiv}>
                                    <div className={imageBox}>
                                        <ObsDataComponent obsData={slide.element.obsData} content={(element) => <img src={element.thumbnailUrl} alt={element.name} className={thumpnailStyle} />
                                        } />
                                    </div>
                                    {this.state.editSlides ? <div className={imageButtonsBox}>
                                        <PbButtonFab onClick={() => this.removeSlideOnIndex(index)} className={deleteButtonStyle} ><DeleteIcon /></PbButtonFab>
                                        <PbButtonFab onClick={() => this.showSlideSettingsDialog(index)} className={infoButtonStyle} ><InfoIcon /></PbButtonFab>
                                    </div> : null}
                                </div>
                            </div>
                        ))}
                        {this.state.editSlides ?
                            <div className={listItemEditModeStyle}>
                                <div className={addButtonContainer} onClick={() => this.addSlideOnIndex(this.state.slides.length)}>
                                    <AddIcon />
                                </div>
                            </div>
                            : null
                        }
                    </div>
                </div>
            </>
        );
    }

    private editSlides() {
        this.props.presentation.setEditModeAsync();
    }


    private cancelEdit() {
        this.props.presentation.cancelEditModeAsync();
    }

    private saveEdit() {
        this.props.presentation.saveSlidesAsync();
    }

    private async addSlideOnIndex(index: number) {
        const elementsResult = await this.props.customer.deviceContext.dialogContext.selectElementsDialogExecutor.executeAsync({ customerContext: this.props.customer });
        if (elementsResult.result) {
            this.props.presentation.insertElementsAtIndex(elementsResult.output.elements, index);
        }
    }

    private removeSlideOnIndex(index: number) {
        this.props.presentation.deleteSlideAtIndex(index);
    }

    private async showSlideSettingsDialog(index: number) {
        const slide = this.state.slides[index];
        this.props.customer.deviceContext.dialogContext.slideSettingsDialogExecutor.executeAsync({
            presentation: this.props.presentation,
            slide,
        });
    }
}