import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import { ICustomerContext } from '../../../../services/customers/Interfaces';
import { IDeviceContext } from '../../../../services/device/Interfaces';
import { IElement, IElementListEditService } from '../../../../services/elements/Interfaces';
import { MediaTile } from './MediaTile';
import { PbButton, PbParagraph, PbTheme } from '../../../../components/pd_themed';
import { useObservable } from '../../../../libs/react';
import { style } from 'typestyle';
import { TextStrings } from '../../../../lang/TextStrings';

const listStyle = style({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))"
});

const tileStyle = style({
    margin: "5px",
    overflow: "hidden",
    backgroundColor: PbTheme.colors.backgroundBlockLv2
});

const buttonStyle = style({
    display: "grid",
    gridTemplateColumns: "auto min-content",
    gridTemplateRows: "1fr",
    alignItems: "center"
});

const editButtonsContainer = style({
    display: "grid",
    gridTemplateColumns: "auto auto",
    gridTemplateRows: "1fr",
    columnGap: "5px",
});


interface IProps {
    deviceContext: IDeviceContext;
    customer: ICustomerContext;
    elementService: IElementListEditService;
}

export function MediaList(props: IProps): JSX.Element {
    const elements = useObservable(props.elementService.obsElements);
    const isInEditMode = useObservable(props.elementService.obsEditModeActive) ?? false;

    return (
        <>
            {isInEditMode ? renderInEditButtons() : renderStartEditButton()}
            <div className={listStyle}>
                {elements?.map((element) => (
                    <div className={tileStyle} key={element.elementId} >
                        <MediaTile element={element} key={element.elementId} onDeleteClicked={() => removeMedia(element)} showEditButtons={isInEditMode} />
                    </div>
                ))}
                {(!elements || elements.length === 0) && <PbParagraph>{TextStrings.textMyMediaNoItems}</PbParagraph>}
            </div>
        </>
    );


    function renderStartEditButton() {
        return (
            <PbButton isSecondary className={buttonStyle} onClick={() => switchToEditMode()}>
                {TextStrings.textMyMediaEditListAction}<EditIcon />
            </PbButton>
        );
    }

    function renderInEditButtons() {
        return (
            <div className={editButtonsContainer}>
                <PbButton isSecondary onClick={() => cancelEdit()}>
                    {TextStrings.textCancelAction}
                </PbButton>
                <PbButton className={buttonStyle} onClick={() => commitEdit()}>
                    {TextStrings.textSaveAction}<EditIcon />
                </PbButton>
            </div>
        );
    }

    function switchToEditMode(): void {
        props.elementService.startEdit();
    }

    function removeMedia(item: IElement): void {
        props.elementService.removeFromList(item);
    }

    function cancelEdit(): void {
        props.elementService.cancelEdit();
    }

    function commitEdit(): void {
        props.elementService.commitEditAsync();
    }
}