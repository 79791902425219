import React, { useMemo } from "react";
import { style } from "typestyle";
import { ActivatorComponent } from "../../../components/datawaiting/ActivatorComponent";
import { withPbDialog, WithPbDialogProps } from "../../../components/overlay";
import { PbButton, PbHeader2, PbTheme } from "../../../components/pd_themed";
import { TextStrings } from "../../../lang/TextStrings";
import { ObsDataComponent } from "../../../libs/react";
import { DialogExecutor } from "../../../libs/react/DialogExecutor";
import { ICustomerContext } from "../../../services/customers/Interfaces";
import { IElement } from "../../../services/elements/Interfaces";
import { TopicList } from "./TopicList"
import { IElementTopicHeader } from "../../../services/element_topics/Interfaces";
import { SelectMediaFromTopicDialogExecutor, SelectMediaFromTopicDialog } from "./SelectMediaFromTopicDialog";

const dialogContainer = style({
    height: "100%",
    display: "grid",
    gridTemplateRows: "min-content auto min-content",
    gridTemplateColumns: "minmax(90%, 1000px)",
    gridRowGap: "10px",
    overflow: "hidden",
});

const scrollContainer = style({
    overflowX: "hidden",
    overflowY: "auto",
    backgroundColor: PbTheme.colors.backgroundBlockLv3,
    display: "grid",
    gridTemplateColumns: "1fr",
    gridTemplateRows: "1fr",
    padding: "5px",
    gridColumnGap: "10px",
    gridRowGap: "10px",
});

interface IInput {
    customerContext: ICustomerContext;
}

interface IOutput {
    elements: IElement[];
}

export class SelectTopicDialogExecutor extends DialogExecutor<IInput, IOutput> { }

function SelectTopicDialogForm(props: WithPbDialogProps<IInput, IOutput>): JSX.Element | null {
    const selectMediaFromTopicDialogExecutor = useMemo(() => new SelectMediaFromTopicDialogExecutor(), []);

    return (
        <>
            <div className={dialogContainer}>
                <PbHeader2>{TextStrings.textEditPresentationSelectMedia}</PbHeader2>
                <div className={scrollContainer}>
                    <ActivatorComponent activator={props.dialogModel.input.customerContext.elementTopicServiceActivator} content={(topicService) => (
                        <ObsDataComponent obsData={topicService.obsTopics} content={(topics) => (
                            <TopicList onTopicSelect={topic => topicSelectionChanged(topic)} topics={topics} />
                        )} />
                    )} />
                </div>
                <PbButton onClick={() => closeDialog()}>{TextStrings.textCloseAction}</PbButton>
            </div>
            <SelectMediaFromTopicDialog obsDialogModel={selectMediaFromTopicDialogExecutor.obsDialogModel} />
        </>
    );

    function closeDialog(): void {
        props.dialogModel.close();
    }

    async function topicSelectionChanged(selectedTopic: IElementTopicHeader) {
        const elementsResult = await selectMediaFromTopicDialogExecutor.executeAsync({
            customerContext: props.dialogModel.input.customerContext,
            topic: selectedTopic
        });

        if (elementsResult.result) {
            props.dialogModel.resolve({elements: elementsResult.output.elements});
        } else {
            props.dialogModel.close();
        }
    }
}

export const SelectTopicDialog = withPbDialog<IInput, IOutput>(SelectTopicDialogForm);
