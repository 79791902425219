import React from "react";
import { classes, style } from "typestyle";
import { PbTheme, PbHeader4 } from ".";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CheckIcon from '@material-ui/icons/Check';
import { To, useLocation, useNavigate } from "react-router-dom";

const listItemStyle = style({
    display: "grid",
    gridTemplateColumns: "1fr min-content",
    gridTemplateRows: "min-content",
    padding: "10px",
    margin: "2px 0",
    backgroundColor: PbTheme.colors.backgroundBlockLv2,
    transition: PbTheme.transitions.all1x,
    alignItems: "center",
    overflow: "hidden",
    cursor: "pointer",
    $nest: {
        '&:hover': {
            backgroundColor: PbTheme.colors.backgroundBlockLv4,
        }
    }
});

const listItemStyleActive = style({
    backgroundColor: PbTheme.colors.backgroundBlockLv4,
});

interface IProps {
    text: string;
    to?: To;
    onClick?: () => void;
    selected?: boolean;
}

export const RouterListButton = (props: IProps): JSX.Element => {
    const location = useLocation();
    const navigate = useNavigate();

    let className = listItemStyle;
    let selected = props.selected;
    if (selected === undefined && props.to && location.pathname.includes(props.to.toString())) {
        selected = true;
    }

    if (selected) {
        className = classes(listItemStyle, listItemStyleActive);
    }

    function onClick(): void {
        if (props.onClick) {
            props.onClick();
        }
        else {
            if (props.to) {
                navigate(props.to);
            }
        }
    }

    return (
        <div onClick={() => onClick()} className={className}>
            <PbHeader4>{props.text}</PbHeader4>
            {!selected && <ArrowForwardIcon htmlColor={PbTheme.colors.textNormal} />}
            {selected && <CheckIcon htmlColor={PbTheme.colors.textNormal} />}
        </div>
    );
}