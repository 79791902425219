import { ConnectCodeServiceActivator } from '../connectcode/ConnectCodeServiceActivator';
import { CustomerContextService } from '../customers/CustomerContextService';
import { ModelStateObservable } from '../../libs/activators';
import { IConfigService } from '../config/Interfaces';
import { IConnectCodeServiceActivator } from '../connectcode/Interfaces';
import { IAlertDialogInput, IDeviceContext, IDeviceData } from './Interfaces';
import { IDeviceTokenProvider } from '../token/Interfaces';
import { ILoginService } from '../login/Interfaces';
import { IObservable, ObservableOpt } from '../../libs/observables';
import { LifetimeContainer } from '../../libs/lifetime';
import { LoginLogic } from '../../pages/loggedout/LoginLogic';
import { LoginService } from '../login/LoginService';
import { CustomerSearchServiceActivator } from '../customersearch/CustomerSearchServiceActivator';
import { ICustomerSearchModel } from '../customersearch/Interfaces';
import { DeviceTokenProvider } from '../token/DeviceTokenProvider';
import { DialogExecutor, ObservableDialogModel } from '../../libs/react/DialogExecutor';
import { DialogContext } from '../dialogs/DialogContext';


export class DeviceContext implements IDeviceContext {
    private _lifeTimeContainer: LifetimeContainer = new LifetimeContainer();
    private _deviceTokenProvider: DeviceTokenProvider;
    private _obsDeviceData: ObservableOpt<IDeviceData> = new ObservableOpt();
    private readonly _customerListServiceActivator: CustomerSearchServiceActivator;
    private readonly _alertDialogExecutor = new DialogExecutor<IAlertDialogInput, void>();

    public readonly _configService: IConfigService;
    public readonly _loginLogic: LoginLogic;
    public readonly _loginService: ILoginService;
    public readonly _customers: CustomerContextService;
    public readonly _dialogContext: DialogContext;
    public get obsCustomerSearchModel(): ModelStateObservable<ICustomerSearchModel> { return this._customerListServiceActivator.obsModelState; }
    public get obsDeviceData(): IObservable<IDeviceData> { return this._obsDeviceData }

    constructor(
        configService: IConfigService,
        deviceTokenProvider: DeviceTokenProvider
    ) {
        this._deviceTokenProvider = deviceTokenProvider;
        this._configService = configService;

        this._dialogContext = new DialogContext();
        this._loginService = new LoginService(this);
        this._loginLogic = new LoginLogic(this._loginService);
        this._customers = new CustomerContextService(this);
        this._customerListServiceActivator = new CustomerSearchServiceActivator(this);
        this._deviceTokenProvider.obsToken.subscribeInitial(this._lifeTimeContainer, (token) => this.setDeviceData({
            deviceNr: token.deviceNumber,
            hasAccess: token.hasAccess,
            isUser: token.isUser,
            name: token.displayName,
            loginType: token.tokenType
        }));
    }

    public setDeviceData(data: IDeviceData): void {
        this._obsDeviceData.emit(data);
    }

    public getConnectCodeServiceActivator(): IConnectCodeServiceActivator {
        return new ConnectCodeServiceActivator(this);
    }

    public get dialogContext(): DialogContext { return this._dialogContext; }

    public get deviceTokenProvider(): DeviceTokenProvider { return this._deviceTokenProvider; }

    public get tokenProvider(): IDeviceTokenProvider { return this._deviceTokenProvider; }

    public get obsAlertDialogModel(): ObservableDialogModel<IAlertDialogInput, void> { return this._alertDialogExecutor.obsDialogModel; }

    setTokenProvider(tokenProvider: DeviceTokenProvider): void {
        this._deviceTokenProvider = tokenProvider;
    }

    public logout(): void {
        this._loginService.logout();
    }

    public refreshDevice(): void {
        this._deviceTokenProvider.invalidateToken();
        this._customerListServiceActivator.recreateService();
        this._customers.clear();
    }

    public async alertAsync(message: string, autoClose?: number | false): Promise<void> {
        await this._alertDialogExecutor.executeAsync({ message, autoClose: autoClose ?? 3000 });
    }
}