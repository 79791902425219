import { CustomerContext } from '../customers/CustomerContext';
import { LicensesApi } from '../../apis/LicensesApi';
import { ServiceErrorApi, ServiceOk, StoreServiceActivator } from '../../libs/activators';
import { ILicensesModel } from './Interfaces';
import { LicensesService } from './LicensesService';

export class LicensesActivator extends StoreServiceActivator<ILicensesModel>  {
    constructor(customer: CustomerContext) {
        const api = new LicensesApi(customer.tokenProvider, customer.deviceContext._configService);
        super(async() => {
            const apiResult = await api.getLicensesAsync();
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const service = new LicensesService(customer, apiResult.data.licenses);
            return new ServiceOk(service);
        });
    }
}
