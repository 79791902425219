import { CustomersApi } from '../../apis/CustomersApi';
import { DeviceContext } from '../device/DeviceContext';
import { ExecuteErrorApi, StoreServiceActivator, ServiceOk } from '../../libs/activators';
import { CustomerSearchService } from './CustomerSearchService';
import { ICustomerSearchModel } from './Interfaces';


export class CustomerSearchServiceActivator extends StoreServiceActivator<ICustomerSearchModel> {
    constructor(deviceContext: DeviceContext) {
        const api = new CustomersApi(deviceContext.tokenProvider, deviceContext._configService);
        super(async() => {
            const apiResult = await api.getCustomersAsync();
            if (apiResult.resultType === "error") {
                return new ExecuteErrorApi(apiResult);
            }

            const service = new CustomerSearchService(deviceContext, this, api, apiResult.data);

            return new ServiceOk(service);
        });
    }
}
