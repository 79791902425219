import { ApiStatusService } from '../../libs/apilib';
import { CredentialManagement } from './CredentialManagement';
import { DeviceContext } from '../device/DeviceContext';
import { IAccessToken, IDeviceTokenProvider } from '../token/Interfaces';
import { IErrorResult, ILoginService, ILoginState, IOkResult, LoginResult, SubscribeEmailResult } from './Interfaces';
import { IObservableDef, ObservableDef } from '../../libs/observables';
import { LifetimeContainer } from '../../libs/lifetime';
import { LoginApi } from '../../apis/LoginApi';
import { SecurityApi } from '../../apis/SecurityApi';
import { Utils } from '../../libs/utils';


export class LoginService implements ILoginService {
    private readonly _securityApi: SecurityApi;
    private readonly _apiStatusService = new ApiStatusService();
    private readonly _obsLoginState: ObservableDef<ILoginState>;
    private readonly _myPbSoundApi: LoginApi;
    private readonly _deviceTokenProvider: IDeviceTokenProvider;
    private readonly _deviceContext: DeviceContext;
    private readonly _lifetimeContainer: LifetimeContainer = new LifetimeContainer();
    private _initDone = false;

    constructor(deviceContext: DeviceContext) {
        this._deviceContext = deviceContext;
        this._securityApi = new SecurityApi(deviceContext._configService);
        this._myPbSoundApi = new LoginApi(deviceContext._configService);
        this._deviceTokenProvider = deviceContext.tokenProvider;

        this._obsLoginState = new ObservableDef<ILoginState>({
            canLogin: false,
            canSubscribe: false,
            canLogout: false,
            isBusy: false,
            error: undefined,
        });
        this._deviceTokenProvider.obsToken.subscribeInitial(this._lifetimeContainer, (token) => this.proccessAccesToken(token));
    }

    public get obsLoginState(): IObservableDef<ILoginState> { return this._obsLoginState; }

    public async subscribeEmailAsync(email: string): Promise<SubscribeEmailResult> {
        try {
            this._obsLoginState.emit({
                ...this._obsLoginState.value,
                isBusy: true,
            });

            const result = await this._securityApi.subscribeAsync({ email });
            if (result.resultType === "data") {
                this._obsLoginState.emit({
                    ...this._obsLoginState.value,
                    isBusy: false,
                    canSubscribe: false,
                });

                if (result.data.type === "subscribeOk") {
                    return {
                        type: "subscribed",
                        emailNumber: result.data.emailNumber,
                        emailDateTime: result.data.emailDateTime,
                    };
                } else if (result.data.type === "subscribeError") {
                    return {
                        type: "subscribeError",
                    };
                } else {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const _: never = result.data;
                }
            } else if (result.resultType === "error") {
                this._obsLoginState.emit({
                    ...this._obsLoginState.value,
                    isBusy: false,
                });
            }
        } catch {
            return {
                type: "subscribeError",
            };
        }

        return {
            type: "subscribeError",
        };
    }

    public async loginAsync(email: string, password: string, newPassword?: string): Promise<LoginResult> {
        // if (!this._obsLoginState.value.canLogin) {
        //     return {
        //         type: "serverError",
        //     };
        // }

        return await this.internalLoginAsync(email, password, newPassword);
    }

    public async logout(): Promise<void> {
        this.emitStateBusy();
        const token = await this._deviceTokenProvider.getTokenAsync();
        await this._myPbSoundApi.logoutAsync(token.token);

        await CredentialManagement.logoutAsync();
        this._deviceContext.refreshDevice();
        this.emitStateLoggedOut();
    }

    private async init(): Promise<void> {
        this._initDone = true;
        this.emitStateBusy();
        const urlParams = new URLSearchParams(window.location.search);
        const email = urlParams.get("email");
        const password = urlParams.get("password");
        const computertoken = urlParams.get("computertoken");

        if (email && password) {
            this.clearUrlParams();
            await Utils.delayAsync(500);
            await this._deviceContext._loginLogic.changePasswordAsync(email, password)
        } else if (computertoken) {
            this.clearUrlParams();
            this.internalComputerLoginAsync(computertoken);
        }
        this.emitStateLoggedOut();
    }

    private clearUrlParams() {
        const newUrl = window.location.origin + window.location.pathname;
        window.history.pushState({}, "start", newUrl);
    }

    private async internalComputerLoginAsync(computerCode: string): Promise<IOkResult | IErrorResult> {
        this.emitStateBusy();

        if (!computerCode) {
            return this.generateErrorResult("The given computer code is invalid");
        }
        const token = await this._deviceTokenProvider.getTokenAsync();
        const result = await this._myPbSoundApi.connectComputerAsync(token.token, { computerToken: computerCode });
        if (result.resultType === "error") {
            return this.generateErrorResult("Communication error: " + result.error);
        }
        this._deviceContext.refreshDevice();
        return {
            type: "ok",
        };
    }

    private generateErrorResult(error: string): IErrorResult {
        this.emitStateLoggedOut();

        return {
            type: "error",
            error,
        };
    }

    private async internalLoginAsync(email: string, password: string, newPassword?: string): Promise<LoginResult> {
        this.emitStateBusy();
        const token = await this._deviceTokenProvider.getTokenAsync();
        const result = await this._myPbSoundApi.loginAsync(token.token, { email, password, newPassword });
        if (result.resultType === "data") {
            const loginResult = result.data;
            switch (loginResult.type) {
                case "loginOk":
                    this._deviceContext.refreshDevice();
                    await CredentialManagement.storeAsync(email, newPassword || password);
                    return {
                        type: "ok",
                    };
                case "loginError":
                    this.emitStateLoggedOut();
                    if (loginResult.needNewPassword) {
                        return {
                            type: "loginAction",
                            mustChangePassword: loginResult.needNewPassword,
                            newPasswordToWeak: loginResult.passwordIsToWeak,
                        };
                    }
                    return {
                        type: "loginError",
                        accountIsLockedOut: loginResult.accountIsLockedOut,
                    };
            }
        }

        return {
            type: "serverError",
        };
    }

    private async proccessAccesToken(token: IAccessToken): Promise<void> {
        if (token.isUser) {
            this.emitStateLoggedIn();
        } else {
            this.emitStateLoggedOut();
        }

        if (!this._initDone) {
            this.init();
        }
    }

    private emitStateBusy(): void {
        this._obsLoginState.emit({
            isBusy: true,
            canLogin: false,
            canSubscribe: false,
            canLogout: false,
        });
    }

    private emitStateLoggedIn(): void {
        this._obsLoginState.emit({
            isBusy: false,
            canLogin: false,
            canSubscribe: false,
            canLogout: true,
        });
    }

    private emitStateLoggedOut(): void {
        this._obsLoginState.emit({
            isBusy: false,
            canLogin: true,
            canSubscribe: true,
            canLogout: false,
        });
    }

    private emitStateError(error: string): void {
        this._obsLoginState.emit({
            canLogin: true,
            canLogout: false,
            canSubscribe: true,
            isBusy: false,
            error
        });
    }
}
