import React, { FormEvent, useState } from "react";
import { Validator } from "../../libs/utils";
import { withPbDialog, WithPbDialogProps } from "../../components/overlay";
import { PbHeader2, PbParagraph, PbTextInput, PbButtonText, PbButtonSubmit } from "../../components/pd_themed";
import { TextStrings } from "../../lang/TextStrings";
import { style } from "typestyle";

const formInputs = style({
    display: "grid",
    gridAutoFlow: "row",
    gridAutoRows: "auto",
    gridTemplateColumns: "1fr",
    gridRowGap: "10px"
});

export interface IResetPasswordInput {
    email: string;
}

export interface IResetPasswordOutput {
    email: string;
}

export const ResetPasswordDialog = withPbDialog(
    (props: WithPbDialogProps<IResetPasswordInput, IResetPasswordOutput>): (JSX.Element | null) => {
        const [isValid, setIsValid] = useState<boolean>(Validator.validateEmail(props.dialogModel.input.email));
        const [email, setEmail] = useState<string>(props.dialogModel.input.email);

        return (
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
                <PbHeader2 id="form-dialog-title">
                    {TextStrings.textResetPassword}
                </PbHeader2>
                <div className={formInputs}>
                    <PbParagraph>
                        {TextStrings.textResetPasswordInformation}
                    </PbParagraph>

                    <PbTextInput
                        id="email-input"
                        placeholder="Email"
                        // className={classes.textField}
                        type="email"
                        name="email"
                        autoComplete="email"
                        value={email}
                        onTextChange={(text) => handleEmailChange(text)}
                        autoFocus
                    />


                    <PbButtonSubmit type="submit" disabled={!isValid} text={TextStrings.textSendEmailAction} />
                    <PbButtonText onClick={handleClose} >
                        {TextStrings.textCloseAction}
                    </PbButtonText>
                </div>
            </form>
        );


        function handleClose(): void {
            props.dialogModel.close();
        }

        function onSubmit(event: FormEvent<HTMLFormElement>): void {
            event.preventDefault();
            if (isValid) {
                props.dialogModel.resolve({
                    email,
                });
            }
        }

        function handleEmailChange(text: string): void {
            const email = text.trim();
            setEmail(email);
            setIsValid(Validator.validateEmail(email));
        }
    }
);
