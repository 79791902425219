import { IScrollService } from "./Interfaces";
import { ObservableDef, IObservable } from "../../libs/observables";

class ScrollService implements IScrollService {
    private scheduledScrollTo: string | number = "unset";
    private smoothScroll = false;
    private timerId = -1;
    private _obsCanScrolUp: ObservableDef<boolean> = new ObservableDef<boolean>(false);
    public get obsCanScrolUp(): IObservable<boolean> { return this._obsCanScrolUp }

    constructor() {
        window.addEventListener("scroll", () => {
            if (document.scrollingElement && document.scrollingElement.scrollTop !== 0) {
                this._obsCanScrolUp.emitOnChanged(true);
            } else {
                this._obsCanScrolUp.emitOnChanged(false);
            }
        });
    }

    scheduleScrollTo(containerId: string, smoothScroll = true, dontWait = false): void {
        if (this.timerId >= 0) {
            clearTimeout(this.timerId);
        }
        this.scheduledScrollTo = containerId;
        this.smoothScroll = smoothScroll;
        this.timerId = window.setTimeout(() => this.scrollTo(), dontWait ? 0 : 200);
    }

    scheduleScrollToPosition(position: number, smoothScroll = true, dontWait = false): void {
        if (this.timerId >= 0) {
            clearTimeout(this.timerId);
        }
        this.scheduledScrollTo = position;
        this.smoothScroll = smoothScroll;
        this.timerId = window.setTimeout(() => this.scrollTo(), dontWait ? 0 : 200);
    }


    private scrollTo() {
        if (typeof this.scheduledScrollTo === "string") {
            const elm = document.getElementById(this.scheduledScrollTo);
            if (elm) {
                elm.scrollIntoView({
                    behavior: this.smoothScroll ? "smooth" : "auto"
                });
            }
        } else if (typeof this.scheduledScrollTo === "number" && document.scrollingElement) {
            if (document.scrollingElement.scrollTo !== undefined) {
                document.scrollingElement.scrollTo({
                    left: 0,
                    top: this.scheduledScrollTo,
                    behavior: this.smoothScroll ? "smooth" : "auto"
                });
            } else {
                document.scrollingElement.scrollIntoView();
            }
        }
    }
}
const scrollService = new ScrollService();
export default scrollService;