import { CustomerContext } from '../customers/CustomerContext';
import { ElementsApi } from '../../apis/ElementApi';
import { ElementService } from './ElementService';
import {
    ExecuteErrorApi,
    ExecuteOk,
    ServiceActivator,
    ServiceErrorApi,
    ServiceOk
    } from '../../libs/activators';
import { IElement } from './Interfaces';

export class ElementServiceActivator extends ServiceActivator<ElementService> {
    private _api: ElementsApi;
    constructor(customer: CustomerContext) {
        const api = new ElementsApi(customer.tokenProvider, customer.deviceContext._configService);
        super(async() => {
            const apiResult = await api.getElementsAsync();
            if (apiResult.resultType === "error") {
                return new ServiceErrorApi(apiResult);
            }

            const service = new ElementService(this, apiResult.data.elements);
            return new ServiceOk(service);
        });
        this._api = api;
    }

    public async removeMediaAsync(removedElements: IElement[]): Promise<boolean> {
        return this.executeAsync(async()=>{
            const res = await this._api.removeMediaAsync(removedElements.map((m)=>m.elementId));
            if(res.resultType === "ok"){
                return new ExecuteOk(true);
            }
            return new ExecuteErrorApi(res);
        });
    }
}
