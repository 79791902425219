import { TimeValue } from ".";
import CompareResult from "./CompareResult";
import DateValue from "./DateValue";
import DayOfWeek from "./DayOfWeek";

export default class DateTimeValue {
  private readonly _internalValue: Date;
  private _dayOfWeek?: DayOfWeek;
  private _date?: DateValue;
  private _time?: TimeValue;

  private constructor(date: Date) {
    this._internalValue = date;
  }

  public static fromString(dateStr: string): DateTimeValue {
    const dateTime = new Date(dateStr);

    if (isNaN(+dateTime)) {
      throw new Error(`Date string not valid (${dateStr})`);
    }

    return new DateTimeValue(dateTime);
  }

  public static tryParseFromString(dateStr?: string | null): DateTimeValue | undefined {
    if (!dateStr) {
      return undefined;
    }

    const dateTime = new Date(dateStr);

    if (isNaN(+dateTime)) {
      return undefined;
    }

    return new DateTimeValue(dateTime);
  }

  public static fromApi(dateTime: string): DateTimeValue {
    return this.fromString(dateTime);
  }

  public static fromApiOrNull(dateTime: string | null | undefined): DateTimeValue | null {
    if (dateTime) {
      return this.fromApi(dateTime);
    }

    return null;
  }

  public static fromApiOrUndefined(dateTime: string | null | undefined): DateTimeValue | undefined {
    if (dateTime) {
      return this.fromApi(dateTime);
    }

    return undefined;
  }

  public static fromJsDate(date: Date): DateTimeValue {
    return new DateTimeValue(date);
  }

  public static fromJsDateOrUndefined(date: Date | null | undefined): DateTimeValue | undefined {
    if (date) {
      return new DateTimeValue(date);
    }

    return undefined;
  }

  public static fromDateAndTime(date: DateValue, time: TimeValue): DateTimeValue {
    return new DateTimeValue(new Date(date.year, date.month - 1, date.dayOfMonth, time.hours, time.minutes, 0, 0));
  }

  public static fromDateAndTimeOrUndefined(date?: DateValue, time?: TimeValue): DateTimeValue | undefined {
    if (date && time) {
      return DateTimeValue.fromDateAndTime(date, time);
    }

    return undefined;
  }

  public static fromDate(date: DateValue): DateTimeValue {
    return new DateTimeValue(new Date(date.year, date.month - 1, date.dayOfMonth));
  }

  public static now(): DateTimeValue {
    return DateTimeValue.fromJsDate(new Date());
  }

  public setDate(date: DateValue): DateTimeValue {
    return DateTimeValue.fromDateAndTime(date, this.time);
  }

  public setTime(time: TimeValue): DateTimeValue {
    return DateTimeValue.fromDateAndTime(this.date, time);
  }

  public get date(): DateValue {
    if (this._date === undefined) {
      this._date = DateValue.fromJsDate(this._internalValue);
    }

    return this._date;
  }

  public get time(): TimeValue {
    if (this._time === undefined) {
      this._time = TimeValue.fromJsDate(this._internalValue);
    }

    return this._time;
  }

  public get uiTimeString(): string {
    return this.time.uiString;
  }

  public get jsDate(): Date {
    return new Date(this._internalValue);
  }

  public getDayOfWeek(): DayOfWeek {
    if (this._dayOfWeek === undefined) {
      this._dayOfWeek = DayOfWeek.createFromDate(this._internalValue);
    }

    return this._dayOfWeek;
  }

  public get dayOfWeek(): DayOfWeek {
    if (this._dayOfWeek === undefined) {
      this._dayOfWeek = DayOfWeek.createFromDate(this._internalValue);
    }

    return this._dayOfWeek;
  }

  public get uiString(): string {
    const years = this._internalValue.getFullYear().toString();
    const months = (this._internalValue.getMonth() + 1).toString().padStart(2, "0");
    const days = this._internalValue.getDate().toString().padStart(2, "0");
    const hours = this._internalValue.getHours().toString().padStart(2, "0");
    const mins = this._internalValue.getMinutes().toString().padStart(2, "0");

    return `${days}-${months}-${years} ${hours}:${mins}`;
  }

  public toString(): string {
    return this._internalValue.toLocaleString();
  }

  public toServerDateTimeString(): string {
    return this._internalValue.toISOString();
  }

  public toServerDateTimeMinuteString(): string {
    const years = this._internalValue.getFullYear().toString();
    const months = (this._internalValue.getMonth() + 1).toString().padStart(2, "0");
    const days = this._internalValue.getDate().toString().padStart(2, "0");
    const hours = this._internalValue.getHours().toString().padStart(2, "0");
    const mins = this._internalValue.getMinutes().toString().padStart(2, "0");

    return `${years}-${months}-${days} ${hours}:${mins}`;
  }

  public get toApiAsDateTime(): string {
    return this.toServerDateTimeMinuteString()
  }

  public static compare(a: DateTimeValue, b: DateTimeValue): CompareResult {
    if (!a && !b) {
      return CompareResult.Equal;
    }

    if (!a) {
      return CompareResult.Smaller;
    }

    if (!b) {
      return CompareResult.Bigger;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (a._internalValue < b._internalValue) {
      return CompareResult.Smaller;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (a._internalValue === b._internalValue) {
      return CompareResult.Equal;
      // eslint-disable-next-line no-underscore-dangle
    }

    return CompareResult.Bigger;
  }
}
