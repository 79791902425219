import { FetchResultContent, FetchService } from "../libs/apilib";
import { IConfigService } from "../services/config/Interfaces";

export interface IApiPbToken {
    type: "token";

    token: string;
    validUntilUtc: string;
    minutesValid: number;
    displayName: string;

    serviceId?: string;
    serviceName?: string;

    userId?: string;
    userName?: string;

    customerId?: string;
    customerName?: string;

    licenseId?: string;
    licenseName?: string;
}

export interface IApiPbTokenEngineNotFound {
    type: "engineNotFound";
}

export interface IApiPbTokenWrongPassword {
    type: "wrongPassword";
}

export interface IApiPbTokenChangePassword {
    type: "changePassword";
    mustChangePassword: boolean;
    newPasswordToWeak: boolean;
}

export interface IApiSubscribeRequest {
    email: string;
}

export interface IApiSubscribeResultOk {
    type: "subscribeOk";
    emailNumber: string;
    emailDateTime: string;
}

export interface IApiSubscribeResultError {
    type: "subscribeError";
}

export type ApiSubscribeResult = IApiSubscribeResultOk | IApiSubscribeResultError;

export interface IApiLoginRequest {
    email: string;
    password: string;
    newPassword?: string;
}

export interface IApiLoginResultOk {
    type: "loginOk";
    tokenData: IApiPbToken;
}

export interface IApiLoginResultError {
    type: "loginError";
    needNewPassword: boolean;
    passwordIsToWeak: boolean;
    accountIsLockedOut: boolean;
}

export type ApiLoginResult = IApiLoginResultOk | IApiLoginResultError;

export interface IApiLoginComputerIdRequest {
    computerToken: string;
}

export interface IApiLoginLicenseResultOk {
    type: "loginLicenseOk";
    tokenData: IApiPbToken;
}

export interface IApiLoginLicenseResultError {
    type: "loginLicenseError";
    needNewPassword: boolean;
    passwordIsToWeak: boolean;
    accountIsLockedOut: boolean;
}

export type ApiLoginComputerResult = IApiLoginLicenseResultOk | IApiLoginLicenseResultError;

export class SecurityApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("include");
    }

    public subscribeAsync(data: IApiSubscribeRequest): Promise<FetchResultContent<ApiSubscribeResult>> {
        const uri = this.formatUri("subscribe");
        return this._fetchService.fetchPostWithResultAsync<ApiSubscribeResult>(uri, data);
    }

    public loginAsync(data: IApiLoginRequest): Promise<FetchResultContent<ApiLoginResult>> {
        const uri = this.formatUri("login");
        return this._fetchService.fetchPostWithResultAsync<ApiLoginResult>(uri, data);
    }

    public loginComputerCodeAsync(data: IApiLoginComputerIdRequest): Promise<FetchResultContent<ApiLoginComputerResult>> {
        const uri = this.formatUri("loginComputer");
        return this._fetchService.fetchPostWithResultAsync<ApiLoginComputerResult>(uri, data);
    }

    public getPbSoundTokenFromAzureTokenAsync(azureToken: string): Promise<FetchResultContent<IApiPbToken>> {
        const uri = this.formatUri("token/azure");
        const data = { token: azureToken };
        return this._fetchService.fetchPostWithResultAsync<IApiPbToken>(uri, data);
    }

    public getPbSoundRefreshTokenAsync(): Promise<FetchResultContent<IApiPbToken>> {
        const uri = this.formatUri("token/refresh");
        return this._fetchService.fetchPostWithResultAsync<IApiPbToken>(uri, undefined);
    }

    public getPbSoundAccessTokenAsync(data: { token: string, customerId: string | undefined, licenseId?: string }): Promise<FetchResultContent<IApiPbToken>> {
        const uri = this.formatUri("token/access");
        return this._fetchService.fetchPostWithResultAsync<IApiPbToken>(uri, data);
    }

    private formatUri(rel: string): string {
        return this._configService.config.securityApiUrl + "/" + rel;
    }
}

