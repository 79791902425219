import { FetchResultContent, FetchResultNoContent, FetchService } from '../libs/apilib';
import { IConfigService } from '../services/config/Interfaces';
import { IAccessTokenProvider } from '../services/token/Interfaces';

export class AdvertisementLicensesApi {
    private readonly _configService: IConfigService;
    private readonly _fetchService: FetchService;

    constructor(accessTokenProvider: IAccessTokenProvider, configService: IConfigService) {
        this._configService = configService;
        this._fetchService = new FetchService("omit");
        this._fetchService.addHeaderAsyncFactory(async(headers) => {
            const token = await accessTokenProvider.getTokenAsync();
            headers.set("recordcasetoken", token.token);
        });
    }

    public getLicenseAsync(licenseIdentity: string): Promise<FetchResultContent<IApiLicense>> {
        const url = this.getUri();
        return this._fetchService.fetchGetAsync<IApiLicense>(url + "/getLicense2/" + licenseIdentity);
    }

    public updateLicenseAsync(licenseIdentity: string, data: IApiLicenseUpdate): Promise<FetchResultNoContent> {
        const url = this.getUri();
        return this._fetchService.fetchPostAsync(url + "/updateLicense2/" + licenseIdentity, data);
    }

    private getUri(): string {
        return this._configService.config.advertisementLicenseApiUrl;
    }
}

export interface IApiLicense {
    presentationIdentity?: string;
    presentationName?: string;
    presentationThumbnail?:string;
    presentationExpireDate?: string;
    presentations: IApiLicensePresentation[];
}

export interface IApiLicensePresentation {
    name: string;
    presentationIdentity: string;
    thumbnail: string;
    displayFactor: number;
}

export interface IApiLicenseUpdate {
    presentations: IApiLicenseUpdateAutomatPresentation[];
}

export interface IApiLicenseUpdateAutomatPresentation{
    presentationIdentity: string;
    displayFactor: number;
}