import { DeviceContext } from '../device/DeviceContext';
import { AccessTokenProvider } from '../token/AccessTokenProvider';
import { ElementServiceActivator } from '../elements/ElementServiceActivator';
import { ElementTopicServiceActivator } from '../element_topics/ElementTopicServiceActivator';
import { IActivator, ModelStateObservable } from '../../libs/activators';
import { ICustomerContext } from './Interfaces';
import { IElementService } from '../elements/Interfaces';
import { IElementTopicService } from '../element_topics/Interfaces';
import { ILicensesModel } from '../licenses/Interfaces';
import { IPresentationService } from '../presentations/Interfaces';
import { IPresentationTopicService } from '../presentation_topics/Interfaces';
import { IUploadElementService } from '../upload/Interfaces';
import { PresentationServiceActivator } from '../presentations/PresentationServiceActivator';
import { PresentationTopicServiceActivator } from '../presentation_topics/PresentationTopicServiceActivator';
import { UploadElementServiceActivator } from '../upload/UploadElementServiceActivator';
import { CustomerActivator } from '../customer/CustomerActivator';
import { ICustomerModel } from '../customer/Interfaces';
import { LicensesActivator } from '../licenses/LicensesActivator';
import { LicensesContextService } from '../licenses/LicensesContextService';

export class CustomerContext implements ICustomerContext {
    private readonly _customerId: string;
    private readonly _deviceContext: DeviceContext;
    private readonly _licensesContextService: LicensesContextService;
    private readonly _presentationServiceActivator: PresentationServiceActivator;
    private readonly _elementServiceActivator: ElementServiceActivator;
    private readonly _elementTopicServiceActivator: ElementTopicServiceActivator;
    private readonly _presentationTopicServiceActivator: PresentationTopicServiceActivator;
    private readonly _uploadElementServiceActivator: UploadElementServiceActivator;
    private readonly _accessTokenProvider: AccessTokenProvider;
    private _customerActivator?: CustomerActivator;
    private _licensesActivator?: LicensesActivator;

    constructor(deviceContext: DeviceContext, customerId: string) {
        this._deviceContext = deviceContext;
        this._customerId = customerId;

        this._accessTokenProvider = new AccessTokenProvider(deviceContext.deviceTokenProvider, customerId);

        this._licensesContextService = new LicensesContextService(this);

        this._presentationServiceActivator = new PresentationServiceActivator(this);
        this._elementServiceActivator = new ElementServiceActivator(this);
        this._elementTopicServiceActivator = new ElementTopicServiceActivator(this);
        this._presentationTopicServiceActivator = new PresentationTopicServiceActivator(this);
        this._uploadElementServiceActivator = new UploadElementServiceActivator(this, () => this.refreshElements());
    }

    public get obsCustomerModelState(): ModelStateObservable<ICustomerModel> {
        if (!this._customerActivator) {
            this._customerActivator = new CustomerActivator(this.deviceContext, this);
        }

        return this._customerActivator.obsModelState;
    }

    public get obsLicensesModelState(): ModelStateObservable<ILicensesModel> {
        if (!this._licensesActivator) {
            this._licensesActivator = new LicensesActivator(this);
        }

        return this._licensesActivator.obsModelState;
    }

    public get deviceContext(): DeviceContext { return this._deviceContext; }

    public get licenses(): LicensesContextService { return this._licensesContextService; }

    public get tokenProvider(): AccessTokenProvider { return this._accessTokenProvider; }

    public get customerId(): string { return this._customerId; }

    public get elementServiceActivator(): IActivator<IElementService> { return this._elementServiceActivator; }

    public get elementTopicServiceActivator(): IActivator<IElementTopicService> { return this._elementTopicServiceActivator; }

    public get presentationTopicServiceActivator(): IActivator<IPresentationTopicService> { return this._presentationTopicServiceActivator; }

    public get presentationServiceActivator(): IActivator<IPresentationService> { return this._presentationServiceActivator; }

    public get uploadElementServiceActivator(): IActivator<IUploadElementService> { return this._uploadElementServiceActivator; }

    private refreshElements(): void {
        this._elementServiceActivator.recreateService();
    }
}
