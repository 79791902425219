import React from "react";
import { ILifetimeContainer, LifetimeContainer, ILifetime } from "../lifetime";

// eslint-disable-next-line @typescript-eslint/ban-types
export class ComponentWithLifetime<TProps = {}, TState = {}> extends React.Component<TProps, TState> implements ILifetimeContainer {
    private readonly _lifetimeContainer: LifetimeContainer;

    constructor(props: TProps, context?: unknown) {
        super(props, context);
        this._lifetimeContainer = new LifetimeContainer(undefined);
    }

    public get lifetimeIsCreated(): boolean { return this._lifetimeContainer.lifetimeIsCreated; }

    public addLifetime(lifetime: ILifetime): void {
        this._lifetimeContainer.addLifetime(lifetime);
    }

    public removeLifetime(lifetime: ILifetime): void {
        this._lifetimeContainer.removeLifetime(lifetime);
    }

    public componentWillUnmount(): void {
        this._lifetimeContainer.dispose();
    }
}
