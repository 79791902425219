export default class CompareResult {
  public readonly isEqual: boolean;
  public readonly isNotEqual: boolean;
  public readonly isBigger: boolean;
  public readonly isNotBigger: boolean;
  public readonly isBiggerOrEqual: boolean;
  public readonly isSmaller: boolean;
  public readonly isNotSmaller: boolean;
  public readonly isSmallerOrEqual: boolean;
  /** intValue can be used in compare-functions for Array.sort() */
  public readonly intValue: number;

  private constructor(args: {
    isEqual: boolean,
    isBigger: boolean,
    isSmaller: boolean,
    intValue: number,
  }) {
    this.isEqual = args.isEqual;
    this.isNotEqual = !args.isEqual;
    this.isBigger = args.isBigger;
    this.isNotBigger = !args.isBigger;
    this.isBiggerOrEqual = args.isEqual || args.isBigger;
    this.isSmaller = args.isSmaller;
    this.isNotSmaller = !args.isSmaller;
    this.isSmallerOrEqual = args.isEqual || args.isSmaller;
    this.intValue = args.intValue;
  }

  public static readonly NotEqual = new CompareResult({
    isEqual: false,
    isBigger: false,
    isSmaller: false,
    intValue: NaN,
  });

  public static readonly Equal = new CompareResult({
    isEqual: true,
    isBigger: false,
    isSmaller: false,
    intValue: 0,
  });

  public static readonly Bigger = new CompareResult({
    isEqual: false,
    isBigger: true,
    isSmaller: false,
    intValue: 1,
  });

  public static readonly Smaller = new CompareResult({
    isEqual: false,
    isBigger: false,
    isSmaller: true,
    intValue: -1,
  });
}
