import React from 'react';
import AddIcon from '@material-ui/icons/Add';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { PbBlockContainer, PbButton, PbParagraph } from '../../../components/pd_themed';
import { PbSoundLogo } from '../../../components/PbSoundLogo';
import { style } from 'typestyle';
import { TextStrings } from '../../../lang/TextStrings';
import { useDeviceContext } from '../../main/AppLayout';
import { useObservable } from '../../../libs/react';

const homeBlockStyle = style({
    display: "grid",
    gridTemplateRows: "min-content auto auto auto",
    alignContent: "space-around",
    overflow: "hidden",

});

const buttonStyle = style({
    display: "grid",
    gridTemplateColumns: "auto min-content",
    gridTemplateRows: "1fr",
    alignItems: "center"
});

export function PbBlockLogo(): JSX.Element | null {
    const deviceContext = useDeviceContext();
    const data = useObservable(deviceContext?.obsDeviceData);
    if (!data) {
        return null;
    }

    let displayNameText = "";
    switch (data?.loginType) {
        case "Device":
            displayNameText = TextStrings.formatHomeDevice(data.name);
            break;
        case "User":
            displayNameText = TextStrings.formatHomeLoggedInAs(data.name);
            break;
        case "Computer":
        case "LocalUser":
            displayNameText = TextStrings.formatHomePlayerLogin(data.name);
            break;
    }

    const logoutDisabled = !data.isUser;
    const logoutButtonVisible = data.isUser;
    const connectButtonVisible = data.hasAccess;
    const loginButtonVisible = !data.isUser;

    return (
        <PbBlockContainer>
            <div className={homeBlockStyle}>
                <PbSoundLogo />
                <PbParagraph>{displayNameText}</PbParagraph>
                {loginButtonVisible &&
                    <PbButton className={buttonStyle} onClick={onLogin}>Login</PbButton>}

                {connectButtonVisible &&
                    <PbButton className={buttonStyle} onClick={onConnectSystem}> {TextStrings.textConnectDialogTitle} <AddIcon /></PbButton>}

                {logoutButtonVisible &&
                    <PbButton className={buttonStyle} onClick={onLogout} disabled={logoutDisabled}>Logout<ExitToAppIcon /></PbButton>}

            </div>
        </PbBlockContainer>
    );

    function onLogout(): void {
        if (deviceContext) {
            deviceContext.logout();
        }
    }

    function onLogin(): void {
        if (deviceContext) {
            deviceContext._loginLogic.loginAsync();
        }
    }

    function onConnectSystem(): void {
        if (deviceContext) {
            deviceContext._loginLogic.connectSystemDialogExecuteAsync()
        }
    }
}