export class TextStrings {
    public static textCloseAction = "Sluiten";
    public static textBackAction = "Terug";
    public static textCancelAction = "Annuleren";
    public static textSaveAction = "Opslaan";
    public static textNoAction = "Nee";
    public static textYesAction = "Ja";
    public static textSelectAction = "Selecteer";


    public static formatHomeLoggedInAs(username: string): string { return `Ingelogd als: ${username}` }
    public static formatHomeDevice(displayname: string): string { return `Dit is: ${displayname}` }
    public static formatHomePlayerLogin(displayname: string): string { return `Speler: ${displayname}` }
    public static textHomeLogoutAction = "Controller";

    public static textMyController = "Controller";
    public static textMyControllerDescription = "Met de functionaliteit van de Controller app kunt u uw muziek spelers overal ter wereld bedienen.";
    public static textMyControllerStartAction = "Start Controller";

    public static textMyCustomers = "Mijn systemen";
    public static textMyCustomersConnect = "Om uw systeem te gebruiken moet u een nieuw systeem koppelen.";
    public static textMyCustomersSearch = "Systemen zoeken";
    public static textMyCustomersSearchPlaceholder = "Typ hier de zoekopdracht";
    public static textMyCustomersSearchMoreResults = "Er zijn nog meer resultaten die niet weergegeven worden. Verfijn u zoekopdracht.";

    public static textConnectDialogTitle = "Systeem koppelen";
    public static textConnectDialogAction = "Koppelen";
    public static textConnectDialogCodeDesc = "De code om een speler te koppelen vind u in uw Easyplayer muzieksysteem, hier drukt u op het tandwiel → koppelcode. Wanneer er geen knop koppelcode verschijnt in u systeem moet u contact opnemen met PB-Sound";
    public static textConnectDialogWrongCode = "Code is niet geldig";
    public static formatConnectDialogCodeExisting(company: string, city: string): string { return `U bent al gekoppeld aan het systeem van ${company} in ${city}` }
    public static textConnectDialogCodeDone = "De koppeling met het systeem is gemaakt!";
    public static formatDeviceNumber(devicenumber: string):string {return `Apparaat nummer: ${devicenumber}`}

    public static textSpotifyBlockTitle = "Spotify account";
    public static textSpotifyDescription = "Door uw Spotify te koppelen aan de PB-Sound speler kunnen Spotify speellijsten vanuit de PB-Sound speler afgespeeld worden.";
    public static textSpotifyDialogConnectAction = "Spotify account koppelen";
    public static textSpotifyDialogDisconnectAction = "Spotify account ontkoppelen";

    public static textNarrowCastingBlockTitle = "TV Presentatie";
    public static textMyContentPlayers = "Beheer spelers";
    public static textMyContentNoPlayersMessage = "U heeft geen spelers";
    public static textMyContentDescription = "Beheer presentaties en media";

    public static textMyMenu = "Menu";
    public static textMyMenuPlayersOption = "Players";
    public static textMyMenuContentOption = "Content";

    public static textMyPresentations = "Presentaties";
    public static textMyPresentationsNoItems = "U heeft nog geen presentaties gemaakt.";
    public static textMyPresentationsAddAction = "Presentatie toevoegen";
    public static textMyPresentationsEditListAction = "Lijst bewerken";
    public static textMyPresentationsExpireWarning = "Presentatie is verlopen";
    public static textMyPresentationsExpireDateRemove = "Verloopdatum verwijderen";

    public static textDeletePresentationDialogTitle = "Presentatie verwijderen";
    public static formatConfirmDeletionOfPresentation(name: string): string { return `Weet u zeker dat u de presentatie '${name}' wilt verwijderen.` }

    public static textMyMedia = "Foto / Video";
    public static textMyMediaNoItems = "Geen foto's of video's om weer te geven, u kunt deze uploaden met de bovenstaande knop.";
    public static textMyMediaAddAction = "Toevoegen";
    public static textMyMediaEditListAction = "Lijst bewerken";
    public static textMyMediaUploadDesc = "Met de onderstaande knop kunt u foto's (jpg) en video's (mp4) uploaden.";

    public static textMyPlayer = "Speler";
    public static textMyPlayers = "Mijn spelers";
    public static formatMyPlayerName(name: string): string { return `Speler ${name}` }
    public static formatMyPlayerActivePresentation(presentationName: string): string { return `Huidige presentatie: ${presentationName}` }
    public static textMyPlayerNoActivePresentation = "Voor deze speler is geen presentatie geselecteerd, dit kunt u doen met de volgend knop.";
    public static textMyPlayerSelectPresentation = "Selecteer presentatie";
    public static textMyPlayerSelectedPresentations = "Huidige presentaties";
    public static textMyPlayerDeselectCurrentPresentation = "Huidige presentatie deselecteren";
    public static textMyPlayerNoSelectedPresentation = "Geen presentatie";
    public static textMyPlayerPresentationDisplayFactor = "Weergave percentage";
    public static textMyPlayerPresentationResetDisplayFactor = "Reset weergave percentage";

    public static textEditPresentationSelectMedia = "Selecteer media";

    public static mediaSelectorAddAction = "Media toevoegen";

    public static textPresentationEditNameNew = "Presentatie naam";
    public static textPresentationEditName = "Presentatie naam wijzigen";
    public static textPresentationEditNameSuccess = "Presentatie naam succesvol gewijzigd";
    public static textPresentationEditSlideDuration = "Presentatie snelheid";
    public static textPresentationEditExpireDateSuccess = "Presentatie verloopdatum succesvol gewijzigd";
    public static textPresentationEditSlideDurationSuccess = "Presentatie snelheid succesvol gewijzigd";

    public static textPresentationEditSlideSettings = "Dia instellingen";
    public static formatPresentationExpiresOn(date: Date | undefined): string {
        if (date === undefined) {
            return `Presentatie verloopt niet.`
        } else {
            return `Verloopt op: ${date.toLocaleString("nl-NL")}`
        }
    }

    public static formatPresentationExpire(date: Date): string {
        return `Verlopen op: ${date.toLocaleString("nl-NL")}`;
    }

    public static formatSlideElementName(name: string): string { return `Bestandsnaam: ${name}`; }
    public static formatSlideElementOrigin(topicName: string): string { return `Herkomst: ${topicName}`; }
    public static textPresentationEditSetAsThumbnail = "Instellen als thumbnail";
    public static textPresentationEditSetAsThumbnailDesc = "Wanneer u een dia instelt als thumbnail wordt deze gebruikt in uw overzicht met presentaties.";
    public static formatPresentationCreated(name: string): string { return `Presentatie ${name} is aangemaakt` }

    public static textLoginWelcome = "Welkom bij My Pbsound";
    public static textLoginAppDescription = "Deze applicatie kunt u gebruiken om uw muzieksystemen te bedienen en presentaties te maken en beheren. U kunt deze presentaties tonen op uw schermen als beeldreclame.";
    public static textLoginCreateAccount = "Heeft u nog geen account klik dan op registreren om een account aan te maken. U ontvangt per e-mail de instructies om in te loggen in My Pbsound.";
    public static textLoginCreateAccountAction = "Registreren";
    public static textLoginLogin = "Heeft u een account klik dan op login u kunt inloggen met uw gegevens. Bent u het wachtwoord vergeten drukt u dan op wachtwoord vergeten, er zal een mail worden gestuurd met een resetlink.";
    public static textLoginLoginAction = "Login";
    public static textLoginResetPasswordAction = "Wachtwoord vergeten";
    public static textLoginConnectCode = "Waar is de knop koppel een speler? Om een speler te koppelen gaat u naar uw Easyplayer muzieksysteem en drukt u op het tandwiel --> koppel speler --> voer de koppelcode in uw My Pbsound";

    public static textLoginForm = "Gebruik u e-mailadres en wachtwoord om in te loggen";
    public static formatChangePasswordText(email: string): string { return `Voer het nieuwe wachtwoord in voor dit e-mailadres: ${email}.` }
    public static textChangePasswordText = "Wijzig wachtwoord";
    public static textResetPassword = "Reset wachtwoord";
    public static textResetPasswordInformation = "Een e-mail met nieuwe inloggegeven wordt verstuurt naar het ingevoerde e-mailadres";
    public static textSendEmailAction = "Verstuur e-mail";

    public static subscribeDialogTitle = "Registreren";
    public static subscribeDialogDesc = "Om je te geristreren voor My PB-Sound hier je e-mailadres invullen. U ontvangt per e-mail de instructies om in te loggen in My Pbsound.";

    public static textElementSelectorTopics = "Media toevoegen vanuit een Topic";
    public static textElementSelectorMedia = "Media toevoegen vanuit je eigen gallerij";
    public static textElementSelectorSelectTopic = "Selecteer een topic om afbeeldingen weer te geven";
    public static textElementSelectorTopicEmpty = "Topic is leeg";
    public static formatElementSelectorTopicName(topicName: string): string { return `Topic: ${topicName}` }

    public static textPresentationSelectorTitle = "Selecteer presentatie";
    public static textPresentationSelectorFromTopic = "Selecteer vanuit een Topic";
    public static textPresentationSelectorFromCustomer = "Selecteer vanuit eigen presentaties";

    public static formatTopicSelectorTitle(name: string): string { return `Topic: ${name}` }
    public static textTopicSelectorTitle = "Selecteer topic";
}